import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom"; // To get the subcategory ID from the URL
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import { getUserDetails } from "../../../Helper/SessionHelper";

const EditSubCategory = () => {
  // Initialize form handling
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const { id } = useParams(); // Get the subcategory ID from the URL

  // State variables
  const [catID, setCatID] = useState([]);
  const [titleErMsg, setTitleErMsg] = useState("");
  const [isTitleValid, setIsTitleValid] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryTitle, setCategoryTitle] = useState("");
  const sellerID = getUserDetails().sellerIDInfo[0]?._id;

  // Fetch categories and subcategory details
  useEffect(() => {
    const getCat = async () => {
      try {
        const res = await axios.get(`${BaseURL}/get-category`);
        setCatID(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    getCat();

    const fetchSubCategory = async () => {
      try {
        const res = await axios.get(`${BaseURL}/get-single-subcategory/${id}`);
        console.log("subcategory", res.data.data);
        const subCategoryData = res.data.data[0];
        console.log("catID", subCategoryData.categoryID);
        setValue("selectedCategory", subCategoryData.categoryID);
        setValue("categoryName", subCategoryData.categoryName);
        setCategoryTitle(subCategoryData.categoryName);
        setSelectedCategory(subCategoryData.categoryID);
      } catch (err) {
        console.log(err);
      }
    };
    fetchSubCategory();
  }, [id, setValue]);

  const handleTitleExist2 = async (title) => {
    setCategoryTitle(title);
    try {
      let result = await axios.get(
        `${BaseURL}/sub-category-validation/${sellerID}/${title}`
      );
      console.log(result.data.status);
      if (result.data.status === 200) {
        setTitleErMsg(result.data.message);
        setIsTitleValid(true);
      } else {
        setTitleErMsg("");
        setIsTitleValid(false);
      }
    } catch (error) {
      console.error("Error fetching validation status:", error);
      setTitleErMsg("Error validating title");
      setIsTitleValid(true);
    }
  };
  const handleTitleExist = useCallback(async (event) => {
    let titleExist = event.target.value;
    setCategoryTitle(titleExist);
    console.log("catTitle", titleExist);
    try {
      let result = await axios.get(
        `${BaseURL}/sub-category-validation/${sellerID}/${titleExist}`
      );
      console.log(result.data.status);
      if (result.data.status === 200) {
        setTitleErMsg(result.data.message);
        setIsTitleValid(true);
      } else {
        setTitleErMsg("");
        setIsTitleValid(false);
      }
    } catch (error) {
      console.error("Error fetching validation status:", error);
      setTitleErMsg("Error validating title");
      setIsTitleValid(true);
    }
  }, []);

  // Handle title validation
  //   const handleTitleExist = useCallback(
  //     async (event) => {
  //       let titleExist = event.target.value;
  //       console.log("catTitle", titleExist);
  //       setCategoryTitle(titleExist);
  //       try {
  //         const result = await axios.get(
  //           `${BaseURL}/sub-category-validation/${sellerID}/${titleExist}`
  //         );
  //         if (result.data.status === 200) {
  //           setTitleErMsg(result.data.message);
  //           setIsTitleValid(true);
  //         } else {
  //           setTitleErMsg("");
  //           setIsTitleValid(false);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching validation status:", error);
  //         setTitleErMsg("Error validating title");
  //         setIsTitleValid(true);
  //       }
  //     },
  //     [sellerID]
  //   );

  // Handle form submission
  const handleSubcategory = async (value) => {
    if (isTitleValid) {
      toast.error("Title already exists!", {
        position: "bottom-center",
        autoClose: 5000,
      });
      return;
    }

    const subdata = {
      categoryName: categoryTitle,
      categoryID: selectedCategory,
      status: true,
      sellerID,
      createdBy: sellerID,
    };

    try {
      const res = await axios.post(
        `${BaseURL}/update-subcategory/${id}`,
        subdata
      );
      if (res.data.status === "Success") {
        toast.success("SubCategory/Menu Label has been updated successfully.", {
          position: "bottom-center",
          autoClose: 5000,
        });
      } else {
        toast.error("Can't update the subcategory.", {
          position: "bottom-center",
          autoClose: 5000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container d-flex">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Edit SubCategory/Menu Label</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSubmit(handleSubcategory)}>
                  <div className="row d-flex">
                    <div className="form-group mb-3 col-md-4 col-lg-12">
                      <label>Select Parent Category *</label>
                      <select
                        className="form-control"
                        id="selectedCategory"
                        {...register("selectedCategory", { required: true })}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                      >
                        <option value="">Select Category</option>

                        {catID.map((item, index) => {
                          if (
                            selectedCategory &&
                            selectedCategory === item._id
                          ) {
                            return (
                              <option key={index} value={item._id} selected>
                                {item.categoryName}
                              </option>
                            );
                          } else {
                            return (
                              <option key={index} value={item._id}>
                                {item.categoryName}
                              </option>
                            );
                          }
                        })}
                      </select>
                      {errors.selectedCategory && (
                        <span className="text-danger">Select Category</span>
                      )}
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <label>Sub-Category Title *</label>
                    <input
                      type="text"
                      className="form-control input-default"
                      placeholder="SubCategory Title"
                      defaultValue={categoryTitle}
                      //   onChange={handleTitleExist}
                      onChange={(e) => handleTitleExist(e)}
                      name="categoryName"
                      //   {...register("categoryName", { required: true })}
                    />
                    {titleErMsg && (
                      <span className="text-danger">{titleErMsg}</span>
                    )}
                  </div>

                  <Button
                    style={{ marginLeft: 25 }}
                    type="submit"
                    className="mt-3"
                  >
                    Update
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSubCategory;
