// import React, { useEffect, useState } from "react";
// import { database } from "../../Helper/firebase";
// import { onValue, ref, set } from "firebase/database";
// import { GoogleMap, Marker } from "@react-google-maps/api";
// import RiderIcon from "../../images/RiderPin.png";
// import BuyerIcon from "../../images/buyerPin.png";
// import SellerIcon from "../../images/sellerPin.png";

// const Driver = ({
//   showRestaurantMarker,
//   showPickupMarker,
//   showRiderMarker,
//   restaurantLocation,
//   pickupLocation,
// }) => {
//   const [riderLocation, setRiderLocation] = useState({ lat: 0, lng: 0 });
//   const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
//   const [estimatedTime, setEstimatedTime] = useState("");
//   //pickup location = buyer location
//   //restaurant location = kitchen location

//   // const restaurantLocation = {
//   //   lat: 22.3269139,
//   //   lng: 91.8072081,
//   // };

//   // const pickupLocation = {
//   //   lat: 22.3371007,
//   //   lng: 91.817946,
//   // };

//   const mapOptions = {
//     zoomControl: false,
//     streetViewControl: false,
//     mapTypeControl: false,
//     fullscreenControl: true,
//   };

//   useEffect(() => {
//     if (restaurantLocation) {
//       setMapCenter(restaurantLocation);
//     } else if (pickupLocation) {
//       setMapCenter(pickupLocation);
//     }
//     //buyer=> seller ,rider
//     const intervalId = setInterval(() => {
//       if (navigator.geolocation) {
//         navigator.geolocation.getCurrentPosition(
//           (position) => {
//             const { latitude, longitude } = position.coords;
//             console.log("Driver's location:", latitude, longitude);
//             saveLocationToFirebase(latitude, longitude);
//           },
//           (error) => {
//             console.error("Error getting driver's location:", error);
//           }
//         );
//       } else {
//         console.error("Geolocation is not supported by this browser.");
//       }
//     }, 5000);

//     return () => clearInterval(intervalId);
//   }, []);

//   useEffect(() => {
//     fetchRiderLocation();
//     // fetchEstimatedTime();
//   }, []);

//   const saveLocationToFirebase = (latitude, longitude) => {
//     const riderId = "rider1";
//     const driverRef = ref(database, `drivers/${riderId}`);
//     set(driverRef, {
//       lat: latitude,
//       lng: longitude,
//       timestamp: Date.now(),
//     })
//       .then(() => {
//         console.log("Location saved to Firebase:", latitude, longitude);
//       })
//       .catch((error) => {
//         console.error("Error saving location to Firebase:", error);
//       });
//   };

//   const fetchRiderLocation = () => {
//     const riderId = "rider1";
//     const riderLocationRef = ref(database, `drivers/${riderId}`);
//     onValue(riderLocationRef, (snapshot) => {
//       const data = snapshot.val();
//       if (data) {
//         setRiderLocation({
//           lat: data.lat,
//           lng: data.lng,
//         });
//         console.log("Rider location fetched:", data);
//       }
//     });
//   };

//   // const fetchEstimatedTime = () => {
//   //   if (riderLocation.lat !== 0 && riderLocation.lng !== 0) {
//   //     const directionsService = new window.google.maps.DirectionsService();
//   //     console.log(directionsService,'direction');
//   //     directionsService.route(
//   //       {
//   //         origin: new window.google.maps.LatLng(
//   //           riderLocation.lat,
//   //           riderLocation.lng
//   //         ),
//   //         destination: new window.google.maps.LatLng(
//   //           pickupLocation.lat,
//   //           pickupLocation.lng
//   //         ),
//   //         travelMode: window.google.maps.TravelMode.DRIVING,
//   //       },
//   //       (response, status) => {
//   //         if (status === window.google.maps.DirectionsStatus.OK) {

//   //           const route = response.routes[0];
//   //           const duration = route.legs[0].duration.text;
//   //           setEstimatedTime(duration);
//   //         } else {
//   //           console.error("Directions request failed:", status);
//   //         }
//   //       }
//   //     );
//   //   }
//   // };

//   return (
//     <div>
//       <GoogleMap
//         center={mapCenter}
//         zoom={14}
//         mapContainerStyle={{ width: "100%", height: "300px" }}
//         options={mapOptions}
//         bo
//       >

//         {console.log("restaurant", restaurantLocation, showRestaurantMarker)}
//         {showRestaurantMarker && (
//           <Marker
//             icon={{
//               url: SellerIcon,
//               scaledSize: new window.google.maps.Size(20, 30),

//             }}
//             animation={window.google.maps.Animation.BOUNCE}
//             position={restaurantLocation}
//             label='Seller'
//           />
//         )}

//         {showPickupMarker && (
//           <Marker
//             icon={{
//               url: BuyerIcon,
//               scaledSize: new window.google.maps.Size(20, 30),
//             }}
//             position={pickupLocation}
//             label='Pickup'
//             animation={window.google.maps.Animation.BOUNCE}
//           />
//         )}
//         {showRiderMarker && riderLocation.lat !== 0 && (
//           <Marker
//             icon={{
//               url: RiderIcon,
//               scaledSize: new window.google.maps.Size(20, 30),
//             }}
//             animation={window.google.maps.Animation.BOUNCE}
//             position={riderLocation}
//             label={`Rider`}
//           />
//         )}
//       </GoogleMap>
//       {showRiderMarker && (
//         <div>
//           Estimated time for rider to reach pickup location: {estimatedTime}
//         </div>
//       )}
//     </div>
//   );
// };

// export default Driver;
// import React, { useEffect, useState, useMemo } from "react";
// import { database } from "../../Helper/firebase";
// import { onValue, ref, set } from "firebase/database";
// import { GoogleMap, Marker } from "@react-google-maps/api";
// import RiderIcon from "../../images/RiderPin.png";
// import BuyerIcon from "../../images/buyerPin.png";
// import SellerIcon from "../../images/sellerPin.png";

// const Driver = ({
//   showRestaurantMarker,
//   showPickupMarker,
//   showRiderMarker,
//   restaurantLocation,
//   pickupLocation,
// }) => {
//   const [riderLocation, setRiderLocation] = useState({ lat: 0, lng: 0 });
//   const [mapCenter, setMapCenter] = useState(null);

//   const markers = useMemo(
//     () => [
//       {
//         type: "restaurant",
//         position: restaurantLocation,
//         icon: SellerIcon,
//         label: "Seller",
//         show: showRestaurantMarker,
//       },
//       {
//         type: "pickup",
//         position: pickupLocation,
//         icon: BuyerIcon,
//         label: "Pickup",
//         show: showPickupMarker,
//       },
//       {
//         type: "rider",
//         position: riderLocation,
//         icon: RiderIcon,
//         label: "Rider",
//         show: showRiderMarker && riderLocation.lat !== 0,
//       },
//     ],
//     [
//       restaurantLocation,
//       pickupLocation,
//       riderLocation,
//       showRestaurantMarker,
//       showPickupMarker,
//       showRiderMarker,
//     ]
//   );

//   const mapOptions = {
//     zoomControl: false,
//     streetViewControl: false,
//     mapTypeControl: false,
//     fullscreenControl: true,
//   };

//   useEffect(() => {
//     if (restaurantLocation) {
//       setMapCenter(restaurantLocation);
//     } else if (pickupLocation) {
//       setMapCenter(pickupLocation);
//     }

//     const intervalId = setInterval(() => {
//       if (navigator.geolocation) {
//         navigator.geolocation.getCurrentPosition(
//           (position) => {
//             const { latitude, longitude } = position.coords;
//             saveLocationToFirebase(latitude, longitude);
//           },
//           (error) => {
//             console.error("Error getting driver's location:", error);
//           }
//         );
//       } else {
//         console.error("Geolocation is not supported by this browser.");
//       }
//     }, 5000);

//     return () => clearInterval(intervalId);
//   }, []);

//   useEffect(() => {
//     fetchRiderLocation();
//   }, []);

//   const saveLocationToFirebase = (latitude, longitude) => {
//     const riderId = "rider1";
//     const driverRef = ref(database, `drivers/${riderId}`);
//     set(driverRef, {
//       lat: latitude,
//       lng: longitude,
//       timestamp: Date.now(),
//     })
//       .then(() => {
//         console.log("Location saved to Firebase:", latitude, longitude);
//       })
//       .catch((error) => {
//         console.error("Error saving location to Firebase:", error);
//       });
//   };

//   const fetchRiderLocation = () => {
//     const riderId = "rider1";
//     const riderLocationRef = ref(database, `drivers/${riderId}`);
//     onValue(riderLocationRef, (snapshot) => {
//       const data = snapshot.val();
//       if (data) {
//         setRiderLocation({
//           lat: data.lat,
//           lng: data.lng,
//         });
//         console.log("Rider location fetched:", data);
//       }
//     });
//   };

//   useEffect(() => {
//     if (restaurantLocation) {
//       setMapCenter(restaurantLocation);
//     } else if (pickupLocation) {
//       setMapCenter(pickupLocation);
//     }
//   }, [restaurantLocation, pickupLocation]);

//   return (
//     <div>
//       <GoogleMap
//         center={mapCenter}
//         zoom={14}
//         mapContainerStyle={{ width: "100%", height: "300px" }}
//         options={mapOptions}
//       >
//         {markers.map((marker, index) => {
//           if (marker.show && marker.position) {
//             return (
//               <Marker
//                 key={index}
//                 icon={{
//                   url: marker.icon,
//                   scaledSize: new window.google.maps.Size(20, 30),
//                 }}
//                 animation={window.google.maps.Animation.BOUNCE}
//                 position={marker.position}
//                 label={marker.label}
//               />
//             );
//           }
//           return null;
//         })}
//       </GoogleMap>
//       {/* {showRiderMarker && (
//         <div>
//           Estimated time for rider to reach pickup location: {}
//         </div>
//       )} */}
//     </div>
//   );
// };

// export default Driver;
import React, { useEffect, useState, useMemo } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import RiderIcon from "../../images/RiderPin.png";
import BuyerIcon from "../../images/buyerPin.png";
import SellerIcon from "../../images/sellerPin.png";
import { onValue, ref } from "firebase/database";
import { database } from "../../Helper/firebase";
import LocationTracker from "../Rider/LocationTracker";
import { FaHeadphonesAlt } from "react-icons/fa";

const Driver = ({
  showRestaurantMarker,
  showPickupMarker,
  showRiderMarker,
  restaurantLocation,
  pickupLocation,
  item,
}) => {
  const [mapCenter, setMapCenter] = useState(null);
  const [riderLocation, setRiderLocation] = useState({ lat: 0, lng: 0 });
  const riderId = item?.riderId;
  console.log(riderId, item);
  console.log(riderId, restaurantLocation, item, "driver");
  // const markers = useMemo(
  //   () => [
  //     {
  //       type: "restaurant",
  //       position: restaurantLocation,
  //       icon: SellerIcon,
  //       label: "Seller",
  //       show: showRestaurantMarker,
  //     },
  //     {
  //       type: "pickup",
  //       position: pickupLocation,
  //       icon: BuyerIcon,
  //       label: "Pickup",
  //       show: showPickupMarker,
  //     },
  //     {
  //       type: "rider",
  //       position: riderLocation,
  //       icon: RiderIcon,
  //       label: "Rider",
  //       show: showRiderMarker && riderLocation.lat !== 0,
  //     },
  //   ],
  //   [
  //     restaurantLocation,
  //     pickupLocation,
  //     riderLocation,
  //     showRestaurantMarker,
  //     showPickupMarker,
  //     showRiderMarker,
  //   ]
  // );

  const mapOptions = {
    zoomControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: true,
  };

  const fetchRiderLocation = () => {
    if (!riderId) {
      console.error("riderId is undefined or null", riderId);
      return;
    }
    console.log(riderId);
    const riderLocationRef = ref(database, `drivers/${riderId}`);
    console.log(riderLocationRef);
    onValue(riderLocationRef, (snapshot) => {
      console.log("1");
      const data = snapshot.val();
      console.log(data);
      if (data) {
        console.log("driver-lat", data);
        setRiderLocation({
          lat: data.latitude,
          lng: data.longitude,
        });
      }
    });
  };
  useEffect(() => {
    fetchRiderLocation();

    return () => {};
  }, []);
  console.log(riderLocation.lat, mapCenter);
  const markers = useMemo(
    () => [
      {
        type: "restaurant",
        position: restaurantLocation,
        icon: SellerIcon,
        label: "Seller",
        show: showRestaurantMarker,
      },
      {
        type: "pickup",
        position: pickupLocation,
        icon: BuyerIcon,
        label: "Pickup",
        show: showPickupMarker,
      },
      {
        type: "rider",
        position: riderLocation,
        icon: RiderIcon,
        label: "Rider",
        show: showRiderMarker && riderLocation.lat !== 0,
      },
    ],
    [
      restaurantLocation,
      pickupLocation,
      riderLocation,
      showRestaurantMarker,
      showPickupMarker,
      showRiderMarker,
    ]
  );
  console.log(markers);
  useEffect(() => {
    if (restaurantLocation) {
      setMapCenter(restaurantLocation);
    } else if (pickupLocation) {
      setMapCenter(pickupLocation);
    } else if (riderLocation) {
      setMapCenter(riderLocation);
    } else {
      setMapCenter({ lat: 23.7810672, lng: 90.2548767 });
    }
  }, [restaurantLocation, pickupLocation, riderLocation]);
  return (
    <div>
      {/* <LocationTracker riderId={riderId} /> */}
      {/* {<h1>{riderLocation.lat}</h1>} */}
      <GoogleMap
        center={mapCenter || { lat: 0, lng: 0 }} // handle null mapCenter
        zoom={14}
        mapContainerStyle={{ width: "100%", height: "300px" }}
        options={mapOptions}
      >
        {markers.map((marker, index) => {
          if (marker.show && marker.position) {
            return (
              <Marker
                key={index}
                icon={{
                  url: marker.icon,
                  scaledSize: new window.google.maps.Size(20, 30),
                }}
                animation={window.google.maps.Animation.BOUNCE}
                position={marker.position}
                label={marker.label}
              />
            );
          }
          return null;
        })}
      </GoogleMap>
      {/* <div className='bg-primary rounded my-2 p-2'>
        <div>
          <div className='d-flex gap-1 align-items-center'>
            <div className='shadow-md '>
              <FaHeadphonesAlt color='white' size={20} />
            </div>
            <div className='text-white phone-number d-none hov'> 02143</div>
          </div>
        </div>

      </div> */}
    </div>
  );
};

export default Driver;
