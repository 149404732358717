import React, { useState, useEffect, useContext } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Tab, Button } from "react-bootstrap";
import { ImageUploader } from "../../../ImageUploader/ImageUploader";
import { useForm } from "react-hook-form";
import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/editProfile.json";
const amazingPhotosTab = labels.userDashboard.editProfile.amazingPhotosTab;

const AmazingPhotos = () => {
  // const [currentLanguage, setCurrentLanguage] = useState("en");
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  const successMessage = getTranslation(
    "successMessage",
    currentLanguage,
    amazingPhotosTab
  );
  const errorMessage = getTranslation(
    "errorMessage",
    currentLanguage,
    amazingPhotosTab
  );

  const { handleSubmit } = useForm();

  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  const seller = localStorage.getItem("UserDetails");
  const [foodSkills, setFoodSkills] = useState([]);

  const onAddFoodSkill = () => {
    setFoodSkills((prev) => {
      return [
        ...prev,
        {
          foodType: "",
          captionText: "",
          photo: [],
        },
      ];
    });
  };
  const onRemoveFoodSkill = (i) => {
    var newFoodSkills = foodSkills.filter((item, index) => index !== i);
    setFoodSkills(newFoodSkills);
  };

  const onTitleChange = (event, index) => {
    const value = event.target.value;
    setFoodSkills((prev) => {
      prev[index].captionText = value;
      return [...prev];
    });
  };

  const handleDeleteImage = (foodSkill, ImageIndex) => {
    setFoodSkills((prev) => {
      prev[foodSkill].photo.splice(ImageIndex, 1);
      return [...prev];
    });
  };

  const handleImageChange = (event, index) => {
    const fileListone = Array.from(event.target.files);
    setFoodSkills((prev) => {
      if (prev[index].photo) {
        prev[index].photo = [...prev[index].photo, ...fileListone];
      } else {
        prev[index].photo = [...fileListone];
      }

      prev[index].isExisting = false;

      return [...prev];
    });
  };

  const handleBecomeSeller = async (value) => {
    const hasNewPhotos = foodSkills.filter((m) => !m.isExisting);

    if (hasNewPhotos) {
      await Promise.all(
        foodSkills.map(async (__item, index) => {
          if (__item.isExisting === false) {
            await Promise.all(
              __item.photo.map(async (photo, photoIndex) => {
                if (typeof photo.name === "string") {
                  const SelectedImageResult = await ImageUploader(
                    seller,
                    "Kitchen/Dishes",
                    photo
                  );

                  setFoodSkills((prev) => {
                    prev[index].photo[photoIndex] =
                      SelectedImageResult.images[0];
                    prev[index].isExisting = true;
                    return [...prev];
                  });
                }
              })
            );
          }
        })
      );
    }

    let fdata = {
      user: {},
      seller: {
        dishesMedia: [...foodSkills],
      },
    };

    setTimeout(() => {
      axios
        .post(`${BaseURL}/update-user-details/${Seller._id}`, fdata)
        .then((res) => {
          if (res.data.status === "Success") {
            toast.success(successMessage, {
              position: "bottom-right",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.warning(errorMessage);
          }
        });
    }, 1000);
  };

  useEffect(() => {
    axios
      .get(`${BaseURL}/get-user-details/${Seller._id}`)
      .then((res) => {
        // set amazing photos
        if (
          res.data.data[0] &&
          res.data.data[0].sellerData &&
          res.data.data[0].sellerData.dishesMedia[0] &&
          res.data.data[0].sellerData.dishesMedia.length > 0
        ) {
          var imagesCollection = [];

          res.data.data[0].sellerData.dishesMedia.forEach((images, index) => {
            if (images && images.photo.length > 0) {
              var toBePushed = { ...images, isExisting: true };
              imagesCollection.push(toBePushed);
            }
          });

          setFoodSkills(imagesCollection);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Tab.Pane eventKey="5">
      <form onSubmit={handleSubmit(handleBecomeSeller)}>
        <div className="setting-right">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h3>
                {getTranslation(
                  "tabDetailsTitle",
                  currentLanguage,
                  amazingPhotosTab
                )}
              </h3>
            </div>
            <div className="card-body">
              <div className="row align-content-end">
                <div className="col-12">
                  {foodSkills &&
                    foodSkills.map((__item, _index) => {
                      return (
                        <div className="mt-3">
                          <div>
                            <hr />
                            <div className="row">
                              <div className="col-10">
                                <input
                                  type="text"
                                  class="form-control mt-2 mb-3"
                                  placeholder={getTranslation(
                                    "captionPlaceholder",
                                    currentLanguage,
                                    amazingPhotosTab
                                  )}
                                  value={__item.captionText || ""}
                                  onChange={(e) => {
                                    onTitleChange(e, _index);
                                  }}
                                />
                              </div>
                              <div className="col-2">
                                <Button
                                  variant="outline-danger"
                                  type="button"
                                  className="w-100 mt-2"
                                  onClick={(e) => {
                                    onRemoveFoodSkill(_index);
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </Button>
                              </div>
                            </div>

                            {__item.photo && (
                              <div className="row mt-3">
                                {__item.photo.map((item, index) => {
                                  if (item) {
                                    return (
                                      <div
                                        className="col-3 mb-3"
                                        style={{ position: "relative" }}
                                      >
                                        <Link
                                          style={{
                                            position: "absolute",
                                            right: 20,
                                            top: 15,
                                          }}
                                          to={"#"}
                                          onClick={() => {
                                            handleDeleteImage(_index, index);
                                          }}
                                          className="btn btn-danger shadow btn-xs sharp"
                                        >
                                          <i className="fa fa-trash"></i>
                                        </Link>

                                        <img
                                          style={{
                                            maxHeight: "200px",
                                            width: "100%",
                                          }}
                                          alt="not found"
                                          src={
                                            typeof item.name !== "string"
                                              ? item.orginalImageUrl
                                              : URL.createObjectURL(item)
                                          }
                                        />
                                      </div>
                                    );
                                  }
                                })}
                                <br />
                              </div>
                            )}
                          </div>

                          <div className="mb-3">
                            <input
                              hidden
                              type="file"
                              multiple
                              id={`input-file-upload-ap-${_index}`}
                              onChange={(e) => {
                                handleImageChange(e, _index);
                              }}
                            />
                            <label
                              id="label-file-upload"
                              htmlFor={`input-file-upload-ap-${_index}`}
                              className="text-center py-3"
                            >
                              <div>
                                <p>
                                  {getTranslation(
                                    "uploadBoxDragAndDropText1",
                                    currentLanguage,
                                    amazingPhotosTab
                                  )}
                                </p>
                                <span className="upload-button">
                                  {getTranslation(
                                    "uploadBoxDragAndDropText2",
                                    currentLanguage,
                                    amazingPhotosTab
                                  )}
                                </span>
                              </div>
                            </label>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-3">
                  <Button
                    variant="outline-primary"
                    type="button"
                    className="w-100"
                    onClick={onAddFoodSkill}
                  >
                    <i className="fa fa-plus"></i>{" "}
                    {getTranslation(
                      "addMoreButton",
                      currentLanguage,
                      amazingPhotosTab
                    )}
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-3">
                  <Button type="submit" className="w-100">
                    {getTranslation(
                      "saveButton",
                      currentLanguage,
                      amazingPhotosTab
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Tab.Pane>
  );
};

export default AmazingPhotos;
