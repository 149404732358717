import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  useGetWishFoodBySellerQuery,
  useUpdateSingleFoodMutation,
} from "../../../Redux/API/food";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../Helper/SessionHelper";
import { Button, Tooltip } from "react-bootstrap";
import FormModal from "../../Common/Modal/FormModal";

const WishFoodSeller = () => {
  const [show, setShow] = useState(false);
  const [selectedFoodId, setSelectedFoodId] = useState(null);

  const handleClose = () => {
    setShow(false);
    setSelectedFoodId(null);
    // setPrice("");
  };
  // const handleShow = () => setShow(true);
  const userData = getUserDetails();
  const sellerId = userData?.sellerIDInfo[0]?._id;

  const {
    data: WishFoodData,
    isSuccess: wishFoodSuccess,
    isLoading: wishLoading,
  } = useGetWishFoodBySellerQuery(sellerId, {
    skip: !sellerId,
  });
  const [updateSingleFood] = useUpdateSingleFoodMutation();
  const handleDelete = (data) => {
    axios
      .delete(`${BaseURL}/delete-food/${data}`)
      .then((res) => {
        console.log(res);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const handleSetPrice = (id) => {
    setSelectedFoodId(id);
    setShow(true);
  };
  const handleSavePrice = (e) => {
    e.preventDefault();
    const price = e.target.price.value;
    updateSingleFood({ id: selectedFoodId, data: { foodPrice: price } })
      .then((res) => {
        handleClose();
        Swal.fire("Success", "Price updated successfully!", "success");
      })
      .catch((err) => {
        Swal.fire("Error", "Failed to update price", "error");
      });
    handleClose();
  };
  return (
    <>
      <FormModal handleClose={handleClose} show={show} title={"set Price"}>
        <form onSubmit={handleSavePrice}>
          <div className='form-group mb-3'>
            <label>Food Price</label>
            <input
              type='text'
              className='form-control input-default '
              placeholder='Food Price'
              label='Set Price'
              name='price'
            />
          </div>
          <Button variant='primary' type='submit'>
            Save
          </Button>
        </form>
      </FormModal>
      <div className='card h-auto'>
        <div className='card-body p-0'>
          <div className='table-responsive'>
            {wishLoading === true ? (
              "Loading..."
            ) : (
              <table
                className='table table-list i-table style-1 mb-4 border-0'
                id='guestTable-all3'
              >
                <thead>
                  <tr>
                    <th className='bg-none sorting_asc'>
                      <div className='form-check style-3'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='checkAll'
                          //   onClick={() => chackboxFun("all")}
                        />
                      </div>
                    </th>
                    <th>Image</th>
                    <th>FoodDataName</th>
                    <th>Qty</th>
                    <th>DesiredDate</th>
                    <th>Buyer</th>
                    <th className='bg-none'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {wishFoodSuccess &&
                    WishFoodData?.data?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className='application_sorting_1'>
                            <div className='form-check style-3'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                value=''
                                //   onClick={() => chackboxFun()}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='media-bx d-flex py-3  align-items-center'>
                              <img
                                className='me-3 rounded-circle'
                                src={item?.foodImage[0]?.orginalImageUrl}
                                alt='images'
                              />
                            </div>
                          </td>
                          <td>
                            <div>
                              <h5 className='mb-0'>{item?.foodName}</h5>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <h5 className='mb-0'> {item?.foodQty}</h5>
                            </div>
                            {/* <span>2,97 Km</span> */}
                          </td>
                          {/* <td>
                          <div className='d-flex align-items-center'>
                            <h5 className='mb-0'>
                              {item.status === true ? "Active" : "Pending"}
                            </h5>
                          </div>

                        </td> */}
                          <td>
                            <div>
                              <h4 className='text-primary'>
                                {item?.customerDesireDeliveryDate}
                              </h4>
                            </div>
                          </td>
                          <td>
                            <div>
                              {!!item.customerID &&
                                !!item.customerID.userProfilePhoto && (
                                  <div>
                                    <Tooltip
                                      title={item?.customerID?.userFullName}
                                    >
                                      <img
                                        className='me-3 rounded-circle'
                                        src={
                                          item?.customerID?.userProfilePhoto[0]
                                            ?.small?.imageUrl
                                        }
                                        alt='images'
                                      />
                                    </Tooltip>
                                  </div>
                                )}
                            </div>
                          </td>
                          <td>
                            <span className='badge badge-xl light badge-success'>
                              <Button onClick={() => handleSetPrice(item._id)}>
                                Set Price
                              </Button>
                              <Button className='ms-2'>Reject</Button>
                              {/* <Link to={"/edit-food"} state={item}>
                              Edit
                            </Link> */}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WishFoodSeller;
