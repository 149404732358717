import React, { useState, useEffect, useContext } from "react";

import { LanguageContext } from "../../../context/LanguageContext";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import { toast } from "react-toastify";
import { Tab, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/editProfile.json";
const myRestrictionTab = labels.userDashboard.editProfile.myRestrictionTab;

const MyRestrictions = () => {
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);

  const successMessage = getTranslation(
    "successMessage",
    currentLanguage,
    myRestrictionTab
  );
  const errorMessage = getTranslation(
    "errorMessage",
    currentLanguage,
    myRestrictionTab
  );

  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  const [Tag, setTag] = useState([]);
  const [additionalTag, setAdditionalTag] = useState([]);
  const { handleSubmit } = useForm();

  const handleCheck = (e) => {
    if (e.target.checked) {
      setTag((oldArray) => [...oldArray, e.target.value]);
    } else {
      removeTag(e);
    }
  };

  const removeTag = (e) => {
    setTag([...Tag.filter((Tag) => Tag !== e.target.value)]);
  };

  const handleBecomeSeller = async (value) => {
    var fdata = {
      user: {},
      seller: {
        myRestrictions: [...Tag],
      },
    };

    axios
      .post(`${BaseURL}/update-user-details/${Seller._id}`, fdata)
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success(successMessage, {
            position: "bottom-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(errorMessage);
        }
      });
  };

  useEffect(() => {
    axios
      .get(`${BaseURL}/get-additionaltags`)
      .then((res) => {
        setAdditionalTag(res.data.data);
      })
      .catch((Err) => {
        console.log(Err);
      });

    axios
      .get(`${BaseURL}/get-user-details/${Seller._id}`)
      .then((res) => {
        const data = res.data.data[0].sellerData;

        if (data && data.myRestrictions && data.myRestrictions.length > 0) {
          setTag(data.myRestrictions);
        } else {
          setTag([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Tab.Pane eventKey="8">
      <form onSubmit={handleSubmit(handleBecomeSeller)}>
        <div className="setting-right">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h3>
                {getTranslation(
                  "tabDetailsTitle",
                  currentLanguage,
                  myRestrictionTab
                )}
              </h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <ul className="checkbox-group">
                    {additionalTag.map((item, index) => (
                      <li>
                        <span className="form-check custom-checkbox mb-3 ms-3 checkbox-info d-flex">
                          <input
                            onChange={(e) => {
                              handleCheck(e);
                            }}
                            type="checkbox"
                            value={item.tagName}
                            className="form-check-input"
                            id={`customCheckBox_${index}`}
                            checked={Tag && Tag.includes(item.tagName)}
                          />

                          <label
                            className="form-check-label"
                            htmlFor={`customCheckBox_${index}`}
                          >
                            {item.tagName}
                          </label>
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-12 mt-3">
                  <Button type="submit" className="w-100">
                    {getTranslation(
                      "saveButton",
                      currentLanguage,
                      myRestrictionTab
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Tab.Pane>
  );
};
export default MyRestrictions;
