import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { BaseURL } from "../../../Helper/config";
import { getUserDetails } from "../../../Helper/SessionHelper";
const CategoryList = () => {
  const [category, setCategory] = useState();



  const chackboxFun = (type) => {
    setTimeout(() => {
      const checkbox = document.querySelectorAll(
        ".application_sorting_1 input"
      );
      const motherCheckBox = document.querySelector(".sorting_asc input");
      for (let i = 0; i < checkbox.length; i++) {
        const element = checkbox[i];
        if (type === "all") {
          if (motherCheckBox.checked) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        } else {
          if (!element.checked) {
            motherCheckBox.checked = false;
            break;
          } else {
            motherCheckBox.checked = true;
          }
        }
      }
    }, 200);
  };

  useEffect(() => {
    //chackboxFun()

    axios
      .get(`${BaseURL}/get-category-by-seller/${ getUserDetails()?._id}`)
      .then((res) => {
        setCategory(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDelete = (data) => {
    axios
      .delete(`${BaseURL}/delete-category/${data}`)
      .then((res) => {
        console.log(res);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  return (
    <>
      <div className="card h-auto">
        <div className="card-body p-0">
          <div className="table-responsive">
            {category === undefined ? (
              ""
            ) : (
              <table
                className="table table-list i-table style-1 mb-4 border-0"
                id="guestTable-all3"
              >
                <thead>
                  <tr>
                    <th className="bg-none sorting_asc">
                      <div className="form-check style-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="checkAll"
                          onClick={() => chackboxFun("all")}
                        />
                      </div>
                    </th>
                    <th>Image</th>
                    <th>categoryName</th>
                    <th>createdBy</th>
                    <th>Status</th>
                    <th className="bg-none"></th>
                    <th className="bg-none"></th>
                  </tr>
                </thead>
                <tbody>
                  {category.map((item, index) => {
                    console.log(item);
                    return (
                      <tr key={index}>
                        <td className="application_sorting_1">
                          <div className="form-check style-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              onClick={() => chackboxFun()}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            {/* {item?.map((imgs, index) => {
                              console.log(imgs);
                            })} */}
                            <img
                              className="me-3 rounded-circle"
                              crossorigin="anonymous"
                              src={item?.categoryImage}
                              alt="images"
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <h5 className="mb-0">{item.categoryName}</h5>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <h5 className="mb-0">{item.createdBy}</h5>
                          </div>
                          {/* <span>2,97 Km</span> */}
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <h5 className="mb-0">
                              {item.status === true ? "Active" : "Pending"}
                            </h5>
                          </div>
                          {/* <span>2,97 Km</span> */}
                        </td>
                        <td>
                          <div>
                            <h4 className="text-primary">{item.role}</h4>
                          </div>
                        </td>
                        <td>
                          <span className="badge badge-xl light badge-success">
                            <Link to={"/categoryEdit"} state={item}>
                              Edit
                            </Link>
                          </span>
                        </td>
                        <td>
                          {item.status === true ? (
                            ""
                          ) : (
                            <div>
                              <Link
                                to={"#"}
                                onClick={() => {
                                  del(item._id);
                                }}
                                className="btn btn-outline-primary"
                              >
                                Delete
                              </Link>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryList;
