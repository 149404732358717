import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Accordion,
  Button,
  Toast,
  FormCheck,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { AiFillMinusCircle } from "react-icons/ai";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BaseURL } from "../../../Helper/config";

import Swal from "sweetalert2";
import { getUserDetails } from "../../../Helper/SessionHelper";
import {
  ImageUploader,
  onUploadProgress,
} from "../../../ImageUploader/ImageUploader";

import labels from "../../../translationData/recipe.json";
import "./dnd.css";
import { FastField } from "formik";

const Create_Recipe = () => {
  const [selectedMetaImage, setSelectedMetaImage] = useState([]);
  const navigate = useNavigate();
  const [showEdit, setShowEdit] = useState(false);
  const [disable, setdisable] = useState(true);
  const StepData = [];
  const [stepImage, setStepImage] = useState();
  const [readyImageData, setReadyImageData] = useState([]);
  const [stepNames, setStepName] = useState("");
  const stepNameRef = useRef(null);
  const [Videos, setVideo] = useState("");
  const [recipeVideoData, setRecipeVideoData] = useState("");
  const [infos, setInfos] = useState([
    // { index: 0, stepName: "", Photo: "", Video: "", isEditable: false },
  ]);

  const [tips, setTips] = useState("");
  const [tags, setTags] = useState("");

  const [isFileUploading, setIsFileUploading] = useState(false);
  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  const [step, setStep] = useState([]);
  console.log(step);
  const [EditorData, setEditorData] = useState();
  const [shoppingData, setShoppingData] = useState();

  const [recipeData, setrecipeData] = useState({
    stepName: "",
    Video: "",
    stepImage: {},
  });

  const handleMetaFileChange = (event) => {
    const metaFile = Array.from(event.target.files);
    setSelectedMetaImage(metaFile);
  };
  const recipehandleChange = (e) => {
    const newContact = { ...recipeData };
    newContact[e.target.name] = e.target.value;

    newContact.categoryName !== "" ? setdisable(false) : setdisable(true);
    setrecipeData(newContact);
  };

  let recipeSteps = {
    stepName: recipeData.stepName,
    Video: recipeData.Video || "",
    stepImage: recipeData.stepImage,
  };
  const [stepList, setStepList] = useState([]);
  const addData = () => {
    const newStep = {
      stepName: recipeData.stepName,
      Video: recipeData.Video || "",
      stepImage: recipeData.stepImage,
    };

    // Add the new step to the list
    setStepList((prevList) => [...prevList, newStep]);

    // Clear the input values after adding the step
    // setRecipeData({
    //   stepName: '',
    //   videoUrl: '',
    //   stepImage: null,
    // });
    console.log(stepList, "sss");
  };

  const removeData = (toBeRemoved) => {
    console.log(toBeRemoved, "kiya kiya asta hee,kiya kiya jaita hee");
    const newList = infos.filter((item, index) => index !== toBeRemoved);
    setInfos(newList);
  };



  const [selectedImage, setSelectedImage] = useState([]);
  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);
    setSelectedImage(fileList);
    console.log(selectedImage);
  };
  const [resCat, setResCat] = useState([]);

  useEffect(() => {
    const getResCategory = (async) => {
      fetch(`${BaseURL}/get-recipe-categories`)
        .then((res) => res.json())
        .then((data) => {
          // console.log(data, "get-recipe-category");
          setResCat(data.data);
        });
    };
    getResCategory();
  }, []);

  const [ingredients, setIngredients] = useState([]);
  const [ingredientsName, setIngredientsName] = useState("");
  const [ingredientsQty, setIngredientsQty] = useState("");
  const [ingredientsQtyUnit, setIngredientsQtyUnit] = useState("");

  const addToIngredientsList = () => {
    setIngredients([
      ...ingredients,
      {
        ingredientsName: ingredientsName,
        Qty: ingredientsQty,
        qtyUnit: ingredientsQtyUnit,
        isInputHidden: true,
      },
    ]);
    setIngredientsName("");
    setIngredientsQty("");
  };
  const removeIngredientFromList = (toBeRemoved) => {
    const newListOfIngredients = ingredients.filter(
      (item, index) => index !== toBeRemoved
    );
    setIngredients(newListOfIngredients);
  };

  const addToList = (e) => {
    // console.log("add to lis tcall");
    setInfos([
      ...infos,
      {
        stepNames: stepNames,
        Videos: Videos,
        stepImage: stepImage,
        isEditable: false,
        isDragging: false,
      },
    ]);
    console.log("aa", infos);
    setStepName(""); // Clear step name
    setVideo(""); // Clear video URL
    setStepImage(null); // Clear step image
    console.log("jabed", stepNames);
  };

  const userDetailsId = getUserDetails()._id;
  const handleRecipeSubmit = async (event) => {
    event.preventDefault();
    if (selectedImage.length <= 0) {
      Swal.fire({
        icon: "question",
        title: "Recipe Image is required? 👹        ",
      });
      return;
    }
    if (!userDetailsId) {
      Swal.fire({
        icon: "question",
        title: "Something want wrong 🥴 reload your browser 🥱",
      });
      return;
    }

    const form = event.target;
    const name = form.RecipeName.value;
    const preparationTime = form.preparationTime.value;
    const cookingTime = form.cookingTime.value;
    const serves = form.serves.value;
    const cat = form.recipeCategory.value;
    const recipeTips = tips;
    const recipeTags = tags;

    const recipeDescription = EditorData;
    const proteinDetails = form.proteinDetails.value;
    // const seoMetaTitle = form.metaTitle.value || "";
    // const seoMetaDescription = form.metaDescription.value;
    // const seoMetaKeywords = form.metaKeywords.value;
    const isMyOwnRecipe = form.isMyOwnRecipe.checked;
    // const isFeatured = form?.isFeatured?.checked;
    const status = form.status.checked;
    console.log(status, "checkbox");
    const readyFoodImage = form.readyFoodImage?.files[0];
    console.log(selectedImage, "heelo check");
    const RecipeData = {
      recipeName: name,
      sellerID: userDetailsId,
      recipeCategoryID: cat,
      recipeDescription: recipeDescription,
      proteinDetails: proteinDetails,
      preparationTime: preparationTime,
      cookingTime: cookingTime,
      serves: serves,
      recipeReadyFoodImage: readyImageData,
      recipeVideo: recipeVideoData,
      ingredients: ingredients,
      recipeSteps: StepData,
      recipeTips: recipeTips,
      recipeTags: recipeTags,
      // seo: {
      //   metaTitle: seoMetaTitle,
      //   metaDescription: seoMetaDescription,
      //   metaKeywords: seoMetaKeywords,
      //   metaImage: [],
      // },
      shoppingList: shoppingData,
      isMyOwnRecipe: isMyOwnRecipe,

      status: status,
    };

    // if (selectedMetaImage) {
    //   setIsFileUploading(true);
    //   await ImageUploader(
    //     getUserDetails(),
    //     "SEO/MetaImage/",
    //     selectedMetaImage
    //   ).then((data) => {
    //     if (data.status === "Success") {
    //       RecipeData.seo.metaImage = data.images;
    //     } else {
    //       setIsFileUploading(false);
    //     }
    //   });
    // }
    const formData = new FormData();

    formData.append("folder", "Recipes/" + Seller?.userFullName);
    setIsFileUploading(true);

    // formData.append("cover", readyFoodImage,readyFoodImage);
    const readyImagePromise = await ImageUploader(
      Seller,
      "recipes/ReadyImage",
      selectedImage
    );
    console.log(readyImagePromise, "test again");
    // console.log(onUploadProgress(),'%')
    if (readyImagePromise.status === "Success") {
      console.log(readyImagePromise.images, "pagole ");
      setReadyImageData(readyImagePromise.images);
      RecipeData["recipeReadyFoodImage"] = readyImagePromise.images;
      //           setIsFileUploading(false);
    }
    //       .then((response) => response.json())
    //       .then( data=> {
    // console.log(data,'first recipi image');
    //         if (data.status === "Success") {

    //           setReadyImageData(data.images);
    //           setIsFileUploading(false);
    //           // console.log('from 0st');
    //         }

    setIsFileUploading(true);
    // console.log(readyImagePromise, "okay");
    const uploadPromises = infos.map(async (infoObj,index) => {

      const data = await ImageUploader(
        Seller,
        "recipes/steps",
        infoObj.stepImage
      );
      console.log("step",index,infoObj.stepNames);
      // console.log(onUploadProgress(),'%')
      if (data.status === "Success") {
        StepData.push({
          stepNumber: index,
          stepName: infoObj.stepNames,
          Video: infoObj.Videos,
          Photo: data.images,
        });
        console.log("from 1st");
      } else {
        setIsFileUploading(false);
      }
    });
    Promise.all(uploadPromises).then((data) => {
      console.log(data, readyImagePromise, uploadPromises, "fi");
      setIsFileUploading(false);
      if (!isFileUploading) {
        console.log(RecipeData);
        axios
          .post(`${BaseURL}/create-recipe`, RecipeData)
          .then((res) => {
            console.log(res.status);
            if (res.status === 200) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Recipe created successfully!!!",
                showConfirmButton: false,
                timer: 1500,
              });

              navigate("/my-create-recipe");
            }
          })
          .catch((err) => {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Network Error !!!",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
    //})
    // .catch((err) => {
    //   console.log(err);
    // });
  };

  // DRAG & DROP RECIPE LIST START //
  let infoItemDrag = useRef();
  let infoItemDragOver = useRef();

  function D_Start(e, index) {
    infoItemDrag.current = index;
  }
  function D_Enter(e, index) {
    infoItemDragOver.current = index;

    const cpArr = [...infos];

    let finalArr = [];

    cpArr.forEach((item) => {
      finalArr.push({
        stepNames: item.stepNames,
        stepImage: item.stepImage,
        isDragging: false,
        isEditable: false,
      });
    });

    finalArr[index].isDragging = true;

    setInfos(finalArr);
  }
  function D_End(e, index) {
    const arr1 = [...infos];

    const info_item_main = arr1[infoItemDrag.current];
    arr1.splice(infoItemDrag.current, 1);
    arr1.splice(infoItemDragOver.current, 0, info_item_main);

    infoItemDrag.current = null;
    infoItemDragOver.current = null;

    let f_arr = [];

    arr1.forEach((item) => {
      f_arr.push({
        stepNames: item.stepNames,
        stepImage: item.stepImage,
        isDragging: false,
        isEditable: false,
      });
    });

    setInfos(f_arr);

    console.log(infos);
  }

  // DRAG & DROP RECIPE LIST END //

  return (
    <>
      {" "}
      <div className="container  ">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">{labels.formTitle.en}</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleRecipeSubmit}>
                  <div className="row">
                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12 col-xl-6 col-xs-12   mb-3">
                      <label for="validationCustom01">
                        {labels.recipeName.en} *
                      </label>
                      <input
                        type="text"
                        id="validationCustom01"
                        className="form-control input-default "
                        placeholder={labels.recipeName.en}
                        label="Name:"
                        required
                        name="RecipeName"
                      />
                      <div className="valid-feedback">
                        plz enter recipe title
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-sm-12 col-12  col-xs-12">
                      <div className="form-group mb-3 col-md-4 col-lg-12">
                        <label>
                          {labels.category.en} {"*"}{" "}
                        </label>
                        <select
                          required
                          name="recipeCategory"
                          id="inputState"
                          className="form-control"
                        >
                          <option value="">{labels.category.en}</option>
                          {resCat.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.categoryName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-lg-12 mb-3">
                      <label>{labels.description.en}</label>
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          removePlugins: [
                            "EasyImage",
                            "ImageUpload",
                            "MediaEmbed",
                          ],
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setEditorData(data);
                        }}
                        style={{ height: 20 }}
                      />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <label>{labels.foodImg.en} *</label>
                    {(!selectedImage || selectedImage.length === 0) && (
                      <>
                        <div className="col-6">
                          <input
                            name="readyFoodImage"
                            type="file"
                            id="input-file-upload"
                            onChange={handleFileChange}
                          />
                          <label
                            id="label-file-upload"
                            htmlFor="input-file-upload"
                          >
                            <div>
                              <p>Drag and drop your file here or</p>
                              <span className="upload-button">
                                Upload a file
                              </span>
                            </div>
                          </label>
                        </div>
                        <div className="col-6"></div>
                      </>
                    )}

                    {selectedImage && (
                      <>
                        <div
                          className="col-6"
                          style={{ position: "relative", top: 30 }}
                        >
                          {selectedImage.map((item, index) => {
                            console.log(item);
                            return (
                              <div>
                                <img
                                  style={{ height: "100px" }}
                                  className="img-fluid"
                                  alt="not found"
                                  src={URL.createObjectURL(item)}
                                />

                                <br />
                                <Button
                                  className="my-3"
                                  onClick={() => setSelectedImage(null)}
                                >
                                  Remove
                                </Button>
                              </div>
                            );
                          })}
                        </div>
                        <div className="col-6"></div>
                      </>
                    )}
                  </div>
                  <div className="row ">
                    <div className="col-lg-4 col-xxl-4 col-md-4 col-sm-12 col-xs-12 col-12 ">
                      <div className="form-group mb-3">
                        <label>{labels.preparationTime.en}</label>
                        <input
                          type="text"
                          className="form-control input-default "
                          placeholder={labels.preparationTime.en}
                          name="preparationTime"
                        />
                      </div>
                    </div>

                    <div className="col-lg-4 col-xxl-4 col-md-4 col-sm-12 col-xs-12 col-12">
                      <div className="form-group mb-3">
                        <label>{labels.cookingTime.en}</label>
                        <input
                          type="text"
                          className="form-control input-default "
                          placeholder={labels.preparationTime.en}
                          name="cookingTime"
                        />
                      </div>
                    </div>

                    <div className="col-lg-4 col-xxl-4 col-md-4 col-sm-12 col-xs-12 col-12">
                      <div className="form-group mb-3">
                        <label>{labels.serves.en}</label>
                        <input
                          type="text"
                          className="form-control input-default "
                          placeholder={labels.serves.en}
                          name="serves"
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label>Ingredient List</label>
                    {/* <ul class="list-group">
                      {ingredients?.map((item, index) => (
                        <li
                          class="list-group-item d-flex gap-3 align-items-center"
                          style={{ position: "relative" }}
                        >
                          <div>
                            <span class="badge badge-primary badge-pill">
                              {index + 1}
                            </span>
                          </div>
                          <div>{item.ingredientsName}</div>
                          <div>{item.Qty}</div>

                          <div style={{ position: "absolute", right: 15 }}>
                            <Link
                              to={"#"}
                              onClick={() => {
                                removeIngredientFromList(index);
                              }}
                              className="btn btn-danger shadow btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </li>
                      ))}
                    </ul> */}
                    <ul class="list-group">
                      {ingredients?.map((item, index) => (
                        <li
                          class="list-group-item d-flex gap-3 align-items-center"
                          style={{ position: "relative" }}
                        >
                          <div>
                            <span class="badge badge-primary badge-pill">
                              {index + 1}
                            </span>
                          </div>
                          <div>
                            <input
                              hidden={
                                item.hasOwnProperty("isInputHidden")
                                  ? item.isInputHidden
                                  : true
                              }
                              type="text"
                              className="form-control input-default "
                              placeholder={labels.ingredient.name.en}
                              name={`ingredients[${index}].ingredientsName`}
                              value={item.ingredientsName || ""}
                              onChange={(e) => {
                                setIngredients((prev) => {
                                  prev[index] = {
                                    ...prev[index],
                                    ingredientsName: e.target.value,
                                  };
                                  return [...prev];
                                });
                              }}
                            />

                            {(item.isInputHidden === undefined ||
                              item.isInputHidden) &&
                              item.ingredientsName}
                          </div>
                          <div>
                            <input
                              hidden={
                                item.hasOwnProperty("isInputHidden")
                                  ? item.isInputHidden
                                  : true
                              }
                              type="text"
                              className="form-control input-default "
                              placeholder={labels.ingredient.quantity.en}
                              name={`ingredients[${index}].Qty`}
                              value={item.Qty || ""}
                              onChange={(e) => {
                                setIngredients((prev) => {
                                  prev[index] = {
                                    ...prev[index],
                                    Qty: e.target.value,
                                  };
                                  return [...prev];
                                });
                              }}
                            />

                            {(item.isInputHidden === undefined ||
                              item.isInputHidden) &&
                              (item.Qty || "")}
                          </div>

                          <div style={{ position: "absolute", right: 15 }}>
                          {item.hasOwnProperty("isInputHidden") &&
                            !item.isInputHidden ? (
                             <Link
                                  to={`#`}
                                  state={item}
                                  hidden={
                                    item.hasOwnProperty("isInputHidden")
                                      ? item.isInputHidden
                                      : true
                                  }
                                  className="btn btn-success shadow btn-xs sharp me-1 jabed"
                                  onClick={() => {
                                    setIngredients((prev) => {
                                      prev[index] = {
                                        ...prev[index],
                                        isInputHidden: item.hasOwnProperty(
                                          "isInputHidden"
                                        )
                                          ? !item.isInputHidden
                                          : false,
                                      };
                                      return [...prev];
                                    });
                                  }}
                                >
                                  <i className="fas fa-save"></i>
                            </Link>
                            ) : (
                            <Link
                              to={`#`}
                              state={item}
                              className="btn btn-primary shadow btn-xs sharp me-1"
                              onClick={() => {
                                setIngredients((prev) => {
                                  prev[index] = {
                                    ...prev[index],
                                    isInputHidden: item.hasOwnProperty(
                                      "isInputHidden"
                                    )
                                      ? !item.isInputHidden
                                      : false,
                                  };
                                  return [...prev];
                                });
                              }}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                             )}

                            <Link
                              to={"#"}
                              onClick={() => {
                                removeIngredientFromList(index);
                              }}
                              className="btn btn-danger shadow btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </li>
                      ))}
                    </ul>

                    <Col xl="12" style={{ marginTop: 20 }}>
                      <Card>
                        <Accordion
                          className="accordion accordion-no-gutter "
                          defaultActiveKey="0"
                        >
                          <Accordion.Item>
                            <Accordion.Header as="div">
                              <h5 style={{ paddingTop: "15px" }}>
                                {labels.ingredient.en}
                              </h5>
                            </Accordion.Header>
                            <Accordion.Collapse className="px-5">
                              <div className="row">
                                <div
                                  className="form-group mb-3 col-lg-4 cols-xs-12 col-xxl-4 col-md-4 col-sm-12 col-12"
                                  style={{ padding: 10 }}
                                >
                                  <label>{labels.ingredient.name.en}</label>
                                  <input
                                    type="text"
                                    className="form-control input-default "
                                    placeholder={labels.ingredient.name.en}
                                    name="ingredientsName"
                                    value={ingredientsName}
                                    onChange={(e) =>
                                      setIngredientsName(e.target.value)
                                    }
                                  />
                                </div>

                                <div
                                  className="form-group mb-3 col-lg-3 cols-xs-12 col-xxl-3 col-md-3 col-sm-12 col-12"
                                  style={{ padding: 10 }}
                                >
                                  <label>{labels.ingredient.quantity.en}</label>
                                  <input
                                    type="text"
                                    className="form-control input-default "
                                    placeholder={labels.ingredient.quantity.en}
                                    name="qty"
                                    value={ingredientsQty}
                                    onChange={(e) =>
                                      setIngredientsQty(e.target.value)
                                    }
                                  />
                                </div>

                                {/* <div
                                  className="form-group mb-3 col-lg-3 cols-xs-12 col-xxl-3 col-md-3 col-sm-12 col-12"
                                  style={{ padding: 10 }}
                                >
                                  <label>{labels.ingredient.unit.en}</label>
                                  <input
                                    type="text"
                                    className="form-control input-default "
                                    placeholder={labels.ingredient.unit.en}
                                    name="qtyUnit"
                                    onChange={(e) =>
                                      setIngredientsQtyUnit(e.target.value)
                                    }
                                  />
                                </div> */}

                                <div className="col-lg-2 cols-xs-12 col-xxl-2 col-md-2 col-sm-12 col-12">
                                  <div style={{ marginTop: 33 }}>
                                    <Button
                                      style={{ width: "100%" }}
                                      onClick={addToIngredientsList}
                                    >
                                      <i className="fa fa-plus"></i>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Collapse>
                          </Accordion.Item>
                        </Accordion>
                      </Card>
                    </Col>
                  </div>

                  <div>
                    <label>Recipe Steps</label>
                    <ul class="list-group">
                      {infos?.map((item, index) => (
                        <React.Fragment>
                          <li
                            class="list-group-item d-flex flex-row gap-3 align-items-center"
                            style={{
                              position: "relative",
                              paddingRight: 80,
                              paddingTop: 25,
                              paddingBottom: 25,
                            }}
                            draggable
                            droppable
                            onDragStart={(e) => D_Start(e, index)}
                            onDragEnter={(e) => D_Enter(e, index)}
                            onDragEnd={(e) => D_End(e, index)}
                          >
                            {console.log(item)}
                            {/* add edit option */}
                            <span className="mx-2" style={{ maxWidth: 500 }}>
                              <textarea
                                style={{ display: "block", height: 60 }}
                                hidden={item.isEditable === false}
                                className="textarea_editor form-control bg-transparent"
                                rows="10"
                                cols="100"
                                placeholder={labels.recipeStep.stepName.en}
                                name={`infos[${index}].stepName`}
                                value={item.stepNames || ""}
                                onChange={(e) => {
                                  setInfos((prev) => {
                                    prev[index] = {
                                      ...prev[index],
                                      stepNames: e.target.value,
                                    };
                                    return [...prev];
                                  });
                                }}
                              ></textarea>


                            </span>
                            {/* <span className="mx-2" style={{ maxWidth: 100 }}>
                              <input
                                style={{ display: "block" }}
                                hidden={item.isEditable === false}
                                type="text"
                                className="form-control input-default "
                                placeholder={labels.recipeStep.stepVideo.en}
                                name={`infos[${index}].Video`}
                                value={item.Videos || ""}
                                onChange={(e) => {
                                  setInfos((prev) => {
                                    prev[index] = {
                                      ...prev[index],
                                      Video: e.target.value,
                                    };
                                    return [...prev];
                                  });
                                }}
                              />

                            </span> */}
                            <span
                              hidden={item.isEditable === false}
                              style={{ maxWidth: 300 }}
                            >
                              <input
                                hidden={
                                  item.isEditable === false || item.stepImage
                                }
                                style={{ width: "117px" }}
                                type="file"
                                className="form-control input-default "
                                placeholder={labels.recipeStep.stepImg.en}
                                name={`infos[${index}].Photo`}
                                // value={  item.stepImage||""}
                                onChange={(e) => {
                                  setInfos((prev) => {
                                    console.log(
                                      prev,
                                      prev[index],
                                      "debug",
                                      infos
                                    );
                                    prev[index] = {
                                      ...prev[index],
                                      stepImage: e.target.files[0],
                                    };
                                    console.log(
                                      prev,
                                      prev[index],
                                      infos,
                                      "debug"
                                    );
                                    return [...prev];
                                  });
                                }}
                              />

                              {item.isEditable === true ? (
                                <div>
                                  <img
                                    // hidden={item.isEditable === true}
                                    style={{
                                      // position: "absolute",
                                      // left: 260,
                                      maxHeight: "65px",
                                      maxWidth: "80px",
                                    }}
                                    // alt="not found"
                                    src={
                                      item.stepImage &&
                                      URL.createObjectURL(item.stepImage)
                                    }
                                  />{" "}
                                  <span
                                    hidden={!item.stepImage}
                                    className="btn btn-danger shadow btn-xs sharp"
                                  >
                                    <i
                                      hidden={!item.stepImage}
                                      className="fa fa-remove"
                                      onClick={(e) => {
                                        setInfos((prev) => {
                                          prev[index] = {
                                            ...prev[index],
                                            stepImage: null,
                                          };
                                          return [...prev];
                                        });
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </span>
                            {/* {console.log(item)} */}
                            <span
                              hidden={item.isEditable === true}
                              class="badge badge-primary badge-pill"
                            >
                              {index + 1}
                            </span>

                            {item.isEditable === false ? (
                              <img
                                // hidden={item.isEditable === true}
                                style={{
                                  // position: "absolute",
                                  // left: 260,
                                  maxHeight: "65px",
                                  maxWidth: "80px",
                                }}
                                // alt="not found"
                                src={
                                  item.stepImage &&
                                  URL.createObjectURL(item.stepImage)
                                }
                              />
                            ) : (
                              ""
                            )}
                            <span
                              hidden={item.isEditable === true}
                              style={{ maxWidth: 500 }}
                            >
                              {item.stepNames}
                            </span>

                            {item.isEditable === true ? (
                              <span style={{ position: "absolute", right: 35 }}>
                                {/* <Link
                                className="btn btn-primary shadow btn-xs sharp me-1"
                                to={item.Videos !== '' && item.Videos !== '#' ? item.Videos : '#'}
                                target="_blank"
                              >

                              <i className="fa-solid fa-video"></i>
                              </Link> */}

                                <Link
                                  to={`#`}
                                  state={item}
                                  className="btn btn-success shadow btn-xs sharp me-1"
                                  onClick={() => {
                                    setInfos((prev) => {
                                      prev[index] = {
                                        ...prev[index],
                                        isEditable: false,
                                      };
                                      return [...prev];
                                    });
                                  }}
                                >
                                  <i className="fas fa-save"></i>
                                </Link>
                              </span>
                            ) : (
                              <span style={{ position: "absolute", right: 35 }}>
                                {/* <Link
                                className="btn btn-primary shadow btn-xs sharp me-1"
                                to={item.Videos !== '' && item.Videos !== '#' ? item.Videos : '#'}
                                target="_blank"
                              >

                              <i className="fa-solid fa-video"></i>
                              </Link> */}

                                <Link
                                  to={`#`}
                                  state={item}
                                  className="btn btn-primary shadow btn-xs sharp me-1"
                                  onClick={() => {
                                    item.isEditable = true;
                                  }}
                                  // onClick={() => {
                                  //   setInfos((prev) => {
                                  //     prev[index] = {
                                  //       ...prev[index],
                                  //       isInputHidden: item.hasOwnProperty(
                                  //         "isInputHidden"
                                  //       )
                                  //         ? !item.isInputHidden
                                  //         : false,
                                  //     };
                                  //     return [...prev];
                                  //   });
                                  // }}
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </Link>
                              </span>
                            )}

                            <span style={{ position: "absolute", right: 15 }}>
                              <Link
                                to={"#"}
                                onClick={() => {
                                  removeData(index);
                                }}
                                className="btn btn-danger shadow btn-xs sharp"
                              >
                                <i className="fa fa-trash"></i>
                              </Link>
                            </span>
                          </li>
                          {item.isDragging ? (
                            <div className="drag-indicator"></div>
                          ) : null}
                        </React.Fragment>
                      ))}
                    </ul>

                    <Col xl="12" style={{ marginTop: 20 }}>
                      <Card>
                        <Accordion
                          className="accordion accordion-no-gutter "
                          defaultActiveKey="0"
                        >
                          <Accordion.Item>
                            <Accordion.Header as="div">
                              <h5 style={{ paddingTop: "5px" }}>
                                {labels.recipeStep.en}
                              </h5>
                            </Accordion.Header>
                            <Accordion.Collapse>
                              <div>
                                <div
                                  className="form-group mb-3"
                                  style={{ padding: 10 }}
                                >
                                  <label>{labels.recipeStep.stepName.en}</label>
                                  <textarea
                                    style={{ height: 60 }}
                                    className="textarea_editor form-control bg-transparent"
                                    rows="20"
                                    cols="150"
                                    placeholder={labels.recipeStep.stepName.en}
                                    name="stepName"
                                    value={stepNames}
                                    onChange={(e) =>
                                      setStepName(e.target.value)
                                    }
                                  ></textarea>

                                  {/* <input
                                    type="text"
                                    className="form-control input-default "
                                    placeholder={labels.recipeStep.stepName.en}
                                    value={stepNames}
                                    label="Name:"
                                    name="stepName"
                                    onChange={(e) =>
                                      setStepName(e.target.value)
                                    }
                                  /> */}
                                </div>
                                <div
                                  className="col-12 form-group mb-3"
                                  style={{ padding: 10 }}
                                >
                                  <label>
                                    Step {labels.recipeStep.stepImg.en}
                                  </label>

                                  {(!stepImage || stepImage.length === 0) && (
                                    <div className="col-6">
                                      <input
                                        type="file"
                                        className="form-control input-default "
                                        placeholder={
                                          labels.recipeStep.stepImg.en
                                        }
                                        label="Name:"
                                        name="stepImage"
                                        value={
                                          stepImage ? stepImage.filename : ""
                                        }
                                        onChange={(e) => {
                                          setStepImage(
                                            e.target.files.length > 0
                                              ? e.target.files[0]
                                              : null
                                          );
                                        }}
                                      />
                                    </div>
                                  )}

                                  {stepImage && (
                                    <>
                                      <div
                                        className="col-6"
                                        style={{
                                          position: "relative",
                                          top: 30,
                                        }}
                                      >
                                        <img
                                          style={{ height: "100px" }}
                                          className="img-fluid"
                                          alt="not found"
                                          src={URL.createObjectURL(stepImage)}
                                        />
                                        <br />
                                        <Button
                                          className="my-3"
                                          onClick={() => setStepImage(null)}
                                        >
                                          Remove
                                        </Button>
                                      </div>
                                    </>
                                  )}
                                </div>

                                {/* <div
                                  className="form-group mb-3"
                                  style={{ padding: 10 }}
                                >
                                  <label>
                                    {labels.recipeStep.stepVideo.en}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control input-default "
                                    placeholder={labels.recipeStep.stepVideo.en}
                                    label="Name:"
                                    name="Video"
                                    value={Videos}
                                    onChange={(e) => setVideo(e.target.value)}
                                  />
                                </div> */}

                                <div style={{ padding: 10 }}>
                                  <Button onClick={addToList}>
                                    <span className="fa fa-plus"></span> Add
                                    Step
                                  </Button>
                                </div>
                              </div>
                            </Accordion.Collapse>
                          </Accordion.Item>
                        </Accordion>
                      </Card>
                    </Col>
                  </div>

                  <div className="row">
                    <div className="form-group col-lg-12 mb-3">
                      <label>Recipe Video URL</label>
                      <input
                        type="text"
                        className="form-control input-default "
                        placeholder="Enter your recipe video link"
                        name="recipeVideo"
                        value={recipeVideoData}
                        onChange={(e) => setRecipeVideoData(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* //shoppingList */}
                  <div className="row">
                    <div className="form-group col-lg-12 mb-3">
                      <label>{labels.shoppingList.en}</label>
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          removePlugins: [
                            "EasyImage",
                            "ImageUpload",
                            "MediaEmbed",
                          ],
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setShoppingData(data);
                        }}
                        style={{ height: 20 }}
                      />
                    </div>
                    <div className="form-group col-lg-12 mb-3">
                      <label>{labels.tips.en}</label>
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          removePlugins: [
                            "EasyImage",
                            "ImageUpload",
                            "MediaEmbed",
                          ],
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setTips(data);
                        }}
                        style={{ height: 20 }}
                      />
                    </div>
                  </div>

                  <div className="row d-none">
                    <div className="form-group col-lg-12 mb-3">
                      <label>{labels.protienDetails.en}</label>
                      <textarea
                        className="form-control input-default "
                        placeholder="Protien Details"
                        name="proteinDetails"
                        style={{ height: 100 }}
                      ></textarea>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-lg-12 mb-3">
                      <label>
                        {labels.tags.en} (separate using comma, e.g. "#Recipe,
                        #Tags"){" "}
                      </label>
                      <input
                        type="text"
                        className="form-control input-default "
                        placeholder={labels.tags.en}
                        name="recipeTags"
                        onChange={(e) => setTags(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row d-flex">
                    {/*
                    search engine optimization

                    */}
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="d-flex gap-3">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            name="isMyOwnRecipe"
                          />
                          <span
                            class="form-check-label altClass"
                            for="flexSwitchCheckDefault"
                          >
                            {labels.ownRecipe.en}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xxl-4">
                      {/* <div className="d-flex gap-3">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            name="isFeatured"
                          />
                          <span
                            class="form-check-label altClass"
                            for="flexSwitchCheckDefault"
                          >
                            {labels.featured.en}
                          </span>
                        </div>
                      </div> */}
                    </div>
                    <div className="col-lg-4">
                      <div className="d-flex gap-3">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            defaultChecked
                            id="flexSwitchCheckDefault"
                            name="status"
                          />
                          <span
                            class="form-check-label altClass"
                            for="flexSwitchCheckDefault"
                          >
                            {labels.status.en}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Button
                    type="submit"
                    style={{ width: "100%", marginTop: 10 }}
                  >
                    {" "}
                    Save
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Create_Recipe;
