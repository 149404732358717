import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import { toast } from "react-toastify";
import { Tab, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { ImageUploader } from "../../../ImageUploader/ImageUploader";
import { LanguageContext } from "../../../context/LanguageContext";
import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/editProfile.json";
import { getUserDetails } from "../../../Helper/SessionHelper";
import { useGetSellerInfoQuery } from "../../../Redux/API/ApiSlice";
const identityTab = labels.userDashboard.editProfile.identityTab;

const Identity = () => {
  // const [currentLanguage, setCurrentLanguage] = useState("bn");
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  //Tab labels:
  //const identityTabTitle = getTranslation('tabTitle', currentLanguage,identityTab);
  const successMessage = getTranslation(
    "successMessage",
    currentLanguage,
    identityTab
  );
  const errorMessage = getTranslation(
    "errorMessage",
    currentLanguage,
    identityTab
  );

  const [mainData, setMainData] = useState({});
  const [identityCollection, setIdentityCollection] = useState([]);
  const [images, setImages] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  const seller = localStorage.getItem("UserDetails");

  const onAddIdentity = () => {
    setIdentityCollection((prev) => {
      return [
        ...prev,
        {
          type: "",
          Number: "",
          Img: [],
          isExisting: false,
        },
      ];
    });
  };

  const onRemoveIdentity = (i) => {
    var newIdentityCollection = identityCollection.filter(
      (item, index) => index !== i
    );
    setIdentityCollection(newIdentityCollection);
  };

  const handleBecomeSeller = async (value) => {
    const newImages = identityCollection.filter((m) => !m.isExisting);

    if (newImages) {
      await Promise.all(
        identityCollection.map(async (__item, index) => {
          if (__item.isExisting === false) {
            await Promise.all(
              __item.Img.map(async (photo, photoIndex) => {
                if (typeof photo.name === "string") {
                  const SelectedImageResult = await ImageUploader(
                    seller,
                    "Identity",
                    photo
                  );

                  setIdentityCollection((prev) => {
                    prev[index].Img[photoIndex] = SelectedImageResult.images[0];
                    prev[index].isExisting = true;
                    console.log(prev[index], "xxx");
                    return [...prev];
                  });
                }
              })
            );
          }
        })
      );
    }

    let fdata = {
      user: {
        userDateOfBirth: mainData.userDateOfBirth,
        Identity: identityCollection,
      },
      seller: {},
    };

    setTimeout(() => {
      axios
        .post(`${BaseURL}/update-user-details/${Seller._id}`, fdata)
        .then((res) => {
          if (res.data.status === "Success") {
            toast.success(successMessage, {
              position: "bottom-right",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (res.data.data.sellerID) {
              toast.warning(errorMessage);
            } else toast.warning(errorMessage);
          }
        });
    }, 1000);
  };

  const onSelectType = (event, index) => {
    const value = event.target.value;
    setIdentityCollection((prev) => {
      prev[index].type = value;
      return [...prev];
    });
  };

  const onChangeNumber = (event, index) => {
    const value = event.target.value;
    setIdentityCollection((prev) => {
      prev[index].Number = value;
      return [...prev];
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setMainData((prev) => {
      const data = {
        ...prev,
        [name]: value,
      };

      return data;
    });
  };

  const handleImage = (event, index) => {
    const fileList = Array.from(event.target.files);
    setIdentityCollection((prev) => {
      if (prev[index].Img) {
        prev[index].Img = [...prev[index].Img, ...fileList];
      } else {
        prev[index].Img = [...fileList];
      }

      prev[index].isExisting = false;

      return [...prev];
    });
  };

  const removeImage = (identity, ImageIndex) => {
    setIdentityCollection((prev) => {
      prev[identity].Img.splice(ImageIndex, 1);
      return [...prev];
    });
  };

  useEffect(() => {
    axios
      .get(`${BaseURL}/get-user-details/${Seller._id}`)
      .then((res) => {
        var data = res.data.data[0];

        if (data) {
          var initialData = {
            userDateOfBirth: data.userDateOfBirth,
            Identity: data.Identity,
          };

          if (data.Identity) {
            setIdentityCollection(data.Identity);
          }

          setMainData(initialData);

          reset({
            ...initialData,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reset]);
  const userData = getUserDetails();
  const { data: sellerData, isSuccess: sellerSuccess } = useGetSellerInfoQuery(
    userData._id
  );
  return (
    <Tab.Pane eventKey="12">
      <form onSubmit={handleSubmit(handleBecomeSeller)}>
        <div className="setting-right">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h4>
                {getTranslation(
                  "tabDetailsTitle",
                  currentLanguage,
                  identityTab
                )}
              </h4>
              <Button
                variant="primary"
                className={`${
                  sellerData?.data[0]?.seller[0]?.sellerApproval === "Approved"
                    ? "d-none"
                    : ""
                }`}
                onClick={onAddIdentity}
              >
                <i className="fa fa-plus"></i>{" "}
                {getTranslation("addMoreButton", currentLanguage, identityTab)}
              </Button>
            </div>
            <div className="card-body">
              {identityCollection &&
                identityCollection.map((item, index) => {
                  return (
                    <div>
                      <hr className="my-5" />
                      <div
                        className={`row align-items-center ${
                          sellerData?.data[0]?.seller[0]?.sellerApproval ===
                          "Approved"
                            ? "d-none"
                            : ""
                        }`}
                      >
                        <div className={`col-10`}>
                          <div className="form-group mb-3">
                            <label>
                              {getTranslation(
                                "selectIdType",
                                currentLanguage,
                                identityTab
                              )}{" "}
                            </label>
                            <select
                              name="countryAddress"
                              className="form-control input-default"
                              {...register(`Identity[${index}].type`, {
                                required: true,
                                onChange: (e) => {
                                  onSelectType(e, index);
                                },
                              })}
                            >
                              <option value="">Select Type</option>
                              <option
                                selected={item.type === "NID"}
                                value="NID"
                              >
                                NID
                              </option>
                              <option
                                selected={item.type === "Birth ID"}
                                value="Birth ID"
                              >
                                Birth ID
                              </option>
                              <option
                                selected={item.type === "Passport"}
                                value="Passport"
                              >
                                Passport
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-2">
                          <Button
                            variant="outline-danger"
                            type="button"
                            className="w-100 mt-2"
                            onClick={(e) => {
                              onRemoveIdentity(index);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </Button>
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <label>
                          {getTranslation(
                            "idNumber",
                            currentLanguage,
                            identityTab
                          )}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control input-default "
                          placeholder={getTranslation(
                            "idNumberPlaceholder",
                            currentLanguage,
                            identityTab
                          )}
                          disabled={
                            sellerData?.data[0]?.seller[0]?.sellerApproval ===
                            "Approved"
                          }
                          {...register(`Identity[${index}].Number`, {
                            required: true,
                            onChange: (e) => {
                              onChangeNumber(e, index);
                            },
                          })}
                        />
                      </div>

                      <div>
                        {item.Img && (
                          <div className="row">
                            {item.Img.map((item, _index) => {
                              return (
                                <div
                                  className="col-3 mb-3"
                                  style={{ position: "relative" }}
                                >
                                  <Link
                                    style={{
                                      position: "absolute",
                                      right: 20,
                                      top: 5,
                                    }}
                                    to={"#"}
                                    onClick={() => {
                                      removeImage(index, _index);
                                    }}
                                    className={`btn btn-danger shadow btn-xs sharp ${
                                      sellerData?.data[0]?.seller[0]
                                        ?.sellerApproval === "Approved"
                                        ? "d-none"
                                        : ""
                                    }`}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Link>

                                  <img
                                    className="img-fluid"
                                    style={{
                                      maxHeight: "200px",
                                      maxWidth: "100%",
                                    }}
                                    alt="not found"
                                    src={
                                      typeof item.name !== "string"
                                        ? item.orginalImageUrl
                                        : URL.createObjectURL(item)
                                    }
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                        <div
                          className={`${
                            sellerData?.data[0]?.seller[0]?.sellerApproval ===
                            "Approved"
                              ? "d-none"
                              : ""
                          }`}
                        >
                          <input
                            hidden
                            name="readyFoodImage"
                            type="file"
                            multiple
                            id={`input-file-upload-identity-${index}`}
                            onChange={(e) => {
                              handleImage(e, index);
                            }}
                          />
                          <label
                            id="label-file-upload"
                            htmlFor={`input-file-upload-identity-${index}`}
                            className="text-center py-5"
                          >
                            <div>
                              <p>
                                {getTranslation(
                                  "uploadBoxDragAndDropText1",
                                  currentLanguage,
                                  identityTab
                                )}
                              </p>
                              <span className="upload-button">
                                {getTranslation(
                                  "uploadBoxDragAndDropText2",
                                  currentLanguage,
                                  identityTab
                                )}
                              </span>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                })}

              <div className="col-12 mt-3">
                <div className="setting-input">
                  <label htmlFor="exampleInputtext" className="form-label">
                    {getTranslation(
                      "idDateOfBirth",
                      currentLanguage,
                      identityTab
                    )}
                  </label>
                  <input
                    type="text"
                    disabled={
                      sellerData?.data[0]?.seller[0]?.sellerApproval ===
                      "Approved"
                    }
                    className="form-control input-default "
                    placeholder="Date of Birth"
                    {...register("userDateOfBirth", {
                      required: true,
                      onChange: handleInputChange,
                    })}
                  />
                </div>
              </div>

              <div
                className={`col-12 mt-3 ${
                  sellerData?.data[0]?.seller[0]?.sellerApproval === "Approved"
                    ? "d-none"
                    : ""
                }`}
              >
                <Button type="submit" className="w-100">
                  {getTranslation("saveButton", currentLanguage, identityTab)}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Tab.Pane>
  );
};
export default Identity;
