
import React, { useState, useEffect, useRef } from "react";

import { Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, redirect, useNavigate } from "react-router-dom";
// import { Redirect } from 'react-router-dom';
import {
  getRiderInfo,
  getSeller,
  logoutUser,
} from "../../../Redux/feature/Authentication/AuthenticationSlice";
import { getUserDetails } from "../../../Helper/SessionHelper";
import {
  useGetRiderInfoQuery,
  useGetSellerInfoQuery,
  useUpdateHandleMutation,
} from "../../../Redux/API/ApiSlice";
import { getSellerAPI } from "../../../API/SellerAPI";
import { FaSignOutAlt } from "react-icons/fa";

const LocationIcon = <i className="fa-solid fa-location-dot mx-2 " />;

function AddSearchSlider() {
  //alert(111);
  let SearchBlog = document.getElementById("Search-Blog");
  let BoxTab = document.getElementById("close-searchbox");
  setTimeout(() => {
    if (BoxTab.classList.contains("active")) {
      return (
        BoxTab.classList.remove("active"), SearchBlog.classList.remove("active")
      );
    } else {
      return BoxTab.classList.add("active"), SearchBlog.classList.add("active");
    }
  }, 100);
}

const Header = ({ onNote }) => {
  // const history = useHistory();
  useEffect(() => {}, []);
  //   const user = useSelector((state) => state.authentication.seller);
  //   console.log(user, "user");

  const [approvedSeller, setApprovedSeller] = useState(false);
  const [approvedRider,setApprovedRider] = useState(false);
  const userData = getUserDetails();

  const { data, isSuccess: userInfoSuccess } = useGetSellerInfoQuery(
    userData?._id
  );
  const {data:riderData,isSuccess:riderSuccess} = useGetRiderInfoQuery( userData?._id)
  console.log(riderData,'rider');
  if (userInfoSuccess) {
    console.log(data);
  }
  const navigate = useNavigate();

  const dispatch = useDispatch();
  dispatch(getSeller(data?.data));
  dispatch(getRiderInfo(riderData?.data));
  //   useEffect(()=>{
  // getSellerAPI(userData._id)
  //   },[])
  let seller = useSelector((state) => state.Authentication.seller);
  let user = useSelector((state) => state.Authentication.user);
  let rider = useSelector(state=>state.Authentication.riderInfo)
  console.log(user, "user from header", rider,seller);
  // const useSelector = useSelector()
  // console.log(data);
  // console.log(userData,'sdfasf')
  // const {data} = useGetSellerInfoQuery(userData._id)

  // const dispatch= useDispatch()
  const handleLogOut = () => {
    localStorage.removeItem("Token");
    localStorage.removeItem("UserDetails"); // Remove user from local storage

    dispatch(logoutUser());
    navigate("/");

    window.location.reload(true);
  };

  const [MoodData, { isError, isSuccess }] = useUpdateHandleMutation();
  const handleMood = (data) => {
    MoodData({ id: userData._id, data: { userType: data } });
    console.log(isSuccess);
    // redirect("/");
    const timer = setTimeout(() => {
      // history.push("/");

      navigate("/");
      window.location.reload();
    }, 1000); // 1000 milliseconds = 1 second

    // Clear the timer to prevent further reload if needed
    return () => clearTimeout(timer);
    // if(isSuccess){
    //   window.location.reload();
    // }
  };
  const handleMoodBuyer = () => {
    MoodData({ id: userData._id, data: { userType: "buyer" } });
    // redirect("/");
    const timer = setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 500); // 1000 milliseconds = 1 second

    // Clear the timer to prevent further reload if needed
    return () => clearTimeout(timer);
    // if(isSuccess){
    //   window.location.reload();
    // }
    // window.location.reload();
  };
  //const [rightSelect, setRightSelect] = useState('Eng');
  const [selectCountry, setSelectCountry] = useState([
    LocationIcon,
    "Bangladesh",
  ]);
  useEffect(() => {
    if (isSuccess) {
      // Wait for 1 second, then reload the website
      const timer = setTimeout(() => {
        window.location.reload();
      }, 1000); // 1000 milliseconds = 1 second

      // Clear the timer to prevent further reload if needed
      return () => clearTimeout(timer);
    }
  }, []);
  // For fix header
  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    if (seller) {
      // alert('asda')
      //   console.log("fist");
      //   setApprovedSeller(true);
      if (seller[0]?.seller[0]?.sellerApproval == "Approved") {
        setApprovedSeller(true);
        //   alert('asda')
        console.log("fist1");
      }
    }
    if(rider){
      if(rider[0]?.riderInfo[0]?.riderApproval==="Approved"){
        setApprovedRider(true)
      }
      
    }
  }, [seller,rider]);
  useEffect(() => {
    // console.log('fist0');
    // console.log('fist0',seller[0],seller[0]?.seller[0]);
    // console.log('fist0',seller[0]?.seller[0]?.length > 0);
    // if (seller[0]?.seller[0]?.length > 0) {
    //   alert('asda')
    //   console.log('fist');
    // //   setApprovedSeller(true);
    //   if (seller.length>0 && seller[0]?.seller[0]?.sellerApproval == "Approved") {
    //     setApprovedSeller(true);
    //     alert('asda')
    // 	console.log('fist1');
    //   }
    // }
    setTimeout(function () {
      // Dropdown
      const dropbtn = document.getElementById("droptoggle1");
      //let dropTooglebtn = dropbtn.getAttribute("aria-expanded");
      function toggledrop() {
        return dropbtn.classList.toggle("show");
      }
      dropbtn.addEventListener("click", toggledrop);
    }, 500);

    // for header fix on scroll
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  const nameFilter = (username) => {
    if (window.innerWidth <= 768) return username.slice(0, 10);
    else return username;
  };
  //const [searchBut, setSearchBut] = useState(false);
  //var path = window.location.pathname.split("/");
  //var name = path[path.length - 1].split("-");
  //var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  //var finalName = filterName.includes("app")
  //  ? filterName.filter((f) => f !== "app")
  //  : filterName.includes("ui")
  //  ? filterName.filter((f) => f !== "ui")
  //  : filterName.includes("uc")
  //  ? filterName.filter((f) => f !== "uc")
  //  : filterName.includes("basic")
  //  ? filterName.filter((f) => f !== "basic")
  //  : filterName.includes("table")
  //  ? filterName.filter((f) => f !== "table")
  //  : filterName.includes("page")
  //  ? filterName.filter((f) => f !== "page")
  //  : filterName.includes("email")
  //  ? filterName.filter((f) => f !== "email")
  //  : filterName.includes("ecom")
  //  ? filterName.filter((f) => f !== "ecom")
  //  : filterName.includes("chart")
  //  ? filterName.filter((f) => f !== "chart")
  //  : filterName.includes("editor")
  //  ? filterName.filter((f) => f !== "editor")
  //  : filterName;
  return (
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="container d-block my-0">
            <div className="d-flex align-items-center justify-content-sm-between justify-content-end">
              <div className="header-left">
                <div className="nav-item d-flex align-items-center">
                  <div className="d-flex header-bx d-none" id="Search-Blog">
                    {/* <select className="selectpicker">
									<option data-icon="fa-solid fa-location-dot mx-2">India</option>
									<option data-icon="fa-solid fa-location-dot mx-2">Nepal</option>
									<option data-icon="fa-solid fa-location-dot mx-2">Bangladesh</option>
									<option data-icon="fa-solid fa-location-dot mx-2">Brazil</option>
									<option data-icon="fa-solid fa-location-dot mx-2">China</option>
									<option data-icon="fa-solid fa-location-dot mx-2">Denmark</option>
									<option data-icon="fa-solid fa-location-dot mx-2">Germany</option>
									<option data-icon="fa-solid fa-location-dot mx-2">Japan</option>
									<option data-icon="fa-solid fa-location-dot mx-2">Lithuania</option>
								</select> */}

                    <Dropdown className="bootstrap-select">
                      <Dropdown.Toggle
                        className="header-select-toggle btn btn-light i-false"
                        as="div"
                      >
                        {selectCountry}{" "}
                        <i className="header-select-icon fa-solid fa-sort-down"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="">
                        <Dropdown.Item
                          onClick={() =>
                            setSelectCountry([LocationIcon, "India"])
                          }
                        >
                          {LocationIcon} India
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setSelectCountry([LocationIcon, "Nepal"])
                          }
                        >
                          {LocationIcon} Nepal
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setSelectCountry([LocationIcon, "Bangladesh"])
                          }
                        >
                          {LocationIcon} Bangladesh
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setSelectCountry([LocationIcon, "Brazil"])
                          }
                        >
                          {LocationIcon} Brazil
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setSelectCountry([LocationIcon, "China"])
                          }
                        >
                          {LocationIcon} China
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setSelectCountry([LocationIcon, "Denmark"])
                          }
                        >
                          {LocationIcon} Denmark
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setSelectCountry([LocationIcon, "Germany"])
                          }
                        >
                          {LocationIcon} Germany
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setSelectCountry([LocationIcon, "Japan"])
                          }
                        >
                          {LocationIcon} Japan
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setSelectCountry([LocationIcon, "Lithuania"])
                          }
                        >
                          {LocationIcon} Lithuania
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div
                      className="input-group search-area2 ps-3"
                      id="Serach-bar"
                      onClick={() => AddSearchSlider()}
                    >
                      <span className="input-group-text h-search">
                        <Link to={"#"}>
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.3"
                              d="M16.6751 19.4916C16.2195 19.036 16.2195 18.2973 16.6751 17.8417C17.1307 17.3861 17.8694 17.3861 18.325 17.8417L22.9917 22.5084C23.4473 22.964 23.4473 23.7027 22.9917 24.1583C22.5361 24.6139 21.7974 24.6139 21.3417 24.1583L16.6751 19.4916Z"
                              fill="var(--primary)"
                            />
                            <path
                              d="M12.8333 18.6667C16.055 18.6667 18.6666 16.055 18.6666 12.8333C18.6666 9.61168 16.055 7 12.8333 7C9.61163 7 6.99996 9.61168 6.99996 12.8333C6.99996 16.055 9.61163 18.6667 12.8333 18.6667ZM12.8333 21C8.32297 21 4.66663 17.3437 4.66663 12.8333C4.66663 8.32301 8.32297 4.66667 12.8333 4.66667C17.3436 4.66667 21 8.32301 21 12.8333C21 17.3437 17.3436 21 12.8333 21Z"
                              fill="var(--primary)"
                            />
                          </svg>
                        </Link>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="What do you want eat today"
                      />
                    </div>
                    <div className="search-drop">
                      <div className="card tag-bx">
                        <div className="card-header d-block border-0">
                          <h4>Recently Searched</h4>
                          <ul className="d-flex align-items-center flex-wrap">
                            <li>
                              <Link
                                to={"#"}
                                className="btn btn-outline-light btn-rounded me-2"
                              >
                                Bakery
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={"#"}
                                className="btn btn-outline-light btn-rounded me-2"
                              >
                                Burger
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={"#"}
                                className="btn btn-outline-light btn-rounded me-2"
                              >
                                Beverage
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={"#"}
                                className="btn btn-outline-light btn-rounded me-2"
                              >
                                Chicken
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={"#"}
                                className="btn btn-outline-light btn-rounded mt-3 mt-xl-0"
                              >
                                Pizza
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="card-body pt-0">
                          <h4>popular Cuisines</h4>
                          <div className="swiper mySwiper-4">
                            {/* <HeaderSlider /> */}
                          </div>
                        </div>
                      </div>
                      <div
                        id="close-searchbox"
                        onClick={() => AddSearchSlider()}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <ul className="navbar-nav header-right">
                <li>
                  <Dropdown className=" header-profile2 ">
                    <Dropdown.Toggle
                      as="a"
                      className={`nav-link i-false cursor-pointer `}
                      id="droptoggle1"
                      //onClick={DropBtnblog()}
                    >
                      <div className="header-info2 d-flex align-items-center">
                        {/* <img src={Seller?.userPhoto} alt="" /> */}
                        <div className="d-flex align-items-center sidebar-info">
                          {/* {userData?.length > 0 ? ( */}
                          <img
                            src={
                              "https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png"
                            }
                            alt=""
                          />
                          {/* ) : null} */}

                          <div>
                            {/* {console.log(userData, "user email")} */}
                            <h6 className="font-w500 mb-0 ms-2">
                              {/* Welcome */}
                              {userData && userData.userFullName

                                ? nameFilter(userData.userFullName)
                                : ""}

                            </h6>
                          </div>
                          <i className="fas fa-chevron-down"></i>
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-end">
                      {/* <Link
                        to='./app-profile'
                        className='dropdown-item ai-icon '
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='text-primary'
                          width='18'
                          height='18'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        >
                          <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2'></path>
                          <circle cx='12' cy='7' r='4'></circle>
                        </svg>
                        <span className='ms-2'>Profile</span>
                      </Link> */}

                      {/* <Link
                        to='./edit-profile'
                        className='dropdown-item ai-icon'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='text-primary'
                          width='18'
                          height='18'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        >
                          <path d='M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7'></path>
                          <path d='M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z'></path>
                        </svg>
                        <span className='ms-2'>Edit Profile</span>
                      </Link> */}
                      {seller &&
                      (seller[0]?.userType === "buyer" ||
                        seller[0]?.userType === "rider") &&
                      approvedSeller ? (
                        <button
                          className="ms-2 dropdown-item ai-icon"
                          onClick={()=>handleMood("seller")}
                        >
                          Switch Seller
                        </button>
                      ) : null}
                      {seller &&
                      (seller[0]?.userType === "seller" ||
                        seller[0]?.userType === "rider") ? (
                        <button
                          className="ms-2 dropdown-item ai-icon"
                          onClick={handleMoodBuyer}
                        >
                          Switch Buyer
                        </button>
                      ) : null}

{(seller && rider )&&
                      (seller[0]?.userType === "seller" ||
                        seller[0]?.userType === "buyer") &&
                        approvedRider ? (
                        <button
                          className="ms-2 dropdown-item ai-icon"
                          onClick={()=>handleMood("rider")}
                        >
                          Switch Rider
                        </button>
                      ) : null}


                      {/* <Button
                        onClick={handleLogOut}
                        className='dropdown-item ai-icon'
                      >
                        LogOut
                      </Button> */}
                      <Link
                        to="#"
                        className="dropdown-item ai-icon"
                        onClick={handleLogOut}
                      >
                        <FaSignOutAlt />
                        <span className="ms-2">Logout</span>
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
