import React, { useEffect, useState } from "react";

import labels from "../../../translationData/rider.json";
import { Button } from "react-bootstrap";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import {
  useAllCountryQuery,
  useGetCityByRegionQuery,
  useGetCuisineByCountryQuery,
  useGetRegionByCountryQuery,
} from "../../../Redux/API/ApiSlice";
import { Link, useNavigate } from "react-router-dom";
import { ImCross } from "react-icons/im";
import { useForm } from "react-hook-form";
import { ImageUploader } from "../../../ImageUploader/ImageUploader";
import Swal from "sweetalert2";
const BecomeRider = () => {
  const [country, setCountry] = useState([]);
  const [region, setRegion] = useState([]);
  const [city, setCity] = useState([]);
  const [countryId, setCountryId] = useState("6505745d7efd849768bce05e");
  const [regionId, setRegionId] = useState("650571b67efd849768bcdf12");
  const [cityId, setCityId] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("Cycle");
  const [userDetails, setUserDetails] = useState({});
  const [identityImgFile, setIdentityImgFile] = useState([]);
  const [identityLoadedImg, setIdentityLoadedImg] = useState([]);
  const [selectedID, setSelectedID] = useState("NID");
  const [isEdit, setIsEdit] = useState(false);
  const rider = JSON.parse(localStorage.getItem("UserDetails"));

  //  React RTK Query Call
  const { data: countryData, isSuccess } = useAllCountryQuery(undefined);

  const {
    data: RegionData,
    isLoading: RegionLoading,
    isSuccess: RegionSuccess,
  } = useGetRegionByCountryQuery(countryId);
  const {
    data: CityData,
    isLoading: CityLoading,
    isSuccess: CitySuccess,
  } = useGetCityByRegionQuery(regionId);
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm();
  //delete image from local
  const handleImageDelete = (index) => {
    const updatedImages = [...identityImgFile];
    updatedImages.splice(index, 1);
    setIdentityImgFile(updatedImages);
  };

  const handleNID = (e) => {
    const file = Array.from(e.target.files);
    setIdentityImgFile([...identityImgFile, ...file]);
  };
  const handleOptionVehicle = (e) => {
    const selectedValue = e.target.value;
    setSelectedVehicle(selectedValue);
  };

  const selectCountry = (e) => {
    console.log("debuf-by");
    const selectCountryValue = e.target.value;
    console.log(selectCountryValue, "debuf-by");
    setCountryId(selectCountryValue);
    console.log("debuf-by", RegionData);
    // GetRegion2(selectCountryValue);
  };

  const selectRegion = (e) => {
    const selectRegionValue = e.target.value;
    setRegionId(selectRegionValue);
    // GetCity2(selectRegionValue);
  };

  const selectCity = (e) => {
    const selectCityValue = e.target.value;
    setCityId(selectCityValue);
  };

  const handleIdentity = (e) => {
    const selectedValue = e.target.value;
    setSelectedID(selectedValue);
  };
  const handleChange = (e) => {
    console.log(e.target.checked);
    setIsEdit(e.target.checked);
  };
  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  const navigate = useNavigate();
  const getUserDetails = async () => {
    const res = await axios.get(`${BaseURL}/get-user-details/${Seller._id}`);
    if (res.data.status === "Success") {
      setUserDetails(res.data.data[0]);
      setSelectedID(res?.data?.data[0]?.Identity[0]?.type);
      setIdentityLoadedImg(res?.data?.data[0]?.Identity[0]?.Img);
      //   setIdentityImgFile(res?.data?.data[0]?.Identity[0]?.Img);
    }
    // console.log(res.data, "res-check", userDetails);
  };
  useEffect(() => {
    getUserDetails();
  }, []);
  const handleRider = async (value) => {
    const { permanentAddress, presentAddress, riderWhatsappNo, vehicleRegNo,NIDnumber,BIDnumber,Passnumber } =
      value;
    const riderData = {
      ...value,
      vehicleType: selectedVehicle,
      riderName: rider?.userFullName,
      riderMobileNo: rider?.userMobileNo,
      userId:rider?._id|| userDetails?._id,
      cityID:cityId
    };
    if (selectedVehicle !== "Cycle") {
      riderData.vehicleDetails = [{ vehicleRegNo }];
    }

    const userData = {

          }

          if(isEdit){
            let nid = [];
            const NidFront = await ImageUploader(rider, "Identity", identityImgFile);
            if (NidFront.status === "Success") {
              // setNidData(NidFront.images);
              nid.push(NidFront.images);
            }
            userData.Identity=[
              {
                type: selectedID,
                Number: value.IDnumber,
                Img: nid[0],
              }
            ]

          }
console.log(riderData,userData);
          axios.post(`${BaseURL}/create-rider`,{
            riderData,
            userData

          })
          .then((res) => {
            if (res.data.status === "Success") {
              Swal.fire({
                icon: "success",
                title: `আপনাকে পেয়ে আমরা আনন্দিত। রাইডার হিসেবে আপনার আবেদন গ্রহন করা হয়েছে।
                আপনার পরবর্তী ধাপগুলো হলো
                ১। আপনার দেওয়া ইমেইলে (${`${userDetails?.email?.slice(0, 2)}${"*".repeat(
                  userDetails?.email.length - 4
                )}${userDetails?.email?.slice(-2)}`}  ) এ একটি মেইল পাঠানো হয়েছে।
                ২। সেই ইমেইলে থাকা লিংক ক্লিক করে আপনার ইমেইল Verify করুন।

                আপনাকে রাইডার হিসেবে অনুমোদন করা হলে আপনার দেওয়া মোবাইল নম্বর (${`${userData?.userMobileNo?.slice(
                  0,
                  3
                )}*****${userDetails?.userMobileNo?.slice(
                  8,
                  10
                )}`}) এ SMS এর মধ্যমে জানানো হবে।

                যদি আপনার কোন প্রশ্ন থাকে তাহলে আপনি info@ownfood.com এ যোগাযোগ করুন অথবা নির্দ্বিধায় যোগাযোগ করতে পারেন আমাদের 09643-123456 এই টেলিফোন নম্বরে।
               `,
                showConfirmButton: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/");
                } else if (result.isDenied) {
                  // Swal.fire('Changes are not saved', '', 'info')
                }
              });}
              else if(res?.data?.keyPattern?.userId===1){
                Swal.fire({
                  icon:'error',
                  text:'already apply for rider'
                })
                console.log(res)
              }

          })
  };
  return (
    <div className=' ms-5'>
      <form onSubmit={handleSubmit(handleRider)} className='row rider-form'>
        {" "}
        <div className='col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 form-group mb-3'>
          <label>{labels.riderName.en}</label>
          <input
            type='text'
            className='form-control input-default '
            placeholder='Rider Name'
            label='Name:'
            name='Ridername'
            defaultValue={rider?.userFullName}
          />
        </div>
        {/*  */}
        <div className='col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 form-group mb-3'>
          <label>{labels.riderContact.en}</label>
          <input
            type='text'
            value={userDetails?.userMobileNo}
            className='form-control input-default '
            placeholder='Rider Mobile No'
            label='Name:'
            name='riderMobileNo'
          />
        </div>
        <div className='col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 form-group mb-3'>
          <label>{labels.riderWhatsapp.en}</label>
          <input
            type='text'
            className='form-control input-default '
            placeholder='Rider Whatsapp No'
            label='Name:'
            {...register("riderWhatsappNo", { required: true })}
            // name='riderWhatsappNo'
          />
          {errors.riderWhatsappNo && (
            <span className='text-danger'>This field is required</span>
          )}
        </div>
        <div className='form-group mb-3'>
          <label>{labels.vehicle.en}</label>
          <select
            name='vehicletype'
            className='form-control'
            // {...register("riderWhatsappNo", { required: true })}
            onChange={handleOptionVehicle}
          >
            <option value='Cycle'>Cycle</option>
            <option value='Motor_Cycle'>Motor Cycle</option>
            {/* <option value="Car">Car</option> */}
          </select>
        </div>
        {(selectedVehicle === "Car" || selectedVehicle === "Motor_Cycle") && (
          <>
            <div className='form-group mb-3'>
              <label>{labels.vehicle.vehicleRegNo.en}</label>
              <input
                type='text'
                className='form-control input-default '
                placeholder={labels.vehicle.vehicleRegNo.en}
                label='Name:'
                {...register("vehicleRegNo")}
                // name='vehicleRegNo'
              />
            </div>
          </>
        )}
        <div className='col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group mb-3'>
          <label>Present Address</label>
          <input
            type='text'
            className='form-control input-default '
            placeholder='Present Address'
            label='Name:'
            {...register("presentAddress", { required: true })}
            // name='present_address'
          />
          {errors.presentAddress && (
            <span className='text-danger'>This field is required</span>
          )}
        </div>
        <div className='col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group mb-3'>
          <label>Permanent Address</label>
          <input
            type='text'
            className='form-control input-default '
            placeholder='Permanent Address'
            label='Name:'
            // name='parmanent_address'
            {...register("permanentAddress", { required: true })}
            ////value={data.LicenseNo}
            //onChange={handleChange}
          />
          {errors.permanentAddress && (
            <span className='text-danger'>This field is required</span>
          )}
        </div>
        <div className='col-12'>
          <div className='row shadow-sm'>
            <div className='col-12'>whare interest to work</div>
            {/* <div className='col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group mb-3'>
              <label>{labels.country.en}</label>
              <select onChange={selectCountry} className='form-control'>
                <option>select country</option>
                {isSuccess &&
                  countryData?.data?.map((c) => (
                    <option value={c._id} key={c._id}>
                      {c.countryName}
                    </option>
                  ))}
              </select>
            </div> */}

            {/* <div className='col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group mb-3'>
              <label>{labels.region.en}</label>
              <select onChange={selectRegion} className='form-control'>
                <option>select Region</option>
                {RegionSuccess &&
                  RegionData?.data?.map((c) => (
                    <option value={c._id} key={c._id}>
                      {c.regionName}
                    </option>
                  ))}
              </select>
            </div> */}

            {/* <div className='col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group mb-3'>
              <label>{labels.city.en} </label>
              <select onChange={selectCity} className='form-control'>
                <option>select City</option>
                {CitySuccess &&
                  CityData?.data?.map((c) => (
                    <option value={c._id} key={c._id}>
                      {c.cityName}
                    </option>
                  ))}
              </select>
            </div> */}
            <div className='col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group mb-3'>
              <label>Delivery Service Area</label>
              <input
                type='text'
                className='form-control input-default '
                placeholder='Service Area'
                label='Name:'
                name='service'
                ////value={data.LicenseNo}
                //onChange={handleChange}
              />
            </div>
            <div className='col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group mb-3'>
              <label>{labels.city.en} </label>
              <select onChange={selectCity} className='form-control'>
                <option>select City</option>
                {CitySuccess &&
                  CityData?.data?.map((c) => (
                    <option value={c._id} key={c._id}>
                      {c.cityName}
                    </option>
                  ))}
              </select>
            </div>
            <div className='col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group mb-3'>
              <label>{labels.thana.en} </label>

              <input
                type='text'
                className='form-control input-default '
                placeholder='Address'
                {...register("thana", { required: true })}
                label='Name:'
                // name='service'
              />
              {/* <select className='form-control'>
                <option>select Thana</option>
                {city?.map((c) => (
                  <option value={c._id} key={c._id}>
                    {c.cityName}
                  </option>
                ))}
              </select> */}
            </div>
            <div className='col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group mb-3'>
              <label>address</label>
              <input
                type='text'
                className='form-control input-default '
                placeholder='Address'
                {...register("interestServiceAddress", { required: true })}
                label='Name:'
                // name='service'
              />
              {errors.interestServiceAddress && (
                <span className='text-danger'>This field is required</span>
              )}
            </div>
          </div>
        </div>
        <div className='col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group mb-3'>
          <label>Time Shift Type</label>
          <select
            {...register("riderType", { required: true })}
            name='shiftType'
            className='form-control'
          >
            <option value='FULL_TIME'>Full Time</option>
            <option value='PART_TIME'>Half Time</option>
            <option value='OVER_TIME'>Over Time</option>
          </select>
          {errors.riderType && (
            <span className='text-danger'>This field is required</span>
          )}
        </div>
        <div className='col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group mb-3'>
          <label>Time Shift </label>
          <select
            {...register("timeSchedule", { required: true })}
            // name='timeshift'
            className='form-control'
          >
            <option value='8:00 am - 4:00 pm'>8:00 am - 4:00 pm</option>
            <option value='6:00 pm - 12:00 am'>8:00 am - 4:00 pm</option>
          </select>
          {errors.timeSchedule && (
            <span className='text-danger'>This field is required</span>
          )}
        </div>
        <div className='col-12'>
          <div className='card '>
            <div className='card-body'>
              <div className='col-12'>
                <div class='d-flex justify-content-end form-check form-switch'>
                  <input
                    onChange={handleChange}
                    class='form-check-input'
                    type='checkbox'
                    id='mySwitch'
                    name='darkmode'
                    //   value='yes'
                  />
                  <label class='form-check-label' for='mySwitch'>
                    Edit
                  </label>
                </div>
              </div>

              {(isEdit === true || userDetails?.Identity?.length < 0) && (
                <div className='row'>
                  <div className='col-12'>
                    <div className='mb-3'>
                      <label className='form-label'>Identity</label>
                      <select
                        name='identity'
                        className='form-control input-default '
                        value={
                          userDetails?.Identity?.length >= 0 &&
                          userDetails?.Identity?.type
                        }
                        //   disabled={userDetails?.Identity?.length >= 0}
                        onChange={handleIdentity}
                      >
                        <option
                          selected={
                            userDetails?.Identity?.length >= 0 &&
                            userDetails?.Identity[0]?.type === "NID"
                          }
                          value='NID'
                        >
                          NID
                        </option>
                        <option
                          selected={
                            userDetails?.Identity?.length >= 0 &&
                            userDetails?.Identity[0]?.type === "passport"
                          }
                          value='passport'
                        >
                          Passport
                        </option>
                        <option
                          selected={
                            userDetails?.Identity?.length >= 0 &&
                            userDetails?.Identity[0]?.type === "BID"
                          }
                          value='BID'
                        >
                          Birth Certificate
                        </option>
                      </select>
                    </div>
                  </div>

                  {selectedID === "NID" && (
                    <div>
                      <input
                        type='text'
                        //   defaultValue={
                        //     userDetails?.Identity &&
                        //     userDetails?.Identity[0]?.Number
                        //   }
                        {...register("IDnumber")}

                        className='form-control'
                        placeholder='Enter Your  NID Number'
                        name='IDnumber'
                      />

                      <div className='row'>
                        {/* <div className='col-12'> */}
                        {identityImgFile.length > 0 &&
                          identityImgFile.map((i, index) => (
                            <div
                              className='position-relative d-flex'
                              style={{ width: "80px" }}
                            >
                              <div
                                style={{
                                  height: "70px",
                                  overflow: "hidden",
                                  width: "80px",
                                }}
                              >
                                <img
                                  className='img-fluid'
                                  src={
                                    typeof i.orginalImageUrl !== "string"
                                      ? URL.createObjectURL(i)
                                      : i.orginalImageUrl
                                  }
                                  alt=''
                                />
                              </div>
                              <div className='position-absolute top-0 end-0'>
                                <ImCross
                                  onClick={() => handleImageDelete(index)}
                                  color='red'
                                />
                              </div>
                            </div>
                          ))}
                        {/* </div> */}
                        <div className='col-12 mt-4'>
                          <label>NID Image</label>
                          <input
                            type='file'
                            //   required={!identityImgFile}
                            id='nid-front'
                            multiple
                            className=' form-control input-default'
                            onChange={handleNID}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {selectedID === "BID" && (
                    <div>
                      <input
                        type='text'
                        className='form-control'
                        {...register("IDnumber")}

                        placeholder='Enter Your Birth ID Number'
                        name='IDnumber'
                      />

                      <div className='row'>
                        <div className='col-6'>
                          {identityImgFile.length > 0 &&
                            identityImgFile.map((i, index) => (
                              <div
                                className='position-relative d-flex'
                                style={{ width: "80px" }}
                              >
                                <div
                                  style={{
                                    height: "70px",
                                    overflow: "hidden",
                                    width: "80px",
                                  }}
                                >
                                  <img
                                    className='img-fluid'
                                    src={
                                      typeof i.orginalImageUrl !== "string"
                                        ? URL.createObjectURL(i)
                                        : i.orginalImageUrl
                                    }
                                    alt=''
                                  />
                                </div>
                                <div className='position-absolute top-0 end-0'>
                                  <ImCross
                                    onClick={() => handleImageDelete(index)}
                                    color='red'
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className='col-12 mt-4'>
                          <label>BirthImage</label>
                          <input
                            type='file'
                            //   required={!identityImgFile}
                            id='nid-front'
                            multiple
                            // id='input-file-upload'

                            className=' form-control input-default'
                            onChange={handleNID}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedID === "passport" && (
                    <div>
                      <input
                        type='text'
                        {...register("IDnumber")}
                        //   defaultValue={userDetails?.Identity[0]?.Number}
                        className='form-control'
                        placeholder='Enter Your Passport Number'
                        name='IDnumber'
                      />
                      <div className='row'>
                        <div className='col-6'>
                          {identityImgFile.length > 0 &&
                            identityImgFile.map((i, index) => (
                              <div
                                className='position-relative d-flex'
                                style={{ width: "80px" }}
                              >
                                <div
                                  style={{
                                    height: "70px",
                                    overflow: "hidden",
                                    width: "80px",
                                  }}
                                >
                                  <img
                                    className='img-fluid'
                                    src={
                                      typeof i.orginalImageUrl !== "string"
                                        ? URL.createObjectURL(i)
                                        : i.orginalImageUrl
                                    }
                                    alt=''
                                  />
                                </div>
                                <div className='position-absolute top-0 end-0'>
                                  <ImCross
                                    onClick={() => handleImageDelete(index)}
                                    color='red'
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className='col-12 mt-4'>
                          <label>
                            passport image
                            {/* {inputLabels.identity.passport.passportImg.bn} */}
                          </label>
                          <input
                            type='file'
                            // required={!identityImgFile}
                            id='nid-front'
                            multiple
                            // id='input-file-upload'

                            className=' form-control input-default'
                            onChange={handleNID}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {isEdit === false && userDetails?.Identity?.length > 0 && (
                <div className='row'>
                  <div className='col-12'>
                    <div className='mb-3'>
                      <label className='form-label'>Identity</label>
                      <select
                        name='identity'
                        className='form-control input-default '
                        value={
                          userDetails?.Identity?.length >= 0 &&
                          userDetails?.Identity?.type
                        }
                        //   onChange={handleIdentity}
                      >
                        <option
                          selected={
                            userDetails?.Identity?.length >= 0 &&
                            userDetails?.Identity[0]?.type === "NID"
                          }
                          value='NID'
                        >
                          NID
                        </option>
                        <option
                          selected={
                            userDetails?.Identity?.length >= 0 &&
                            userDetails?.Identity[0]?.type === "passport"
                          }
                          value='passport'
                        >
                          Passport
                        </option>
                        <option
                          selected={
                            userDetails?.Identity?.length >= 0 &&
                            userDetails?.Identity[0]?.type === "BID"
                          }
                          value='BID'
                        >
                          Birth Certificate
                        </option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <input
                      type='text'
                      defaultValue={
                        userDetails?.Identity &&
                        userDetails?.Identity[0]?.Number
                      }
                      className='form-control'
                      placeholder='Enter Your  NID Number'
                      name='NIDnumber'
                    />

                    <div className='row'>
                      <div className='col-6 mt-2'>
                        {identityLoadedImg.length > 0 &&
                          identityLoadedImg.map((i, index) => (
                            <div
                              className='position-relative d-flex'
                              style={{ width: "80px" }}
                            >
                              <div
                                style={{
                                  height: "70px",
                                  overflow: "hidden",
                                  width: "80px",
                                }}
                              >
                                <img
                                  className='img-fluid'
                                  src={
                                    typeof i.orginalImageUrl !== "string"
                                      ? URL.createObjectURL(i)
                                      : i.orginalImageUrl
                                  }
                                  alt=''
                                />
                              </div>
                              {/* <div className='position-absolute top-0 end-0'>
                                <ImCross
                                  onClick={() => handleImageDelete(index)}
                                  color='red'
                                />
                              </div> */}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Button type='submit'>Apply for Rider</Button>
      </form>
    </div>
  );
};
export default BecomeRider;
