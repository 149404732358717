import React, { useState, useEffect, useContext } from "react";

import { LanguageContext } from "../../../context/LanguageContext";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import { toast } from "react-toastify";
import { Tab, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAllCountryQuery } from "../../../Redux/API/ApiSlice";
import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/editProfile.json";
const cuisinesTab = labels.userDashboard.editProfile.cuisinesTab;

const Cuisines = () => {
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  const successMessage = getTranslation(
    "successMessage",
    currentLanguage,
    cuisinesTab
  );
  const errorMessage = getTranslation(
    "errorMessage",
    currentLanguage,
    cuisinesTab
  );

  const [mainData, setMainData] = useState({});
  const [newCuisine, setNewCuisine] = useState([]);
  const { data, isSuccess } = useAllCountryQuery(undefined);

  const { handleSubmit, reset } = useForm();

  const Seller = JSON.parse(localStorage.getItem("UserDetails"));

  const handleCountryChange = async (event, index) => {
    const newValue = event.target.value;

    const __cuisine = await axios
      .get(`${BaseURL}/get-cuisine-by-country/${newValue}`)
      .then((res) => res);

    if (__cuisine) {
      setNewCuisine((prev) => {
        prev[index].options = __cuisine.data.data || [];
        return [...prev];
      });
    }
  };

  const handleCuisineCountryChange = (event, index) => {
    const value = event.target.value;
    setNewCuisine((prev) => {
      prev[index].countryID = value;
      return [...prev];
    });
  };

  const handleCuisineChange = (event, index) => {
    const value = event.target.value;
    setNewCuisine((prev) => {
      prev[index].cuisineID = value;
      return [...prev];
    });
  };

  const handleMoreCuisine = () => {
    setNewCuisine((prev) => {
      return [
        ...(prev || []),
        {
          cuisineID: "",
          countryID: "",
          cuisineName: "",
          countryName: "",
          options: [],
        },
      ];
    });
  };

  const removeCuisine = (i) => {
    setNewCuisine(newCuisine.filter((item, index) => index != i));
  };

  const handleBecomeSeller = async (value) => {
    let fdata = {
      user: {},
      seller: {
        cuisinesArray: [...newCuisine],
      },
    };

    axios
      .post(`${BaseURL}/update-user-details/${Seller._id}`, fdata)
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success(successMessage, {
            position: "bottom-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          if (res.data.data.sellerID) {
            toast.warning(errorMessage);
          } else toast.warning(errorMessage);
        }
      });
  };

  useEffect(() => {
    axios
      .get(`${BaseURL}/get-user-details/${Seller._id}`)
      .then((res) => {
        var data = res.data.data[0].sellerData;
        var initialData = {
          cuisinesArray: data.cuisinesArray,
        };

        setMainData(initialData);

        //set cuisines
        if (
          res.data.data &&
          res.data.data[0] &&
          res.data.data[0].sellerData &&
          res.data.data[0].sellerData.cuisinesArray.length > 0
        ) {
          res.data.data[0].sellerData.cuisinesArray.forEach(
            async (item, index) => {
              const __cuisine = await axios
                .get(`${BaseURL}/get-cuisine-by-country/${item.countryID}`)
                .then((res) => res);
              setNewCuisine((prev) => {
                return [
                  ...(prev || []),
                  {
                    countryID: item.countryID,
                    cuisineID: item.cuisineID,
                    countryName: item.countryName,
                    cuisineName: item.cuisineName,
                    options: __cuisine.data.data || [],
                  },
                ];
              });
            }
          );
        } else {
          setNewCuisine([
            {
              cuisineID: "",
              countryID: "",
              countryName: "",
              cuisineName: "",
              options: [],
            },
          ]);
        }

        reset({
          ...initialData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reset, setNewCuisine]);
  console.log(newCuisine, "cuisine data");

  return (
    <Tab.Pane eventKey="4">
      <form onSubmit={handleSubmit(handleBecomeSeller)}>
        <div className="setting-right">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h3>
                {getTranslation(
                  "tabDetailsTitle",
                  currentLanguage,
                  cuisinesTab
                )}
              </h3>
              <Button className="float-right" block onClick={handleMoreCuisine}>
                <i className="fas fa-plus"></i>
              </Button>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-5">
                  <label>
                    {getTranslation("country", currentLanguage, cuisinesTab)}
                  </label>
                </div>
                <div className="col-5">
                  <label>
                    {getTranslation("cuisine", currentLanguage, cuisinesTab)}
                  </label>
                </div>
              </div>
              {newCuisine &&
                newCuisine.map((_cuisine, index) => {
                  return (
                    <div key={index} className="row align-items-center mb-3">
                      <div className="form-group col-5">
                        <select
                          className="form-control input-default "
                          onChange={(e) => {
                            handleCountryChange(e, index);
                            handleCuisineCountryChange(e, index);
                          }}
                        >
                          <option>
                            {getTranslation(
                              "selectCountry",
                              currentLanguage,
                              cuisinesTab
                            )}
                          </option>
                          {isSuccess &&
                            data.data?.map((item) => {
                              return (
                                <option
                                  selected={_cuisine.countryID === item._id}
                                  value={item._id}
                                >
                                  {item.countryName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="form-group col-5">
                        <select
                          className="form-control input-default "
                          onChange={(e) => {
                            handleCuisineChange(e, index);
                          }}
                        >
                          <option>
                            {getTranslation(
                              "selectCuisine",
                              currentLanguage,
                              cuisinesTab
                            )}
                          </option>
                          {_cuisine &&
                            _cuisine.options?.map((item) => (
                              <option
                                selected={_cuisine.cuisineID === item._id}
                                value={item._id}
                              >
                                {item.cuisineName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-2">
                        <Button
                          variant="danger"
                          onClick={(e) => {
                            removeCuisine(index);
                          }}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </Button>
                      </div>
                    </div>
                  );
                })}

              <div className="row">
                <div className="col-12 mt-3">
                  <Button type="submit" className="w-100">
                    {getTranslation("saveButton", currentLanguage, cuisinesTab)}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Tab.Pane>
  );
};
export default Cuisines;
