import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import { useRef } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import bgimage from "../../../images/no-img-avatar.png";
import { AiFillDelete } from "react-icons/ai";
import axios from "axios";
import { useEffect } from "react";
import { RiDeleteBin2Line } from "react-icons/ri";
// import "../../../css/become_a_seller.css";
// import "./upload.css";
import { BaseURL } from "../../../Helper/config";
// import ImageUploader from "../../../ImageUploader/ImageUploader";
import {
  useAllCountryQuery,
  useGetCityByRegionQuery,
  useGetCuisineByCountryQuery,
  useGetRegionByCountryQuery,
  useGetSellerInfoQuery,
  useGetThanaByRegionQuery,
} from "../../../Redux/API/ApiSlice";
import { useForm } from "react-hook-form";
import { ImageUploader } from "../../../ImageUploader/ImageUploader";
import { getUserDetails } from "../../../Helper/SessionHelper";
import GetLocationByLatLng from "../../../Helper/GetLocationUsingLatLng";
import { useDispatch, useSelector } from "react-redux";
import { getSeller } from "../../../Redux/feature/Authentication/AuthenticationSlice";
import Loader from "../../Common/Loader";
import Swal from "sweetalert2";
import UploaderModel from "../../Common/UploaderModel/UploaderModel";
import labels from "../../../translationData/userDashboardLabels.json";
import { setLoading } from "../../../Redux/feature/ImageLoader/ImageLoaderSlice";
const user = JSON.parse(localStorage.getItem("UserDetails"));

const inputLabels = labels.userDashboard.becomeSellerModule;

const formTitle1 = inputLabels.formTitle1.en;
const formTitle2 = inputLabels.formTitle2.en;
const formTitle3 = inputLabels.formTitle3.en;
const formSubTitle1 = inputLabels.formSubTitle1.en;

const kitchenNameLabel = inputLabels.kitchenName.en;
const countryLabel = inputLabels.country.en;
const regionLabel = inputLabels.region.en;
const cityLabel = inputLabels.city.en;
const location = inputLabels.location.en;
const kitchenPhoto = inputLabels.kitchenPhoto.en;
const videoURL = inputLabels.videoURL.en;
const countryCuisine = inputLabels.cuisineCountry.en;
const cuisineOne = inputLabels.cuisine.en;
const photoTitle = inputLabels.photoTitle.en;

const BecomeSeller = () => {
  const [myCountry, setMyCountry] = useState("6505745d7efd849768bce05e");
  const [myparentsCountry, setMyParentsCountry] = useState(
    "6505745d7efd849768bce05e"
  );
  const [loading, setLoadingState] = useState(false);
  const [currentLocation, setCurrentLocation] = useState();
  const [sellerExistData, setSellerExistData] = useState({
    kitchenName: "", // You can set initial values here
    // cusinesArray: [],
    sellerNationality: "",
    sellerNationalityByBornID: "",
    myRestrictions: "",
    kitchenImages: [],
    isSellInstantFood: false, // You can set initial values here
    myExperimentalFoodMedia: [
      {
        photo: "",
        captionText: "",
      },
    ],
    dishesMedia: [
      {
        captionText: "",
        photo: "",
      },
    ],
    otherFoodSkillsMedia: "",
    // isTakeWishOrder: false, // Uncomment this line if needed
    isTakePreOrder: false,
    isTakeCateringOrder: false,
    sellerID: "",
    cuisinesArray: [],
    // dishesMedia: [], // Uncomment this line if needed
    address: {
      userAddress: "",
      coordinates: {
        lat: 0,
        lng: 0,
      },
    },
  });
  const [sellerid, setSellerid] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm();
  const [selectedImageAmazingDish, setSelectedImageAmazingDish] = useState([]);
  const [selectedImageExperimental, setSelectedImageExperimental] = useState(
    []
  );
  const [kitchenImage, setKitchenImage] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [userAddress, setUserAddress] = useState(null);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [selectedCountryAd, setSelectedCountryAd] = useState(
    "6505745d7efd849768bce05e"
  );
  const [selectedRegionAd, setSelectedRegionAd] = useState(67);
  const [selectedCity, setSelectedCity] = useState();
  const [cuisineId, setCuisineID] = useState("");
  const [newCuisine, setNewCuisine] = useState([]);
  const [othersFoodSkill, setOthersFoodSkill] = useState([]);
  // {photo:[],foodType:""}
  const [foodTypeImage, seTFoodTypeImage] = useState([]);
  const [othersHide, setOthersHide] = useState(true);
  //  React RTK Query Call
  const { data, isSuccess } = useAllCountryQuery(undefined);
  const { data: cuisine, isSuccess: cusineIsSuccess } =
    useGetCuisineByCountryQuery(selectedCountry);
  const {
    data: RegionData,
    isLoading: RegionLoading,
    isSuccess: RegionSuccess,
  } = useGetRegionByCountryQuery(selectedCountryAd);
  const {
    data: CityData,
    isLoading: CityLoading,
    isSuccess: CitySuccess,
  } = useGetThanaByRegionQuery(selectedRegionAd);
  const userData = getUserDetails();
  const { data: sellerData, isSuccess: sellerSuccess } = useGetSellerInfoQuery(
    userData._id
  );
  console.log(sellerData, "from seller data ");
  //   if(sellerData?.data[0]?.seller.length>0 && sellerSuccess){
  //     console.log(sellerData?.data[0]?.seller[0]?.kitchenName, "from seller data if block");
  // setSellerExistData({
  //   kitchenName:'asdf'
  // })
  //   }

  const { data: SellerInfo, isSuccess: userInfoSuccess } =
    useGetSellerInfoQuery(userData._id);
  console.log(SellerInfo, "infoinfo");
  const dispatch = useDispatch();
  dispatch(getSeller(data?.data));

  useEffect(() => {
    const getLocationData = async () => {
      const resultdata = await GetLocationByLatLng(
        parseInt(SellerInfo?.data[0]?.coordinate?.latitude),
        parseInt(SellerInfo?.data[0]?.coordinate?.longitude)
      );
      console.log(resultdata, "from debug2");
      setCurrentLocation(resultdata);
    };
    getLocationData();
  }, []);

  const handleSelect = async (value) => {
    console.log(value);
    const result = await geocodeByAddress(value);
    console.log(result);
    const ll = await getLatLng(result[0]);
    console.log(result, ll);
    setCoordinates({
      lat: ll.lat || 22.376445039851774,
      lng: ll.lng || 91.82384356842148,
    });
    setUserAddress(value);
  };
  console.log(coordinates, userAddress, "df");

  const searchOptions = {
    componentRestrictions: { country: ["BD"] },
  };
  ////console.log((city);
  const handleDrag = (event) => {
    console.log(event.latLng.lat(), event.latLng.lng());
    setCoordinates({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const [foodType, setFoodType] = useState([]);
  const [foodSkills, setFoodSkills] = useState([]);

  const updateUser = async () => {
    axios
      .post(`${BaseURL}/update-users/${userData._id}`, {
        sellerStatus: "PENDING",
      })
      .then((res) => {
        if (res.data.status === "Success") {
          console.log(res.data.data);
          //  setCountry(res.data.data);
        }
      });
  };

  useEffect(() => {
    axios
      .get(`${BaseURL}/get-foodType`)
      .then((res) => {
        setFoodType(res.data.data);
      })
      .catch((Err) => {
        console.log(Err);
      });
  }, []);

  const HandleFoodType = (e, index) => {
    const value = e.target.value;
    if (e.target.checked) {
      if (value === "Others") {
        setOthersHide(false);
        setFoodSkills((prev) => {
          return [
            ...prev,
            {
              index: index,
              foodType: value,
              captionText: "",
              photo: [],
            },
          ];
        });
        document.querySelectorAll(".form-checkbox-none").forEach((checkbox) => {
          checkbox.checked = false;
        });
      } else if (value === "NONE") {
        setFoodSkills([]);
        var blankFoodSkills = [];
        setFoodSkills(blankFoodSkills);
      } else {
        setFoodSkills((prev) => {
          return [
            ...prev,
            {
              index: index,
              foodType: value,
              captionText: "",
              photo: [],
            },
          ];
        });
        document.querySelectorAll(".form-checkbox-none").forEach((checkbox) => {
          checkbox.checked = false;
        });
      }
    } else {
      // Checkbox is unchecked
      var newFoodSkills = foodSkills.filter((a) => a.index !== index);
      setFoodSkills(newFoodSkills);
      console.log(foodSkills, "unchecked ");
      if (value === "NONE") {
        document.querySelectorAll(".form-checkbox-none").forEach((checkbox) => {
          checkbox.checked = false;
        });
      }
    }
  };

  // console.log(foodSkills, "food skills added");
  const HandleFoodTypeImage = (event, index, foodTypeValue) => {
    const fileListone = Array.from(event.target.files);
    console.log(fileListone, index, "files selected");
    console.log(foodSkills, "foodskills array");
    let indexToFind = index; // Replace 42 with the index you want to search for
    let filteredFoodSkills = foodSkills
      .map((a, originalIndex) => ({ data: a, originalIndex }))
      .filter((item) => item.data.index === indexToFind);

    if (filteredFoodSkills.length > 0) {
      let originalIndex = filteredFoodSkills[0].originalIndex; // Get the original index
      setFoodSkills((prev) => {
        prev[originalIndex].photo = [...fileListone];
        prev[originalIndex].isExisting = false;
        return [...prev];
      });
      console.log(foodSkills, filteredFoodSkills, "fk after update");
    } else {
      //if Not Checked but Upload Imageas

      setFoodSkills((prev) => {
        return [
          ...prev,
          {
            index: index,
            foodType: foodTypeValue,
            captionText: "",
            photo: [...fileListone],
            isExisting: false,
          },
        ];
      });
    }
  };

  const [additionalTag, setAdditionalTag] = useState([]);

  useEffect(() => {
    axios
      .get(`${BaseURL}/get-additionaltags`)
      .then((res) => {
        setAdditionalTag(res.data.data);
      })
      .catch((Err) => {
        console.log(Err);
      });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          // setError(null);
        },
        (error) => {
          // setError(error.message);
        }
      );
    } else {
      // setError('Geolocation is not supported by this browser.');
    }
  }, []);

  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  console.log(Seller);

  const [Tag, setTag] = useState([]);

  const handleCheck = (e) => {
    if (e.target.checked) {
      setTag((oldArray) => [...oldArray, e.target.value]);
      console.log(Tag, "hi tag");
    } else {
      removeCities(e);
      console.log(Tag);
    }
  };
  const removeCities = (e) => {
    setTag([...Tag.filter((Tag) => Tag !== e.target.value)]);
  };

  const [sellectFoodType, setSellectFoodType] = useState([]);

  // const HandleFoodType = (e) => {
  //   console.log(e.target.value, "from working !!!!!!!!!!");
  //   if (e.target.checked) {
  //     console.log(e.target.checked);
  //     if (e.target.value === "Others") {
  //       setOthersHide(false);
  //     }
  //     setSellectFoodType((oldArray) => [...oldArray, e.target.value]);
  //   } else {
  //     if (e.target.value === "Others") {
  //       setOthersHide(true);
  //     }
  //     setSellectFoodType([
  //       ...sellectFoodType.filter(
  //         (Restrictions) => Restrictions !== e.target.value
  //       ),
  //     ]);
  //   }
  // };
  // const HandleFoodTypeImage = (e) => {
  //   const file = Array.from(e.target.files);
  //   console.log(file, "fixed bug");
  //   seTFoodTypeImage([...foodTypeImage, ...file]);
  // };

  // console.log("from another !!!!!!!!!!!!!", sellectFoodType, foodTypeImage);
  const handleCountryChangeAdr = (event) => {
    const newValue = event.target.value;
    const selectedCountryId =
      event.target.options[event.target.selectedIndex].getAttribute(
        "data-country-id"
      );
    console.log(selectedCountryId, "idid");
    setSelectedCountryAd(newValue);
  };
  const handleRegionChangeAdr = (event) => {
    const value = event.target.value;
    setSelectedRegionAd(value);
  };
  const handleCuisineChange = (event) => {
    const value = event.target.value;
    setCuisineID(value);
    console.log(cuisineId, "change");
  };
  const handleCountryChange = (event) => {
    const newValue = event.target.value;

    setSelectedCountry(newValue);
  };

  const handleFileChangeAmazingDish = (event) => {
    const fileListone = Array.from(event.target.files);
    console.log(selectedImageAmazingDish, "amazing");

    setSelectedImageAmazingDish([...selectedImageAmazingDish, ...fileListone]);
    console.log(event, selectedImageAmazingDish, "amazing");
  };
  const handleFileChangeExperimental = (event) => {
    const fileList = Array.from(event.target.files);
    console.log(event, selectedImageExperimental, "experimanta");

    setSelectedImageExperimental([...selectedImageExperimental, ...fileList]);
  };
  const handleKitchenPhoto = (event) => {
    const fileList = Array.from(event.target.files);
    setKitchenImage([...kitchenImage, ...fileList]);
  };
  const handleImageDeleteFood = (index) => {
    const updatedImages = [...kitchenImage];
    updatedImages.splice(index, 1);
    // console.log(selectedImageAmazingDish);
    setKitchenImage(updatedImages);
  };
  const handleImageDeleteExperimental = (index) => {
    const updatedImages = [...selectedImageExperimental];
    updatedImages.splice(index, 1);
    // console.log(selectedImageAmazingDish);
    setSelectedImageExperimental(updatedImages);
  };

  const handleImageDeleteAmazingDish = (index) => {
    const updatedImages = [...selectedImageAmazingDish];
    updatedImages.splice(index, 1);
    console.log(selectedImageAmazingDish);
    setSelectedImageAmazingDish(updatedImages);
  };

  const seller = localStorage.getItem("UserDetails");
  const handleMoreCuisine = () => {
    console.log("from click", cuisine, data);
    let cuisineData = cuisine?.data.find((item) => item._id === cuisineId);
    console.log(cuisineData, "click");
    let countryData = data?.data.find((item) => item._id === selectedCountry);
    console.log(countryData, "click");
    setNewCuisine([
      ...newCuisine,
      {
        cuisineID: cuisineData?._id,
        countryID: countryData._id,
        cuisineName: cuisineData?.cuisineName,
        countryName: countryData?.countryName,
      },
    ]);
    console.log("aaaaa", newCuisine);
  };
  const navigate = useNavigate();

  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    setValue("city", selectedCityId);
    setSelectedCity(selectedCityId);
  };

  const handleBecomeSeller = async (value) => {
    // e.preventDefault();
    // const uploadResult = await UploadImage();
    if (
      !coordinates.lng ||
      !coordinates.lat ||
      coordinates.lat === null ||
      coordinates.lng === null
    ) {
      await Swal.fire({
        icon: "info",

        title: "Please enable your location.",

        timer: 2000,
      });
      return;
    }

    // const KitchenName = form.kitchenName.value;
    // const myCountry = value.myCountry;
    // const decentCountry = value.decentCountry;
    const kitchenName = value.kitchenName;
    // const AmazingDishName = value.AmazingDishName;
    // const ExperimentalFoodName = value.ExperimentalFoodName;
    const preOrder = value.preOrder;
    const catering = value.catering;
    const instant = value.instant;
    const wishOrder = value.wishOrder;
    const address = value.address;
    const policeStation = value.policeStation;
    const city = value.city;
    console.log(city, "city");
    // const cuisine=form.cuisine.value;
    // const country=form.country.value
    setLoadingState(true);
    dispatch(setLoading(true));
    const kitchenImageresult = await ImageUploader(
      seller,
      "Kitchen",
      kitchenImage
    );
    const experimentalImageResult = await ImageUploader(
      seller,
      "Kitchen/Ex",
      selectedImageExperimental
    );
    const dishesMedia = await ImageUploader(
      seller,
      "Kitchen/Dishes",
      selectedImageAmazingDish
    );
    setLoadingState(true);
    dispatch(setLoading(true));

    const UploadPromise = foodSkills.map(async (__item, index) => {
      if (__item.isExisting === false) {
        const SelectedImageResult = await ImageUploader(
          seller,
          "Kitchen/OtherFoodSkills",
          __item.photo
        );

        setFoodSkills((prev) => {
          prev[index].photo = SelectedImageResult.images;
          prev[index].isExisting = true;
          return [...prev];
        });
      }
    });
    const cityNumeric = city || selectedCity;
    const regionNumeric = selectedRegionAd;
    const regionNumericId = Number(regionNumeric);
    const cityNumericId = Number(cityNumeric);
    let fdata = {
      kitchenName: kitchenName,
      //   cusinesArray: [data.Slug],
      sellerNationality: myCountry,
      sellerNationalityByBornID: myparentsCountry,
      address: address,
      myRestrictions: Tag,
      kitchenImages: kitchenImageresult.images,
      myExperimentalFoodMedia: [
        {
          photo: experimentalImageResult.images,
          // captionText: ExperimentalFoodName,
        },
      ],
      dishesMedia: [
        {
          //  captionText: AmazingDishName,
          photo: dishesMedia.images,
        },
      ],
      policeStation: policeStation,
      otherFoodSkillsMedia: foodSkills,
      countryID: selectedCountryAd,
      cityNumericId: cityNumericId,
      regionNumericId: regionNumericId,
      isSellInstantFood: instant,
      isTakeWishOrder: wishOrder,
      isTakePreOrder: preOrder,
      isTakeCateringOrder: catering,
      sellerID: Seller._id,
      cuisinesArray: newCuisine,
      // dishesMedia: uploadResult,
      pointLocation: {
        userAddress,
        coordinates: [coordinates.lng, coordinates.lat],
      },
    };
    console.log("check", fdata);
    Promise.all(UploadPromise).then((data) => {
      axios.post(`${BaseURL}/create-become-seller`, fdata).then((res) => {
        console.log(res.data.data);
        if (res.data.status === "Success") {
          updateUser();
          setLoadingState(false);
          dispatch(setLoading(false));
          setSellerid(res.data.data._id);
          navigate(`/become-seller-next-step?id=${res.data.data._id}`);
          // toast.success("Go Next Page", {
          //   position: "bottom-right",
          //   autoClose: 10000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          // });
          Swal.fire({
            icon: "success",
            title: "Go Next Page",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          setLoadingState(false);
          if (res?.data.data?.keyPattern?.sellerID) {
            dispatch(setLoading(false));
            dispatch(setLoading(false));
            Swal.fire({
              icon: "warning",
              title: "Already Apply for seller",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            setLoadingState(false);
            dispatch(setLoading(false));
            Swal.fire({
              icon: "error",
              title: "Try again please",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      });
    });
  };
  const loader = useSelector((state) => state.ImageLoader.loading);

  //if user already fillup first page redirect secound page
  // const userData = getUserDetails();

  return (
    <>
      <UploaderModel loader={loader} />
      <div className="container d-flex ">
        {/* {loading === true ? (
    <Loader />
  ) : ( */}
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">{formTitle1}</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSubmit(handleBecomeSeller)}>
                  <div className="form-group mb-3">
                    <label>
                      {kitchenNameLabel}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control input-default "
                      placeholder={kitchenNameLabel}
                      // name='kitchenName'
                      {...register("kitchenName", { required: true })}
                    />
                    {errors.kitchenName && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="row">
                    <div className="form-group mb-3 col-lg-6">
                      <label>
                        {inputLabels.iAm.en}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        {...register("myCountry", { required: false })}
                        onChange={(e) => setMyCountry(e.target.value)}
                        // name='myCountry'
                        className="form-control input-default  "
                        defaultValue="6505745d7efd849768bce05e"
                      >
                        <option disabled>select country</option>
                        {isSuccess &&
                          data.data?.map((item) => (
                            <option
                              selected={item?.countryName === "Bangladesh"}
                              value={item?._id}
                            >
                              {item.countryName}
                            </option>
                          ))}
                      </select>
                      {errors.myCountry && (
                        <span className="text-danger">
                          This field is required
                        </span>
                      )}
                    </div>
                    <div className="form-group mb-3 col-lg-6">
                      <label>
                        {inputLabels.parants.en}
                        <span className="text-danger">*</span>
                      </label>

                      <select
                        {...register("decentCountry", { required: false })}
                        onChange={(e) => setMyParentsCountry(e.target.value)}
                        // name='decentCountry'
                        className="form-control input-default "
                        defaultValue="6505745d7efd849768bce05e"
                      >
                        <option disabled>Select country</option>
                        {isSuccess &&
                          data.data?.map((item) => (
                            <option
                              selected={item?.countryName === "Bangladesh"}
                              data-country-id={item._id}
                              value={item._id}
                            >
                              {item.countryName}
                            </option>
                          ))}
                      </select>
                      {errors.decentCountry && (
                        <span className="text-danger">
                          This field is required
                        </span>
                      )}
                    </div>
                  </div>

                  {/* Map &  Address Section Start */}

                  <div className="">
                    <h5>{formTitle2} </h5>
                    <div className="form-group mb-3 row ">
                      <div className="col-lg-4">
                        <label>{countryLabel} *</label>

                        <select
                          name="countryAddress"
                          onChange={handleCountryChangeAdr}
                          className="form-control input-default  "
                        >
                          <option disabled>select country</option>
                          {isSuccess &&
                            data.data?.map((item) => (
                              <option
                                selected={item?.countryName === "Bangladesh"}
                                value={item._id}
                              >
                                {item.countryName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-lg-4">
                        <label>{regionLabel} *</label>
                        <select
                          onChange={handleRegionChangeAdr}
                          className="form-control input-default  "
                          defaultValue="650571b67efd849768bcdf12"
                        >
                          <option disabled>Select</option>
                          {RegionSuccess &&
                            !RegionLoading &&
                            RegionData?.data?.map((item) => (
                              <option
                                selected={item?.id == selectedRegionAd}
                                value={item.id}
                              >
                                {item.regionName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-lg-4">
                        <label>{cityLabel} *</label>

                        <select
                          className="form-control input-default  "
                          id="city"
                          {...register("city", {
                            required: true,
                          })}
                          // onChange={handleCityChange}
                        >
                          <option value="">Select Thana</option>
                          {CitySuccess &&
                            CityData.data?.map((item) => (
                              <option
                                key={item?._id}
                                // defaultValue={item?._id}
                                value={item?.id}
                                selected={item?.cityName == "Dhaka"}
                              >
                                {item.cityName}
                              </option>
                            ))}
                        </select>

                        {errors.city && (
                          <span className="text-danger">Select your Thana</span>
                        )}

                        {/* <select
                    className="form-control input-default"
                    {...register("city", { required: false })}
                    onChange={handleCityChange}
                    value={selectedCity}
                  >
                    <option disabled>Select City</option>
                    {CitySuccess &&
                      CityData.data?.map((item) => (
                        <option key={item?._id} value={item?._id}>
                          {item.cityName}
                        </option>
                      ))}
                  </select> */}
                      </div>

                      {/* <div className='col-lg-3'>
                        <div class='form-group'>
                          <label>থানা</label>
                          <input
                            type='text'
                            class='form-control'
                            {...register("policeStation", {
                              required: true,
                            })}
                            placeholder='থানা'
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label>
                            <h6>
                              বিস্তারিত ঠিকানা লিখুন যেভাবে ডেলিভারি ম্যান
                              আপনাকে খুজে পাবে
                            </h6>
                          </label>
                          <textarea
                            class="form-control"
                            {...register("address", {
                              required: true,
                            })}
                            rows="5"
                            placeholder="ঠিকানা লিখুন"
                          ></textarea>
                          {errors.address && (
                            <span className="text-danger">
                              Write your detail address
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 mb-3">
                        {/* <label>
                          <h5>আপনার ঠিকানার পিন পয়েন্ট করুন </h5>
                        </label> */}

                        <label>{location} in Map</label>
                        <PlacesAutocomplete
                          searchOptions={searchOptions}
                          value={userAddress}
                          onChange={setUserAddress}
                          onSelect={handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: "search your address on map ...",
                                  className: "form-control input-default",
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3"></div>
                    <GoogleMap
                      mapContainerStyle={{ height: "200px", width: "100%" }}
                      //onDragEnd={handleDrag}
                      center={coordinates} // Centered at the coordinates of Dhaka, Bangladesh
                      zoom={20}
                      //onLoad={handleMapLoad}
                    >
                      <Marker
                        position={coordinates}
                        draggable
                        onDragEnd={handleDrag}
                      />
                    </GoogleMap>
                  </div>

                  {/* Map & Address Section End */}

                  <div className="mt-3 row">
                    <label>
                      {" "}
                      <h5>
                        {kitchenPhoto} <span className="text-danger">*</span>
                      </h5>
                    </label>
                    <div className="row align-content-end">
                      <div className="col-lg-12">
                        <div>
                          <div>
                            {kitchenImage && (
                              <div className="row justify-content-start">
                                {kitchenImage.map((item, index) => {
                                  return (
                                    <div className="col mb-3">
                                      <img
                                        alt="not found"
                                        // width={"80px"}
                                        key={index}
                                        src={URL.createObjectURL(item)}
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          marginRight: "10px",
                                          display: "inline",
                                        }}
                                      />
                                      <AiFillDelete
                                        onClick={() =>
                                          handleImageDeleteFood(index)
                                        }
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                          <div className="mb-3">
                            <input
                              name="readyFoodImage"
                              type="file"
                              id="input-file-upload"
                              multiple
                              onChange={handleKitchenPhoto}
                            />
                            <label
                              id="label-file-upload"
                              htmlFor="input-file-upload"
                            >
                              <div>
                                <p>আপনার ফাইলটি এখানে টেনে আনুন বা ড্রপ করুন</p>
                                <span className="upload-button">
                                  ছবি আপলোড করুন
                                </span>
                              </div>
                            </label>
                          </div>
                          <div>
                            <input
                              type="text"
                              className="form-control input-default "
                              placeholder={videoURL}
                              label="Name:"
                              name="video"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* cuisine section start */}

                  <div className="mt-3">
                    <label>
                      {" "}
                      <h5>{formSubTitle1}</h5>
                    </label>
                    <div>
                      {newCuisine.length > 0 && (
                        <table className="table">
                          <thead>
                            {console.log("cuisine", newCuisine)}
                            <tr>
                              <th>কি ধরনের খাবার</th>
                              <th>দেশী</th>
                            </tr>
                          </thead>
                          <tbody>
                            {newCuisine.length > 0 &&
                              newCuisine.map((item) => (
                                <tr>
                                  <td>{item.cuisineName}</td>
                                  <td>{item.countryName}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <div>
                      {/* <p className='mt-4'>{inputLabels.formSubTitle1.en}</p> */}
                      <div className="row mb-3 align-items-center">
                        <div className="form-group col-lg-4">
                          {/* <label>{countryCuisine} </label> */}

                          <select
                            className="form-control input-default "
                            onChange={handleCountryChange}
                            value={selectedCountry}
                          >
                            <option>{inputLabels.cuisineCountry.en}</option>
                            {isSuccess &&
                              data.data?.map((item) => (
                                <option value={item._id}>
                                  {item.countryName}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group col-lg-4">
                          {/* <label>{cuisine} </label> */}

                          <select
                            className="form-control input-default "
                            onChange={handleCuisineChange}
                          >
                            <option draggable>
                              {inputLabels.selectCuisine.en}
                            </option>
                            {cusineIsSuccess &&
                              cuisine.data?.map((item) => (
                                <option value={item._id}>
                                  {item.cuisineName}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="col-4">
                          <Button onClick={handleMoreCuisine}>যোগ করুন</Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* cuisine section end */}

                  {/* amazing dish section start */}
                  {/* <div className="mt-3 row">
                    <label>
                      {" "}
                      <h5>
                        {inputLabels.formSubTitle2.en}{" "}
                        <span className="text-danger">*</span>
                      </h5>
                    </label>

                    <div className="row align-content-end">

                      <div className="col-lg-12">
                        <div>
                          <div>
                            {selectedImageAmazingDish && (
                              <div className="row justify-content-start">
                                {selectedImageAmazingDish.map((item, index) => {
                                  return (
                                    <div className="col mb-3">
                                      <img
                                        alt="not found"
                                        // width={"80px"}
                                        key={index}
                                        src={URL.createObjectURL(item)}
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          marginRight: "10px",
                                          display: "inline",
                                        }}
                                      />
                                      <AiFillDelete
                                        onClick={() =>
                                          handleImageDeleteAmazingDish(index)
                                        }
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>

                          <div className="mt-3">
                            <input
                              type="file"
                              id="amazingFood"

                              multiple
                              className="form-control input-default"
                              onChange={handleFileChangeAmazingDish}

                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* amazing dish section end */}

                  {/* experimental food section start */}
                  {/* <div className="mt-3 row">
                    <label>
                      {" "}
                      <h5>{inputLabels.formSubTitle3.en} </h5>
                    </label>
                    <div className="row align-content-end">

                      <div className="col-lg-12">
                        <div>
                          <div>
                            {selectedImageExperimental && (
                              <div className="row justify-content-start">
                                {selectedImageExperimental.map(
                                  (item, index) => {
                                    return (
                                      <div className="col mb-3">
                                        <img
                                          alt="not found"

                                          key={index}
                                          src={URL.createObjectURL(item)}
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            marginRight: "10px",
                                            display: "inline",
                                          }}
                                        />
                                        <AiFillDelete
                                          onClick={() =>
                                            handleImageDeleteExperimental(index)
                                          }
                                        />
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </div>

                          <div className="mt-3">
                            <input
                              type="file"
                              id="experimentalFood"

                              multiple
                              className="experimantal form-control input-default"
                              onChange={handleFileChangeExperimental}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* experimental food section end */}

                  <div className="mt-5 row">
                    <label>
                      {" "}
                      <h5>{formTitle3}</h5>
                      <p>
                        আরও যে ধরনের খাবার বানাতে পারেন তা সিলেক্ট করে ছবি দিন
                      </p>
                    </label>

                    {/* others food skill section start */}

                    <div className="">
                      <span>
                        {" "}
                        <input
                          type="checkbox"
                          value="NONE"
                          onChange={(e) => {
                            HandleFoodType(e);
                          }}
                          className="form-check-input form-checkbox-none"
                        />{" "}
                        এখন কিছু নেই,কিন্তু বিক্রি করার সময় থাকবে।
                      </span>
                      {foodType.map((item, index) => {
                        return (
                          <div className="row mb-1">
                            <div className="col-lg-3">
                              <span key={index}>
                                <span className="form-check custom-checkbox mb-3 ms-3 checkbox-info">
                                  <input
                                    onChange={(e) => {
                                      HandleFoodType(e, index);
                                    }}
                                    type="checkbox"
                                    value={item.foodTypeName}
                                    className="form-check-input"
                                    id={`customCheckBox${index}`} // Unique ID for each checkbox
                                    checked={foodSkills.some(
                                      (skill) =>
                                        skill.foodType === item.foodTypeName
                                    )}
                                  />

                                  <div class="sc-hbVHIN gXxfbz ">
                                    <div class="sc-kutfdj eIhAra sc-iKqDbg mcqhQ">
                                      <div class="sc-jwbTYE fvdGbf">
                                        <div class="sc-fQhefh hlvMNo"></div>
                                        <span>{item.foodTypeName}</span>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </span>
                            </div>

                            <div className="col-lg-3">
                              <input
                                type="file"
                                multiple
                                onChange={(e) => {
                                  HandleFoodTypeImage(
                                    e,
                                    index,
                                    item.foodTypeName
                                  );
                                }}
                                className="experimantal form-control input-default"
                              />
                            </div>
                            <div className="col-lg-3">
                              <input
                                type="text"
                                placeholder="description "
                                {...register("othersFoodDetails", {
                                  // required: true,
                                })}
                                className="form-control input-default"
                                id=""
                              />
                            </div>
                          </div>
                        );
                      })}
                      {/* <div className='row mb-1'>
                      <div className='col-lg-3'>
                        <span>
                          <span className='form-check custom-checkbox mb-3 ms-3 checkbox-info'>
                            <input
                              onChange={(e) => {
                                HandleFoodType(e, index);
                              }}
                              type='checkbox'
                              value={"Others"}
                              className='form-check-input'
                              id='customCheckBox2'
                            />

                            <div class='sc-hbVHIN gXxfbz '>
                              <div class='sc-kutfdj eIhAra sc-iKqDbg mcqhQ'>
                                <div class='sc-jwbTYE fvdGbf'>
                                  <div class='sc-fQhefh hlvMNo'></div>
                                  <span>অন্যান্য খাবার</span>
                                </div>
                              </div>
                            </div>
                          </span>
                        </span>
                      </div>
                      <div className='col-lg-3'>
                        {othersHide === false && (
                          <input
                            type='text'
                            placeholder='others '
                            className='form-control input-default'
                            id=''
                          />
                        )}
                      </div>
                    </div> */}
                    </div>
                    {console.log(othersHide)}
                    {/* others food skill section end */}

                    {/* my restriction section start */}
                    <div className="mt-3 row">
                      <label>
                        {" "}
                        <h5>
                          যে ধরনের খাবারের অর্ডার নেওয়া হবে না(ধর্মীয় বিধি
                          নিষেধ এর কারনে)
                        </h5>
                      </label>

                      {additionalTag.map((item, index) => (
                        <div className="col-lg-3 col-md-3 ">
                          {" "}
                          <span className="form-check custom-checkbox mb-3 ms-3 checkbox-info d-flex">
                            <input
                              onChange={(e) => {
                                handleCheck(e);
                              }}
                              type="checkbox"
                              value={item.tagName}
                              className="form-check-input"
                              id={`customCheckBoxRestriction${index}`}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="customCheckBox2"
                            >
                              {item.tagName}
                            </label>
                          </span>
                        </div>
                      ))}
                    </div>

                    {/* my restriction section end */}

                    {/* others section start : like instant pre order etc */}

                    <div className="mt-3">
                      <h5>আজকের খাবার</h5>
                      <div className="d-flex gap-3">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            defaultChecked
                            role="switch"
                            id="flexSwitchCheckDefault"
                            {...register("instant", { required: false })}
                          />
                          <label
                            class="form-check-label"
                            for="flexSwitchCheckDefault"
                          >
                            প্রতিদিনের সংসারের জন্য তৈরি করা যেকোনো খাবার বিক্রি
                            করতে চাই। তার মানে প্রতিদিনে নির্ধারিত কোনো মেনু
                            নেই। কেবল সংসারের রান্নাই বিক্রি হবে, তাই
                            ঝুঁকিমুক্ত।
                          </label>
                        </div>
                      </div>

                      <h5> উন্মুক্ত অর্ডার নিতে চাই</h5>
                      <div className="d-flex gap-3">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            defaultChecked
                            role="switch"
                            id="flexSwitchCheckDefault"
                            {...register("wishOrder", { required: false })}
                          />
                          <label
                            class="form-check-label altClass"
                            for="flexSwitchCheckDefault"
                          >
                            আমার খাবারের তালিকায় না থাকলেও ক্রেতার ইচ্ছানুযায়ী
                            যেকোনো খাবার বানিয়ে দিতে পারবো। ক্রেতা যদি আমাকে
                            ভালো ভাবে বর্ণ্না করে কি খাবার চায় তাহলে তালি্কায় না
                            থাকলেও আমি অর্ডার নিতে পারবো।
                          </label>
                        </div>
                      </div>

                      <h5>নিজের মেনু অনুযায়ী অগ্রিম অর্ডার নিতে চাই</h5>
                      <div className="d-flex gap-3">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            defaultChecked
                            id="flexSwitchCheckDefault"
                            {...register("preOrder", { required: false })}
                          />
                          <label
                            class="form-check-label"
                            for="flexSwitchCheckDefault"
                          >
                            আমি নিজেই নিজের মেনু বা খাবারের তালিকা তৈরি করবো। সে
                            অনুযায়ী আমার পছন্দ করা নির্দিষ্ট সময়ের আগে(২-১২ঘন্টা
                            যেভাবে আমি সিলেক্ট করবো) ক্রেতাকে খাবারের অর্ডার
                            দিতে হবে।অর্ডার পাওয়ার পরে আমি ওই খাবার তৈরি
                            করবো।এতে খাবার থাকছে ফ্রেশ আর আমি ব্যবসায়ীক দিক থেকে
                            ঝুঁকিমুক্ত।
                          </label>
                        </div>
                      </div>

                      <h5>ক্যাটারিং সার্ভিসের অর্ডার নিতে</h5>
                      <div className="d-flex gap-3">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            defaultChecked
                            id="flexSwitchCheckDefault"
                            {...register("catering", { required: false })}
                          />
                          <label
                            class="form-check-label altClass"
                            for="flexSwitchCheckDefault"
                          >
                            আমি যেকোনো ধরনের ক্যাটারিং অর্ডার (কমপক্ষে ১০ জনের)
                            নিতে চাই। আমি যেকোনো বিয়ে, জন্মদিন বা কোম্পানি
                            ইভেন্টসহ যেকোনো অনুষ্ঠানের জন্য খাবার দিতে পারবো। এর
                            জন্য নির্দিষ্ট সময়ের আগে আমাকে অর্ডার দিতে হবে।
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* others section end */}
                  <div className="d-flex justify-content-between">
                    <Button style={{ marginLeft: 10 }} type="submit">
                      পরবর্তী ধাপে এগিয়ে যান
                    </Button>
                    {/* <Button style={{ marginLeft: 25, color: "white" }}>
                <Link
                  to={`/BankInfo?id=${sellerid}`}
                  style={{ color: "white" }}
                >
                  Next
                </Link>
              </Button> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    </>
  );
};
export default BecomeSeller;
