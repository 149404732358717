import React from 'react';
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import bgimage from "../../images/login-img/login-image.png";
import { useState } from "react";
import { IoIosEyeOff, IoMdEye } from "react-icons/io";
import axios from "axios";
import { BaseURL } from "../../Helper/config";
import { useDispatch } from "react-redux";
import {
  setToken,
  setUser,
} from "../../Redux/feature/Authentication/AuthenticationSlice";
import { toast } from "react-toastify";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const onLogin = async (e) => {
    e.preventDefault();
    const data1 = await axios.post(`${BaseURL}/login-users`, {
      email: email,
      userPassword: password,
    });
    console.log(data1.data);

    const { data, Token } = data1.data;

    dispatch(setUser(data));
    dispatch(setToken(Token));

    // Store user and token in local storage
    localStorage.setItem("UserDetails", JSON.stringify(data));
    localStorage.setItem("Token", Token);
    if (data1.data.status === "Success") {
      console.log(1);
      navigate("/");
      toast.success("Successfully Login");
    } else {
      toast.warning("UnAuthorized");
    }
  };

  return (
    <div className='container mt-0'>
      <div className='row  align-items-center justify-contain-center bg-login'>
        <div className='col-xl-12 mt-5'>
          <div className='card border-0'>
            <div className='card-body login-bx'>
              <div className='row mt-5'>
                <div className='col-xl-8 col-md-6  text-center'>
                  <img
                    src={bgimage}
                    className='food-img'
                    alt='Log into your account'
                  />
                </div>
                <div className='col-xl-4 col-md-6 pe-0'>
                  <div className='sign-in-your'>
                    <div className='text-center mb-3'>
                      <img src={logo} className='mb-3' alt='' />
                      <h4 className='fs-20 font-w800 text-black'>Welcome!</h4>
                      <span className='font-w800'>Log Into Your Account</span>
                    </div>
                    {/* {props.errorMessage && (
                      <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                        {props.errorMessage}
                      </div>
                    )}
                    {props.successMessage && (
                      <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                        {props.successMessage}
                      </div>
                    )} */}
                    <form onSubmit={onLogin}>
                      <div className='mb-3'>
                        <label className='mb-1'>
                          <strong>Email Address/Phone Number</strong>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {/* {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )} */}
                      </div>
                      <div className='mb-3 password'>
                        <label className='mb-1'>
                          <strong>Password</strong>
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          className='form-control '
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {/* {errors.password && (
                          <div className="text-danger fs-12">
                            {errors.password}
                          </div>
                        )} */}
                        <div className='eye-container'>
                          <span
                            className='eye'
                            onClick={() => handleTogglePassword("password")}
                          >
                            {showPassword ? (
                              <>
                                <IoMdEye size={20} color='#0d6efd' />
                              </>
                            ) : (
                              <>
                                <IoIosEyeOff size={20} color='#0d6efd' />
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className='row d-flex justify-content-between mt-4 mb-2'>
                        <div className='mb-3'>
                          <div className='form-check custom-checkbox ms-1 d-flex justify-content-between'>
                            <div>
                              <input
                                type='checkbox'
                                className='form-check-input'
                                id='basic_checkbox_1'
                              />
                              <label
                                className='form-check-label'
                                htmlFor='basic_checkbox_1'
                              >
                                Remember my preference
                              </label>
                            </div>

                            <div className='forget-password-container'>
                              <Link to={"/forget-password"}>
                                Forget Password
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='text-center'>
                        <button
                          type='submit'
                          className='btn btn-primary btn-block'
                        >
                          Sign Me In
                        </button>
                      </div>
                    </form>

                    <div className='text-center mt-5'>
                      <span>
                        Don't have an account{" "}
                        <a
                          href='https://page.ownfood.com.bd/CustomerRegistration'
                          className='text-primary'
                        >
                          {" "}
                          Sign up
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login