import listName from "../../../translationData/menu.json";

export const MenuList = [
  {
    title: "Food",
    entitle: "Food",
    type: "seller",
    classsChange: "mm-collapse",
    iconStyle: "fas fa-utensils",
    content: [
      {
        title: "Post Food",
        entitle: "Post Food",
        to: "create-food",
      },
      {
        title: "My Food",
        entitle: "My Food",
        to: "my-food",
      },
    ],
  },
  {
    title: "খাবারের ক্যাটেগরি",
    entitle: "My Categories",
    type: "seller",
    classsChange: "mm-collapse",
    iconStyle: "fa-solid fa-list",
    content: [
      {
        title: "Create SubCategory",
        entitle: "Create SubCategory",
        to: "create-subCategory",
      },
      {
        title: "My SubCategories",
        entitle: "My SubCategories",
        to: "sub-category-list",
      },
    ],
  },
  {
    title: "ক্যাটেগরি",
    entitle: "Category",
    type: "seller1",
    classsChange: "mm-collapse",
    iconStyle: "fa-solid fa-list",
    content: [
      {
        title: `${listName.recipe.subMenu.createRecipeCat.bn}`,
        entitle: `${listName.recipe.subMenu.createRecipeCat.en}`,
        to: "create-recipe-category",
      },
      {
        title: `${listName.recipe.subMenu.myRecipeCat.bn}`,
        entitle: `${listName.recipe.subMenu.myRecipeCat.en}`,
        to: "my-recipe-category",
      },
      {
        title: "Create Category",
        to: "create-category",
      },
      {
        title: "My Category",
        to: "category-list",
      },
      {
        title: "Create SubCategory",
        to: "create-subCategory",
      },
      {
        title: "My SubCategory",
        to: "sub-category-list",
      },
    ],
  },
  {
    title: `${listName.howDoWeDo.bn}`,
    entitle: `${listName.howDoWeDo.en}`,
    type: "common",
    classsChange: "mm-collapse",
    iconStyle: "fa-regular fa-file",

    content: [
      {
        title: `${listName.howDoWeDo.subMenu.post.bn}`,
        entitle: `${listName.howDoWeDo.subMenu.post.en}`,
        to: "create-how-do-we-do",
      },
      {
        title: `${listName.howDoWeDo.subMenu.myHow.bn}`,
        entitle: `${listName.howDoWeDo.subMenu.myHow.en}`,
        to: "my-how-do-we-do",
      },
    ],
  },
  {
    title: `${listName.recipe.bn}`,
    entitle: `${listName.recipe.en}`,
    classsChange: "mm-collapse",
    iconStyle: "fa-solid fa-bell-concierge",
    type: "common",
    content: [
      {
        title: `${listName.recipe.subMenu.post.bn}`,
        entitle: `${listName.recipe.subMenu.post.en}`,
        to: "create-recipe",
      },
      {
        title: `${listName.recipe.subMenu.myRecipe.bn}`,
        entitle: `${listName.recipe.subMenu.myRecipe.en}`,
        to: "my-create-recipe",
      },
    ],
  },
  {
    title: `${listName.dairy.bn}`,
    entitle: `${listName.dairy.en}`,
    classsChange: "mm-collapse",
    iconStyle: "fa-solid fa-circle-info",
    type: "common",
    content: [
      {
        title: `${listName.dairy.subMenu.post.bn}`,
        entitle: `${listName.dairy.subMenu.post.en}`,
        to: "create-diary",
      },
      {
        title: `${listName.dairy.subMenu.myDiary.bn}`,
        entitle: `${listName.dairy.subMenu.myDiary.en}`,
        to: "my-diary",
      },
    ],
  },
  {
    type: "buyer",
    title: `${listName.order.bn}`,
    entitle: `${listName.order.en}`,
    to: "order",
    iconStyle: "fas fa-utensils",
  },
  {
    type: "rider",
    title: `New Order`,
    entitle: `New Order`,
    to: "assign-order",
    iconStyle: "fas fa-utensils",
  },
  {
    type: "rider",
    title: `Transaction List`,
    entitle: `Transaction List`,
    to: "/rider-transaction",
    iconStyle: "fas fa-utensils",
  },
  {
    type: "seller",
    title: `Transaction List`,
    entitle: `Transaction List`,
    to: "/seller-transaction",
    iconStyle: "fas fa-utensils",
  },
  {
    type: "rider",
    title: `Add Withdraw`,
    entitle: `Add Withdraw`,
    to: "/create-rider-withdraw",
    iconStyle: "fas fa-utensils",
  },
  {
    type: "seller",
    title: `Add Withdraw`,
    entitle: `Add Withdraw`,
    to: "/create-seller-withdraw",
    iconStyle: "fas fa-utensils",
  },
  {
    type: "seller",
    title: `Wish Order`,
    entitle: `Wish Order`,
    to: "/wish-order-seller",
    iconStyle: "fas fa-utensils",
  },
  {
    type: "buyer",
    title: `Wish Order`,
    entitle: `Wish Order`,
    to: "/wish-order-buyer",
    iconStyle: "fas fa-utensils",
  },
  {
    type: "rider",
    title: `${listName.order.bn}`,
    entitle: `${listName.order.en}`,
    to: "rider-order",
    iconStyle: "fas fa-utensils",
  },
  {
    type: "seller",
    title: `${listName.order.bn}`,
    entitle: `${listName.order.en}`,
    to: "seller-order",
    iconStyle: "fas fa-utensils",
  },
  {
    title: `${listName.application.bn}`,
    entitle: `${listName.application.en}`,
    classsChange: "mm-collapse",
    iconStyle: "fa-regular fa-file",
    type: "buyer",
    content: [
      {
        title: `${listName.application.subMenu.seller.bn}`,
        entitle: `${listName.application.subMenu.seller.en}`,
        to: "become-seller",
      },
      {
        title: `${listName.application.subMenu.rider.bn}`,
        entitle: `${listName.application.subMenu.rider.en}`,
        to: "become-rider",
      },
    ],
  },
  {
    title: `${listName.settings.bn}`,
    entitle: `${listName.settings.en}`,
    classsChange: "mm-collapse",
    iconStyle: "fa-solid fa-gear",
    type: "common",
    content: [
      {
        title: `${listName.settings.subMenu?.basicInfo.bn}`,
        entitle: `${listName.settings.subMenu?.basicInfo.en}`,
        to: "edit-profile",
      },
      // {
      //   title: `${listName.application.subMenu.rider.bn}`,
      //   to: "become-rider",
      // },
    ],
  },
];
