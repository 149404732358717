import React, { useState, useEffect, useContext } from "react";

import { LanguageContext } from "../../../context/LanguageContext";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import { toast } from "react-toastify";
import { Tab, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useGetBankListQuery } from "../../../Redux/API/ApiSlice";
import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/editProfile.json";
const mobileBankingTab = labels.userDashboard.editProfile.mobileBankingTab;

const MobileBanking = () => {
  const { handleSubmit } = useForm();

  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  const successMessage = getTranslation(
    "successMessage",
    currentLanguage,
    mobileBankingTab
  );
  const errorMessage = getTranslation(
    "errorMessage",
    currentLanguage,
    mobileBankingTab
  );

  const { data: providers, isSuccess } = useGetBankListQuery("mobileBanking");
  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  const [mobileBankingCollection, setMobileBanking] = useState([]);
  const handleProviderNameChange = (event, index) => {
    const value = event.target.value;
    setMobileBanking((prev) => {
      prev[index].operatorName = value;
      return [...prev];
    });
  };
  const handleMobileNumberChange = (event, index) => {
    const value = event.target.value;
    setMobileBanking((prev) => {
      prev[index].mobileNumber = value;
      return [...prev];
    });
  };
  const addMobileBanking = () => {
    setMobileBanking((prev) => {
      return [
        ...(prev || []),
        {
          operatorName: "",
          mobileNumber: "",
        },
      ];
    });
  };
  const removeMobileBanking = (i) => {
    setMobileBanking(
      mobileBankingCollection.filter((item, index) => index != i)
    );
  };

  const handleBecomeSeller = async (value) => {
    let fdata = {
      user: {},
      seller: {
        mobileBanking: [...mobileBankingCollection],
      },
    };

    axios
      .post(`${BaseURL}/update-user-details/${Seller._id}`, fdata)
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success(successMessage, {
            position: "bottom-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(errorMessage);
        }
      });
  };

  useEffect(() => {
    axios
      .get(`${BaseURL}/get-user-details/${Seller._id}`)
      .then((res) => {
        const data = res.data.data[0].sellerData;
        if (data && data.mobileBanking.length > 0) {
          setMobileBanking([...data.mobileBanking]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Tab.Pane eventKey="11">
      <form onSubmit={handleSubmit(handleBecomeSeller)}>
        <div className="setting-right">
          <div className="card">
            <div className="card-body">
              <div className="card-header border-0 pb-0">
                <h3>
                  {getTranslation(
                    "tabDetailsTitle",
                    currentLanguage,
                    mobileBankingTab
                  )}
                </h3>
              </div>
              <div className="card-body">
                {mobileBankingCollection &&
                  mobileBankingCollection.length > 0 && (
                    <div className="row">
                      <div className="col-5">
                        <label>
                          {getTranslation(
                            "selectProvider",
                            currentLanguage,
                            mobileBankingTab
                          )}
                        </label>
                      </div>
                      <div className="col-5">
                        <label>
                          {getTranslation(
                            "selectMobileNo",
                            currentLanguage,
                            mobileBankingTab
                          )}
                        </label>
                      </div>
                    </div>
                  )}
                {mobileBankingCollection &&
                  mobileBankingCollection.map((mobileBanking, index) => {
                    return (
                      <div key={index} className="row align-items-center mb-3">
                        <div className="form-group col-5">
                          <select
                            className="form-control"
                            onChange={(e) => handleProviderNameChange(e, index)}
                          >
                            <option value="">
                              {getTranslation(
                                "selectProviderPlaceholder",
                                currentLanguage,
                                mobileBankingTab
                              )}
                            </option>
                            {providers &&
                              providers.data.map((item, index) => {
                                return (
                                  <option
                                    selected={
                                      mobileBanking.operatorName ===
                                      item.bankName
                                    }
                                    className={item.bankName}
                                  >
                                    {item.bankName}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="form-group col-5">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={getTranslation(
                              "selectMobileNo",
                              currentLanguage,
                              mobileBankingTab
                            )}
                            value={mobileBanking.mobileNumber || ""}
                            onChange={(e) => handleMobileNumberChange(e, index)}
                          />
                        </div>
                        <div className="col-2">
                          <Button
                            className="w-100"
                            variant="danger"
                            onClick={(e) => {
                              removeMobileBanking(index);
                            }}
                          >
                            <i className="fas fa-trash-alt"></i>
                          </Button>
                        </div>
                      </div>
                    );
                  })}

                <div className="row">
                  <div className="col-12">
                    <Button
                      className="w-100"
                      variant="outline-primary"
                      onClick={addMobileBanking}
                    >
                      <i className="fa fa-plus"></i>{" "}
                      {getTranslation(
                        "addMoreButton",
                        currentLanguage,
                        mobileBankingTab
                      )}
                    </Button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-3">
                    <Button type="submit" className="w-100">
                      {getTranslation(
                        "saveButton",
                        currentLanguage,
                        mobileBankingTab
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Tab.Pane>
  );
};
export default MobileBanking;
