import React, { useState, useEffect, useContext } from "react";

import { LanguageContext } from "../../../context/LanguageContext";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import { toast } from "react-toastify";
import { Tab, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/editProfile.json";
const takeOrderEnableTab = labels.userDashboard.editProfile.takeOrderEnableTab;
const TakeOrderEnable = () => {
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);

  const successMessage = getTranslation(
    "successMessage",
    currentLanguage,
    takeOrderEnableTab
  );
  const errorMessage = getTranslation(
    "errorMessage",
    currentLanguage,
    takeOrderEnableTab
  );

  const [mainData, setMainData] = useState({});
  const { register, handleSubmit, reset, getValues } = useForm();

  const Seller = JSON.parse(localStorage.getItem("UserDetails"));

  const handleBecomeSeller = async (value) => {
    let fdata = {
      user: {},
      seller: { ...getValues() },
    };

    axios
      .post(`${BaseURL}/update-user-details/${Seller._id}`, fdata)
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success(successMessage, {
            position: "bottom-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(errorMessage);
        }
      });
  };

  useEffect(() => {
    axios
      .get(`${BaseURL}/get-user-details/${Seller._id}`)
      .then((res) => {
        var data = res.data.data[0].sellerData;
        setMainData(data);

        reset({
          ...data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reset]);

  return (
    <Tab.Pane eventKey="9">
      <form onSubmit={handleSubmit(handleBecomeSeller)}>
        <div className="setting-right">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h3>
                {getTranslation(
                  "tabDetailsTitle",
                  currentLanguage,
                  takeOrderEnableTab
                )}
              </h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-1">
                      <div className="d-flex gap-3">
                        <div class="form-check form-switch float-right">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault1"
                            value={mainData && !mainData.isSellInstantFood}
                            {...register("isSellInstantFood", {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-11 form-check">
                      <h5 for="flexSwitchCheckDefault1">
                        {getTranslation(
                          "instantOrder",
                          currentLanguage,
                          takeOrderEnableTab
                        )}
                      </h5>
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckDefault1"
                      >
                        {getTranslation(
                          "instantOrderDescription",
                          currentLanguage,
                          takeOrderEnableTab
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-1">
                      <div className="d-flex gap-3">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault2"
                            value={mainData && !mainData.isTakeWishOrder}
                            {...register("isTakeWishOrder", {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-11 form-check">
                      <h5>
                        {" "}
                        {getTranslation(
                          "wishOrder",
                          currentLanguage,
                          takeOrderEnableTab
                        )}
                      </h5>
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckDefault2"
                      >
                        {getTranslation(
                          "wishOrderDescription",
                          currentLanguage,
                          takeOrderEnableTab
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-1">
                      <div className="d-flex gap-3">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault3"
                            value={mainData && !mainData.isTakePreOrder}
                            {...register("isTakePreOrder", { required: false })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-11 form-check">
                      <h5>
                        {" "}
                        {getTranslation(
                          "preOrder",
                          currentLanguage,
                          takeOrderEnableTab
                        )}
                      </h5>
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckDefault3"
                      >
                        {getTranslation(
                          "preOrderDescription",
                          currentLanguage,
                          takeOrderEnableTab
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-1">
                      <div className="d-flex gap-3">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault4"
                            value={mainData && !mainData.isTakeCateringOrder}
                            {...register("isTakeCateringOrder", {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-11 form-check">
                      <h5>
                        {" "}
                        {getTranslation(
                          "cateringOrder",
                          currentLanguage,
                          takeOrderEnableTab
                        )}
                      </h5>
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckDefault4"
                      >
                        {getTranslation(
                          "cateringOrderDescription",
                          currentLanguage,
                          takeOrderEnableTab
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-3">
                  <Button type="submit" className="w-100">
                    {getTranslation(
                      "saveButton",
                      currentLanguage,
                      takeOrderEnableTab
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Tab.Pane>
  );
};
export default TakeOrderEnable;
