import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { BaseURL } from "../../../Helper/config";

function dateFormat(value){
	let objectDate = new Date(value);
	let day = ("0" + (objectDate.getDate() + 1)).slice(-2);
	let month = ("0" + (objectDate.getMonth() + 1)).slice(-2);
	let year = objectDate.getFullYear();

	return  day + "/" + month + "/" + year;
}

const RecipeCategoryList = () => {
  const [category, setCategory] = useState();

  const chackboxFun = (type) => {
    setTimeout(() => {
      const checkbox = document.querySelectorAll(
        ".application_sorting_1 input"
      );
      const motherCheckBox = document.querySelector(".sorting_asc input");
      for (let i = 0; i < checkbox.length; i++) {
        const element = checkbox[i];
        if (type === "all") {
          if (motherCheckBox.checked) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        } else {
          if (!element.checked) {
            motherCheckBox.checked = false;
            break;
          } else {
            motherCheckBox.checked = true;
          }
        }
      }
    }, 200);
  };

  useEffect(() => {
    //chackboxFun()

    axios
      .get(`${BaseURL}/get-recipe-category`)
      // .get(`${BaseURL}/get-recipe-category-by-creator/${getUserDetails()?._id}`)
      .then((res) => {
        setCategory(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDelete = (data) => {
    axios
      .delete(`${BaseURL}/delete-recipe-category/${data}`)
      .then((res) => {
        const newData = category.filter(item => item._id !== data)
        setCategory(newData);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  return (
    <>
      <div className='card h-auto'>
        <div className='card-body p-0'>
          <div className='table-responsive'>
            {category === undefined ? (
              ""
            ) : (
              <table
                className='table'
                id='guestTable-all3'
              >
                <thead>
                  <tr>
                    <th className='bg-none sorting_asc'>
                      <div className='form-check style-3'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='checkAll'
                          onClick={() => chackboxFun("all")}
                        />
                      </div>
                    </th>
                    <th>
                      <strong>IMAGE</strong>
                    </th>
                    <th>
                      <strong>RECIPE CATEGORY NAME</strong>
                    </th>
                    <th>STATUS</th>
                    <th>DATE</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {category.map((item, index) => {
                    console.log(item);
                    return (
                      <tr key={index}>
                        <td className='application_sorting_1'>
                          <div className='form-check style-3'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              onClick={() => chackboxFun()}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            {/* {item?.map((imgs, index) => {
                              console.log(imgs);
                            })} */}
                            <img
                              className='me-3 rounded-circle'
                              crossorigin='anonymous'
                              src={item?.categoryImage}
                              alt='images'
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <h5 className='mb-0'>{item.categoryName}</h5>
                          </div>
                        </td>
                        <td>
                          {item.status === false ? (
                            <div className="d-flex align-items-center">
                              <i className="fa fa-circle text-danger me-1"></i>{" "}
                              Inactive
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <i className="fa fa-circle text-success me-1"></i>{" "}
                              Active
                            </div>
                          )}
                        </td>
                        <td>{dateFormat(item.createdDate)}</td>
                        <td>
                          <div className="d-flex">
                            <Link to={`/edit-recipe-category/${item._id}`} state={item} className="btn btn-primary shadow btn-xs sharp me-1">
                              <i className="fas fa-pencil-alt"></i>
                            </Link>

                            {item.status === false ? (
                              ""
                            ) : (
                              <div>
                                <Link
                                  to={"#"}
                                  onClick={() => {
                                    del(item._id);
                                  }}
                                  className="btn btn-danger shadow btn-xs sharp"
                                >
                                  <i className="fa fa-trash"></i>
                                </Link>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecipeCategoryList;
