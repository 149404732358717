import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isLoggedIn: false,
  Token: null,
  seller: [],
  riderInfo:[]
};
const AuthenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setUser: (state, action) => {
      console.log(action);
      state.user = action.payload;
      state.isLoggedIn = true;
      localStorage.setItem("UserDetails", JSON.stringify(action.payload)); // Store user in local storage
    },
    setToken: (state, action) => {
      state.Token = action.payload;
      localStorage.setItem("Token", action.payload); // Store token in local storage
    },
    logoutUser: (state) => {
      state.user = null;
      state.isLoggedIn = false;
      state.Token = null;
      localStorage.removeItem("UserDetails"); // Remove user from local storage
      localStorage.removeItem("Token"); // Remove token from local storage
    },
    getSeller: (state, action) => {
      state.seller = action.payload;
    },
    getRiderInfo:(state,action)=>{
      state.riderInfo = action.payload

    }
  },
});

export const { setUser, setToken, logoutUser, getSeller,getRiderInfo } =
  AuthenticationSlice.actions;
export default AuthenticationSlice.reducer