import axios from "axios";
import { BaseURL } from "../Helper/config";
import { toast } from "react-toastify";
import store from "../Redux/store";
import { setLoading, setPercentage } from "../Redux/feature/ImageLoader/ImageLoaderSlice";
// import { ToastContainer, toast } from "react-toastify";

// const toastId = React.useRef(null);

const onUploadProgress = (event) => {
  const percentage = Math.round((100 * event.loaded) / event.total);
  console.log(percentage);
  store.dispatch(setPercentage(percentage))
  // store.dispatch(setLoading())
  // toast(`Uploading: ${percentage}%`, {
  //   autoClose: false,
  //   toastId: "upload-progress",
  // });

  //---------------------------------------
  // toast.update("upload-progress", {
  //   render: `Uploading: ${percentage}%`,
  // });
  // if (percentage === 100) {

  //   toast.dismiss("upload-progress");
  // }
//-------------------------------------------


  // const existingToast = toast.isActive('upload-progress');
  // if (existingToast) {
  //   toast.update(existingToast, {
  //     render: `Uploading: ${percentage}%`,
  //     autoClose: false,
  //   });
  // } else {
  //   // If the toast doesn't exist, create a new one
  //   toast(`Uploading: ${percentage}%`, {
  //     autoClose: false,
  //     toastId: 'upload-progress',
  //   });
  // }

  //=======================================================

  const existingToast = toast.isActive('upload-progress');
  if (existingToast) {
    toast.update(existingToast, {
      render: `Uploading: ${percentage}%`,
      autoClose: false,
    });
  } else {
    // If the toast doesn't exist, create a new one
    toast(`Uploading: ${percentage}%`, {
      autoClose: false,
      toastId: 'upload-progress',
    });
  }

  if (percentage === 100) {
    // Close the progress toast when the upload is complete
    toast.dismiss('upload-progress');
  }
};

const ImageUploader = async (Seller, folderName, file, title) => {
  const formData = new FormData();
  let titleObject;
  try {
    store.dispatch(setLoading(true));
    if (title !== undefined || title != null) {
      titleObject = title;
      const titleString = JSON.stringify(titleObject);
      formData.append("folder", `${folderName}/` + Seller?.userFullName);
      formData.append("title", titleString);
      if (Array.isArray(file)) {
        file.map((i) => formData.append("cover", i));
      } else {
        formData.append("cover", file);
      }
      const response = await axios.post(`${BaseURL}/imguploads`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
        //   onUploadProgress: ({ loaded, total }) => {
        //     const percentage = Math.round((100 * loaded) / total);
        //     console.log(percentage);
        //   },
      });
      console.log(response.data, "sdfgsdg");
      // .then(function (response) {
      //     console.log(response.data);
      //   });
      return response.data;
    } else {
      // const titleString = JSON.stringify(titleObject);
      formData.append("folder", `${folderName}/` + Seller?.userFullName);
      // formData.append('title',titleString)
      if (Array.isArray(file)) {
        file.map((i) => formData.append("cover", i));
      } else {
        formData.append("cover", file);
      }
      const response = await axios.post(`${BaseURL}/imguploads`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
        //   onUploadProgress: ({ loaded, total }) => {
        //     const percentage = Math.round((100 * loaded) / total);
        //     console.log(percentage);
        //   },
      });
      store.dispatch(setLoading(false));

      toast.dismiss("upload-progress");
      console.log(response.data, "sdfgsdg");
      // const data = await response.json();
      return response.data;
    }
  } catch (e) {
    toast.error("Error uploading image", { toastId: "upload-error" });
    store.dispatch(setLoading(false));
  }
};

export { ImageUploader, onUploadProgress };
