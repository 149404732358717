import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { BaseURL } from "../../../Helper/config";
import { getUserDetails } from "../../../Helper/SessionHelper";
import PageTitle from "../../layouts/PageTitle";
const FoodDairyList = () => {
  const [category, setCategory] = useState();

  const chackboxFun = (type) => {
    setTimeout(() => {
      const checkbox = document.querySelectorAll(
        ".application_sorting_1 input"
      );
      const motherCheckBox = document.querySelector(".sorting_asc input");
      for (let i = 0; i < checkbox.length; i++) {
        const element = checkbox[i];
        if (type === "all") {
          if (motherCheckBox.checked) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        } else {
          if (!element.checked) {
            motherCheckBox.checked = false;
            break;
          } else {
            motherCheckBox.checked = true;
          }
        }
      }
    }, 200);
  };

  useEffect(() => {
    //chackboxFun()

    axios
      .get(`${BaseURL}/get-food-diary-by-user/${getUserDetails()._id}`)
      .then((res) => {
        console.log(res);
        setCategory(res.data.data[0].FoodDiaryData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDelete = (data) => {
    axios
      .delete(`${BaseURL}/delete-food-diary/${data}`)
      .then((res) => {
        console.log(res);
        setCategory(prevCategory => prevCategory.filter(item => item._id !== data));
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  console.log(category);

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };
  // const formatDate = (dateString) => {
  //   const options = { year: "numeric", month: "long", day: "numeric" };
  //   return new Date(dateString).toLocaleDateString(undefined, options);
  // };
  function formatDate(value) {
    let dateData = new Date(value);
    // Format the date as dd/mm/yyyy
    const formattedDate = dateData.toLocaleDateString('en-GB'); // 'en-GB' for the dd/mm/yyyy format
    return formattedDate;
  }
  return (
    <>
      <PageTitle activeMenu="Food Diary List" motherMenu="Food Diary" />
      <div className="card h-auto">
        <div className="card-body p-0">
          <div className="table-responsive">
            {category === undefined ? (
              ""
            ) : (
              <table
                className="table table-list i-table style-1 mb-4 border-0"
                id="guestTable-all3"
              >
                <thead>
                  <tr>
                    {/* <th className="bg-none sorting_asc">
                      <div className="form-check style-3">SL</div>
                    </th> */}
                    <th>ID</th>
                    <th style={{ position: "relative", left: "20px" }}>
                      Title
                    </th>
                    <th>Image</th>
                    <th style={{ position: "relative", left: "50px" }}>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {category.map((item, index) => {
                    return (
                      <tr key={index}>
                        
                        <td className="application_sorting_1">
                          <div className="form-check style-3">
                          {item?.blogID}
                          </div>
                        </td>
                        <td className="application_sorting_1">
                          <div className="form-check style-3">
                            {" "}
                            {item?.blogTitle}
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3 align-items-center">
                            {item.blogImage.map(
                              (image, imageIndex) =>
                                image.orginalImageUrl && (
                                  <img
                                    key={imageIndex}
                                    className="me-3 rounded-circle"
                                    src={image.orginalImageUrl}
                                  />
                                )
                            )}
                          </div>
                        </td>
                        <td className="application_sorting_1">
                          <div className="form-check style-3">
                            <div className="d-flex align-items-center">
                              <h5 className="mb-0">
                                {formatDate(item.createdDate)}
                              </h5>
                            </div>
                          </div>
                        </td>

                      
                        <td>
                          <div className="d-flex gap-1">
                           
                            <Link
                              to={`/edit-food-diary/${item._id}`}
                              state={item}
                              className="btn btn-primary shadow btn-xs sharp me-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                            <Link
                              onClick={() => {
                                del(item._id);
                              }}
                              className="btn btn-danger shadow btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FoodDairyList;
