import React, { useState, useEffect, useContext } from "react";

import { LanguageContext } from "../../../context/LanguageContext";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import { toast } from "react-toastify";
import { Tab, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  useAllCountryQuery,
  useGetCityByRegionQuery,
  useGetRegionByCountryQuery,
  useGetSellerInfoQuery,
  useGetThanaByRegionQuery,
} from "../../../Redux/API/ApiSlice";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { GoogleMap, Marker } from "@react-google-maps/api";

import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/editProfile.json";
import { getUserDetails } from "../../../Helper/SessionHelper";
const kitchenTab = labels.userDashboard.editProfile.kitchenTab;

const Kitchen = () => {
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  const successMessage = getTranslation(
    "successMessage",
    currentLanguage,
    kitchenTab
  );
  const errorMessage = getTranslation(
    "errorMessage",
    currentLanguage,
    kitchenTab
  );

  const [mainData, setMainData] = useState({});
  const [selectedCountryAd, setSelectedCountryAd] = useState("");
  const [selectedRegionAd, setSelectedRegionAd] = useState("");
  const [userAddress, setUserAddress] = useState(null);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const { data, isSuccess } = useAllCountryQuery(undefined);
  const {
    data: RegionData,
    isLoading: RegionLoading,
    isSuccess: RegionSuccess,
  } = useGetRegionByCountryQuery(selectedCountryAd);

  // const {
  //   data: CityData,
  //   isLoading: CityLoading,
  //   isSuccess: CitySuccess,
  // } = useGetCityByRegionQuery(selectedRegionAd);

  const {
    data: CityData,
    isLoading: CityLoading,
    isSuccess: CitySuccess,
  } = useGetThanaByRegionQuery(selectedRegionAd);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm();

  const searchOptions = {
    componentRestrictions: { country: ["BD"] },
  };

  const Seller = JSON.parse(localStorage.getItem("UserDetails"));

  const handleCountryChangeAdr = (event) => {
    const newValue = event.target.value;

    setSelectedCountryAd(newValue);
  };

  const handleRegionChangeAdr = (event) => {
    const value = event.target.value;
    setSelectedRegionAd(value);
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setCoordinates({
      lat: ll.lat,
      lng: ll.lng,
    });
    setUserAddress(value);
  };

  const handleDrag = (event) => {
    setCoordinates({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const handleMapLoad = (event) => {
    if (
      mainData &&
      mainData.pointLocation &&
      mainData.pointLocation.coordinates &&
      mainData.pointLocation.coordinates[1] &&
      mainData.pointLocation.coordinates[0]
    ) {
      setCoordinates({
        lat: mainData.pointLocation.coordinates[1],
        lng: mainData.pointLocation.coordinates[0],
      });
    }
  };

  const values = getValues();
  const regionNumericId = Number(values.regionNumericId);
  const cityNumericId = Number(values.cityNumericId);
  const subData = {
    ...getValues(),
    regionNumericId: regionNumericId,
    cityNumericId: cityNumericId,
  };

  const handleBecomeSeller = async (value) => {
    let fdata = {
      user: {},
      seller: {
        ...subData,
        pointLocation: {
          userAddress: userAddress,
          coordinates: [coordinates.lng, coordinates.lat],
          type: "Point",

          // coordinates: {
          //   lng: coordinates.lng,
          //   lat: coordinates.lat,
          // },
        },
      },
    };
    console.log("fdata", fdata);
    axios
      .post(`${BaseURL}/update-user-details/${Seller._id}`, fdata)
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success(successMessage, {
            position: "bottom-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          if (res.data.data.sellerID) {
            toast.warning(errorMessage);
          } else toast.warning(errorMessage);
        }
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setMainData((prev) => {
      const data = {
        ...prev,
        [name]: value,
      };

      return data;
    });
  };

  useEffect(() => {
    axios
      .get(`${BaseURL}/get-user-details/${Seller._id}`)
      .then((res) => {
        var data = res.data.data[0].sellerData;
        if (data) {
          const [lng, lat] = data.pointLocation.coordinates;

          var initialData = {
            kitchenName: data.kitchenName,
            countryID: data.countryID,
            regionID: data.regionID,
            regionNumericId: data.regionNumericId,
            cityNumericId: data.cityNumericId,
            cityID: data.cityID,
            address: data.address,
            policeStation: data.policeStation,
            pointLocation: {
              userAddress: data.pointLocation.userAddress,
              coordinates: [lng, lat],
            },
            sellerNationality: data.sellerNationality,
            sellerNationalityByBornID: data.sellerNationalityByBornID,
          };

          data?.countryID && setSelectedCountryAd(data.countryID);
          // data?.regionID && setSelectedRegionAd(data.regionID);
          data?.regionNumericId && setSelectedRegionAd(data.regionNumericId);
          data?.pointLocation?.userAddress &&
            setUserAddress(data.pointLocation.userAddress);
          data?.pointLocation?.coordinates[0] &&
            data?.pointLocation?.coordinates[1] &&
            setCoordinates({
              lat: parseFloat(lat),
              lng: parseFloat(lng),
            });

          setMainData(initialData);
          console.log(initialData);

          reset({
            ...initialData,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reset]);
  const userData = getUserDetails();
  const { data: sellerData, isSuccess: sellerSuccess } = useGetSellerInfoQuery(
    userData._id
  );
  return (
    <Tab.Pane eventKey="2">
      <form onSubmit={handleSubmit(handleBecomeSeller)}>
        <div className="setting-right">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h4>
                {getTranslation("tabDetailsTitle", currentLanguage, kitchenTab)}
              </h4>
            </div>
            <div className="card-body">
              <div className="form-group mb-3">
                <label>
                  {getTranslation("kitchenName", currentLanguage, kitchenTab)}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control input-default "
                  placeholder={getTranslation(
                    "kitchenName",
                    currentLanguage,
                    kitchenTab
                  )}
                  name="kitchenName"
                  {...register("kitchenName", {
                    required: true,
                    onChange: handleInputChange,
                  })}
                  disabled={
                    sellerData?.data[0]?.seller[0]?.sellerApproval ===
                    "Approved"
                  }
                />
                {errors.kitchenName && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>
              <div className="row">
                <div className="col-12">
                  <h5>
                    {getTranslation(
                      "kitchenAddressHeading",
                      currentLanguage,
                      kitchenTab
                    )}{" "}
                  </h5>
                </div>
                <div className="col-4">
                  <label>
                    {getTranslation("country", currentLanguage, kitchenTab)}{" "}
                  </label>
                  <select
                    name="countryAddress"
                    className="form-control input-default"
                    {...register("countryID", {
                      required: true,
                      onChange: (e) => {
                        handleCountryChangeAdr(e);
                        handleInputChange(e);
                      },
                    })}
                  >
                    <option>
                      {getTranslation(
                        "selectCountry",
                        currentLanguage,
                        kitchenTab
                      )}
                    </option>
                    {isSuccess &&
                      data.data?.map((item) => {
                        if (mainData && mainData.countryID === item._id) {
                          return (
                            <option selected value={item._id}>
                              {item.countryName}
                            </option>
                          );
                        } else {
                          return (
                            <option value={item._id}>{item.countryName}</option>
                          );
                        }
                      })}
                  </select>
                </div>
                <div className="col-4">
                  <label>
                    {getTranslation("region", currentLanguage, kitchenTab)}{" "}
                  </label>
                  <select
                    className="form-control input-default"
                    {...register("regionNumericId", {
                      required: true,
                      onChange: (e) => {
                        handleRegionChangeAdr(e);
                        handleInputChange(e);
                      },
                    })}
                  >
                    <option>
                      {getTranslation(
                        "selectRegion",
                        currentLanguage,
                        kitchenTab
                      )}
                    </option>
                    {RegionSuccess &&
                      !RegionLoading &&
                      RegionData?.data?.map((item) => {
                        if (mainData && mainData.regionNumericId === item.id) {
                          return (
                            <option selected value={item.id}>
                              {item.regionName}
                            </option>
                          );
                        } else {
                          return (
                            <option value={item.id}>{item.regionName}</option>
                          );
                        }
                      })}
                  </select>
                </div>
                <div className="col-4">
                  <label>
                    {getTranslation("city", currentLanguage, kitchenTab)}{" "}
                  </label>
                  <select
                    className="form-control input-default"
                    {...register("cityNumericId", {
                      required: true,
                      onChange: (e) => {
                        handleInputChange(e);
                      },
                    })}
                  >
                    <option>
                      {getTranslation(
                        "selectCity",
                        currentLanguage,
                        kitchenTab
                      )}
                    </option>
                    {CitySuccess &&
                      CityData.data?.map((item) => {
                        if (mainData && mainData.cityNumericId === item.id) {
                          return (
                            <option selected value={item.id}>
                              {item.cityName}
                            </option>
                          );
                        } else {
                          return (
                            <option value={item.id}>{item.cityName}</option>
                          );
                        }
                      })}
                  </select>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <label>
                    {getTranslation("address", currentLanguage, kitchenTab)}
                  </label>
                  <textarea
                    class="form-control"
                    {...register("address", {
                      required: true,
                    })}
                    rows="5"
                  ></textarea>
                </div>

                <div className="col-6">
                  <label>
                    {getTranslation(
                      "policeStation",
                      currentLanguage,
                      kitchenTab
                    )}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    {...register("policeStation", {
                      required: true,
                    })}
                    placeholder={getTranslation(
                      "policeStation",
                      currentLanguage,
                      kitchenTab
                    )}
                  />
                </div>
              </div>

              {/* Map &  Address Section Start */}
              <div className="mt-3">
                <div className="form-group mb-3 row ">
                  <div className="col-12">
                    <label>
                      {getTranslation(
                        "pointLocation",
                        currentLanguage,
                        kitchenTab
                      )}{" "}
                    </label>
                    <PlacesAutocomplete
                      searchOptions={searchOptions}
                      value={userAddress || ""}
                      onChange={setUserAddress}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div style={{ position: "relative" }}>
                          <input
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className:
                                "form-control input-default location-search-input",
                            })}
                          />
                          <div
                            className="autocomplete-dropdown-container"
                            style={{
                              position: "absolute",
                              zIndex: 2,
                              width: "100%",
                              border: "1px solid #e7e7e7",
                            }}
                          >
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#1E466A",
                                    color: "white",
                                    cursor: "pointer",
                                    padding: "10px",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                    padding: "10px",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
                {console.log("map coordinates", coordinates)}
                <GoogleMap
                  mapContainerStyle={{ height: "200px", width: "100%" }}
                  //onDragEnd={handleDrag}
                  center={coordinates} // Centered at the coordinates of Dhaka, Bangladesh
                  zoom={20}
                  onLoad={handleMapLoad}
                >
                  <Marker
                    position={coordinates}
                    draggable
                    onDragEnd={handleDrag}
                  />
                </GoogleMap>
              </div>

              <div className="row mt-4">
                <div className="form-group mb-3 col-6">
                  <label>
                    {getTranslation("nationality", currentLanguage, kitchenTab)}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    {...register("sellerNationality", {
                      required: true,
                      onChange: handleInputChange,
                    })}
                    // name='myCountry'
                    className="form-control input-default  "
                  >
                    <option>select country</option>
                    {isSuccess &&
                      data.data?.map((item) => {
                        if (
                          mainData &&
                          mainData.sellerNationality === item._id
                        ) {
                          return (
                            <option selected value={item._id}>
                              {item.countryName}
                            </option>
                          );
                        } else {
                          return (
                            <option value={item._id}>{item.countryName}</option>
                          );
                        }
                      })}
                  </select>
                  {errors.myCountry && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
                <div className="form-group mb-3 col-6">
                  <label>
                    {getTranslation(
                      "decentNationality",
                      currentLanguage,
                      kitchenTab
                    )}
                    <span className="text-danger">*</span>
                  </label>

                  <select
                    {...register("sellerNationalityByBornID", {
                      required: true,
                      onChange: handleInputChange,
                    })}
                    // name='decentCountry'
                    className="form-control input-default "
                  >
                    <option>select country</option>
                    {isSuccess &&
                      data.data?.map((item) => {
                        if (
                          mainData &&
                          mainData.sellerNationalityByBornID === item._id
                        ) {
                          return (
                            <option selected value={item._id}>
                              {item.countryName}
                            </option>
                          );
                        } else {
                          return (
                            <option value={item._id}>{item.countryName}</option>
                          );
                        }
                      })}
                  </select>
                  {errors.decentCountry && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
              </div>

              <div className="col-12 mt-3">
                <Button type="submit" className="w-100">
                  {getTranslation("saveButton", currentLanguage, kitchenTab)}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Tab.Pane>
  );
};
export default Kitchen;
