import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { BaseURL } from "../../../Helper/config";
import { getUserDetails } from "../../../Helper/SessionHelper";
import PageTitle from "../../layouts/PageTitle";

function dateFormat(value) {
  let objectDate = new Date(value);
  let day = ("0" + (objectDate.getDate() + 1)).slice(-2);
  let month = ("0" + (objectDate.getMonth() + 1)).slice(-2);
  let year = objectDate.getFullYear();

  return day + "/" + month + "/" + year;
}
const SubCategoryList = () => {
  const [subcategoryList, setSubCategoryList] = useState();

  useEffect(() => {
    axios
      .get(
        `${BaseURL}/get-subcategory-by-seller/${
          getUserDetails().sellerIDInfo[0]?._id
        }`
      )
      .then((res) => {
        setSubCategoryList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [subcategoryList]);

  const handleDelete = (data) => {
    axios
      .delete(`${BaseURL}/delete-subcategory/${data}`)
      .then((res) => {
        console.log(res);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  return (
    <>
      {" "}
      <PageTitle activeMenu="My Categories" motherMenu="Category" />
      <div className="card h-auto" style={{ marginTop: 10 }}>
        <div className="card-body p-0">
          <div className="table-responsive">
            {subcategoryList === undefined ? (
              ""
            ) : (
              <table
                className="table table-list i-table style-1 mb-4 border-0"
                id="guestTable-all3"
              >
                <thead>
                  <tr>
                    <th>SubCategory Title</th>
                    <th>Parent</th>
                    <th>Status</th>

                    <th>Action</th>
                    <th className="bg-none"></th>
                    <th className="bg-none"></th>
                  </tr>
                </thead>
                <tbody>
                  {subcategoryList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <h5 className="mb-0">{item.categoryName}</h5>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <p>{item.parentCategory[0].categoryName}</p>
                              {console.log(item)}
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          {item.status === false ? (
                            <div className="d-flex align-items-center">
                              <i className="fa fa-circle text-danger me-1"></i>{" "}
                              Inactive
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <i className="fa fa-circle text-success me-1"></i>{" "}
                              Active
                            </div>
                          )}
                        </td>
                        <td>{dateFormat(item.createdDate)}</td>
                        <td>
                          <div className="d-flex">
                            <Link
                              to={`/edit-my-subcategory/${item._id}`}
                              state={item}
                              className="btn btn-primary shadow btn-xs sharp me-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>

                            {item.status === false ? (
                              ""
                            ) : (
                              <div>
                                <Link
                                  to={"#"}
                                  onClick={() => {
                                    del(item._id);
                                  }}
                                  className="btn btn-danger shadow btn-xs sharp"
                                >
                                  <i className="fa fa-trash"></i>
                                </Link>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubCategoryList;
