import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { BaseURL } from "../../../Helper/config";
import { getUserDetails, setOTP } from "../../../Helper/SessionHelper";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import {
  ImageUploader,
  onUploadProgress,
} from "../../../ImageUploader/ImageUploader";

const EditFoodDairy = () => {
  const [diaryImageHandle, setDiaryImageHandle] = useState(null);
  const [item, setItem] = useState(null);

  const [selectedImage, setSelectedImage] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const params = useParams();
  const getFoodDiary = async () => {
    fetch(`${BaseURL}/get-single-food-diary/${params.id}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data[0]);
        console.log(data.data[0].blogImage);
        setItem(data.data[0]);
        if (data?.data[0]?.blogImage && data?.data[0]?.blogImage.length > 0) {
          var imagesCollection = [];

          data?.data[0]?.blogImage.forEach((images, index) => {
            if (images && images.orginalImageUrl) {
              var toBePushed = { ...images, isExisting: true };
              imagesCollection.push(toBePushed);
            }
          });

          setSelectedImage(imagesCollection);
        }
        // setSelectedImage(data?.data[0]?.blogImage);
      });
  };

  const handleImageDeleteFood = (index) => {
    const updatedImages = [...selectedImage];
    updatedImages.splice(index, 1);
    // console.log(selectedImageAmazingDish);
    setSelectedImage(updatedImages);
  };

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);
    var diaryImageCollection = [...selectedImage];
    fileList.map((item, index) => {
      diaryImageCollection.push({ images: [item], isExisting: false });
    });

    setSelectedImage(diaryImageCollection);
  };

  useEffect(() => {
    getFoodDiary();
  }, []);
 
  const [EditorData, setEditorData] = useState();
  const [data, setData] = useState({
    title: item?.blogTitle,
    img: item?.blogImage,
    imgfile: item?.blogImage[0].orginalImageUrl,
  });
  console.log("image", data.imgfile?.orginalImageUrl);
  const [disable, setdisable] = useState(true);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const newContact = { ...data };
    newContact[e.target.name] = e.target.value;

    newContact.categoryName !== "" && newContact.categorySlug !== ""
      ? setdisable(false)
      : setdisable(true);
    setData(newContact);
  };
  const handleImageChange = (event) => {
    const file = Array.from(event.target.files);
    setDiaryImageHandle(file);
  };
  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  const submit = async (e) => {
    const subdata = {
      userID: getUserDetails()._id,
      blogTitle: data.title,
      blogSlug: data.title,
      blogDesc: EditorData,
      blogImage: [],
    };

    var newSelectedImages = selectedImage
      .filter((m) => !m.isExisting)
      .map((item) => item.images[0]);

    if (newSelectedImages) {
      setIsFileUploading(true);
      const imageUploadedResult = await ImageUploader(
        Seller,
        "Diary",
        newSelectedImages
      );

      if (imageUploadedResult && imageUploadedResult.status === "Success") {
        subdata.blogImage = [
          ...selectedImage.filter(
            (m) => m.orginalImageUrl !== "" && m.isExisting
          ),
          ...imageUploadedResult.images,
        ];
        setIsFileUploading(false);
      }
    }

    if (!isFileUploading) {
      await axios
        .post(`${BaseURL}/update-food-diary/${item._id}`, subdata)
        .then((apiResponse) => {
          console.log(apiResponse.data);

          Swal.fire({
            icon: "success",
            title: "Food Diary edited successfully!!!",
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            navigate("/my-diary");
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          toast.error("❌ Error Notify !", {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  return (
    <>
      {item && (
        <div class="row">
          <div class="col-xl-12 col-xxl-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Edit Diary</h4>
              </div>
              <div class="card-body custom-ekeditor">
                <div className="form-group mb-3">
                  <label>Title </label>
                  <input
                    type="text"
                    className="form-control input-default "
                    placeholder="Enter Your Title"
                    label="Name:"
                    name="title"
                    //   defaultvalue={data.title}
                    defaultValue={item?.blogTitle}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <div>
                    <div>
                      <label htmlFor="formFile" className="form-label">
                        Upload Images
                      </label>
                    </div>

                    {!selectedImage &&
                      selectedImage?.map((image, imageIndex) => (
                        <img
                          width={"150px"}
                          height={"150px"}
                          key={imageIndex}
                          className="me-3 rounded mb-2"
                          src={image.orginalImageUrl}
                          alt={`Image ${imageIndex}`}
                        />
                      ))}

                    {selectedImage?.map((item, index) => {
                      return (
                        <>
                          <img
                            className="img-fluid"
                            alt="Diary Image"
                            width={"150px"}
                            height={"150px"}
                            src={
                              item.isExisting
                                ? item.orginalImageUrl
                                : URL.createObjectURL(item.images[0])
                            }
                          />
                          <span
                            style={{
                              position: "relative",
                              top: "-30px",
                              left: "-26px",
                            }}
                          >
                            <div
                              className="btn btn-danger shadow btn-xs sharp"
                              onClick={() => handleImageDeleteFood(index)}
                            >
                              <i className="fa fa-trash"></i>
                            </div>
                          </span>
                        </>
                      );
                    })}
                    <div
                      style={{
                        marginTop: 15,
                      }}
                    >
                      <input
                        name="readyFoodImage"
                        type="file"
                        id="input-file-upload"
                        multiple
                        onChange={handleFileChange}
                      />
                      <label id="label-file-upload" htmlFor="input-file-upload">
                        <div>
                          <p>Drag and drop your image here or</p>
                          <span className="upload-button">Upload a image</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                {/* <h2>Using CKEditor 5 build in React</h2> */}
                <label>Description </label>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"],
                  }}
                  data={item?.blogDesc}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.

                    console.log("Editor is ready to use!", editor);
                  }}
                  //   data={form.description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setEditorData(data);
                  }}
                />
                <Button
                  className="mt-2"
                  onClick={() => {
                    submit();
                  }}
                >
                  {" "}
                  Update{" "}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditFoodDairy;
