import React, { useState, useEffect, useContext } from "react";

import { LanguageContext } from "../../../context/LanguageContext";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Tab, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ImageUploader } from "../../../ImageUploader/ImageUploader";

import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/editProfile.json";
const kitchenPhotosVideosTab =
  labels.userDashboard.editProfile.kitchenPhotosVideosTab;

const KitchenPhotosVideos = () => {
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  const successMessage = getTranslation(
    "successMessage",
    currentLanguage,
    kitchenPhotosVideosTab
  );
  const errorMessage = getTranslation(
    "errorMessage",
    currentLanguage,
    kitchenPhotosVideosTab
  );

  const [mainData, setMainData] = useState({});
  const [kitchenImage, setKitchenImage] = useState(null);

  const { register, handleSubmit, reset, getValues } = useForm();

  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  const seller = localStorage.getItem("UserDetails");

  const handleKitchenPhoto = (event) => {
    const fileList = Array.from(event.target.files);
    var kitchenImageCollection = [...kitchenImage];
    fileList.map((item, index) => {
      kitchenImageCollection.push({ images: [item], isExisting: false });
    });

    setKitchenImage(kitchenImageCollection);
  };

  const removeKitchenPhoto = (selectedImage) => {
    const fileList = kitchenImage.filter(
      (item, index) => index !== selectedImage
    );
    setKitchenImage([...fileList]);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setMainData((prev) => {
      const data = {
        ...prev,
        [name]: value,
      };

      return data;
    });
  };

  const handleBecomeSeller = async () => {
    let fdata = {
      user: {},
      seller: {
        ...getValues(),
      },
    };

    var newKitchenImages = kitchenImage
      .filter((m) => !m.isExisting)
      .map((item) => item.images[0]);

    if (newKitchenImages) {
      const kitchenImageresult = await ImageUploader(
        seller,
        "Kitchen",
        newKitchenImages
      );

      fdata.seller.kitchenImages = [
        ...kitchenImage.filter((m) => m.orginalImageUrl !== "" && m.isExisting),
        ...kitchenImageresult.images,
      ];
    }

    axios
      .post(`${BaseURL}/update-user-details/${Seller._id}`, fdata)
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success(successMessage, {
            position: "bottom-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          if (res.data.data.sellerID) {
            toast.warning(errorMessage);
          } else toast.warning(errorMessage);
        }
      });
  };

  useEffect(() => {
    axios
      .get(`${BaseURL}/get-user-details/${Seller._id}`)
      .then((res) => {
        var data = res.data.data[0].sellerData;
        var initialData = {
          kitchenVideos: data.kitchenVideos,
        };

        setMainData(initialData);

        //push current kitchen images
        if (
          res.data.data &&
          res.data.data[0] &&
          res.data.data[0].sellerData &&
          res.data.data[0].sellerData.kitchenImages.length > 0
        ) {
          var imagesCollection = [];

          res.data.data[0].sellerData.kitchenImages.forEach((images, index) => {
            if (images && images.orginalImageUrl) {
              var toBePushed = { ...images, isExisting: true };
              imagesCollection.push(toBePushed);
            }
          });

          setKitchenImage(imagesCollection);
        }

        reset({
          ...initialData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reset]);

  return (
    <Tab.Pane eventKey="3">
      <form onSubmit={handleSubmit(handleBecomeSeller)}>
        <div className="setting-right">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h3>
                {getTranslation(
                  "tabDetailsTitle",
                  currentLanguage,
                  kitchenPhotosVideosTab
                )}
              </h3>
              {/* <p>{getTranslation('tabDetailsDesc', currentLanguage,kitchenPhotosVideosTab)}</p> */}
            </div>
            <div className="card-body">
              <div>
                {kitchenImage && (
                  <div className="row">
                    {kitchenImage.map((item, index) => {
                      return (
                        <div
                          className="col-3 mb-3"
                          style={{ position: "relative" }}
                        >
                          <Link
                            style={{ position: "absolute", right: 20, top: 5 }}
                            to={"#"}
                            onClick={() => {
                              removeKitchenPhoto(index);
                            }}
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>

                          <img
                            className="img-fluid"
                            style={{ maxHeight: "200px", maxWidth: "100%" }}
                            alt="not found"
                            src={
                              item.isExisting
                                ? item.orginalImageUrl
                                : URL.createObjectURL(item.images[0])
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
                <lebel>
                  {" "}
                  {getTranslation(
                    "kitchenPhoto",
                    currentLanguage,
                    kitchenPhotosVideosTab
                  )}{" "}
                  <span className="text-danger">*</span>
                </lebel>

                <input
                  hidden
                  name="readyFoodImage"
                  type="file"
                  multiple
                  id="input-file-upload-kiv"
                  onChange={handleKitchenPhoto}
                />
                <label
                  id="label-file-upload"
                  htmlFor="input-file-upload-kiv"
                  className="text-center py-5"
                >
                  <div>
                    <p>
                      {getTranslation(
                        "uploadBoxDragAndDropText1",
                        currentLanguage,
                        kitchenPhotosVideosTab
                      )}
                    </p>
                    <span className="upload-button">
                      {getTranslation(
                        "uploadBoxDragAndDropText2",
                        currentLanguage,
                        kitchenPhotosVideosTab
                      )}
                    </span>
                  </div>
                </label>
              </div>
              <div className="mt-4">
                <lebel>
                  {getTranslation(
                    "kitchenVideo",
                    currentLanguage,
                    kitchenPhotosVideosTab
                  )}
                </lebel>
                <input
                  type="text"
                  className="form-control input-default "
                  placeholder={getTranslation(
                    "kitchenVideoPlaceholder",
                    currentLanguage,
                    kitchenPhotosVideosTab
                  )}
                  label="Name:"
                  {...register("kitchenVideos", {
                    required: true,
                    onChange: handleInputChange,
                  })}
                />
              </div>

              <div className="col-12 mt-3">
                <Button type="submit" className="w-100">
                  {getTranslation(
                    "saveButton",
                    currentLanguage,
                    kitchenPhotosVideosTab
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Tab.Pane>
  );
};
export default KitchenPhotosVideos;
