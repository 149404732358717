import axios from "axios";
import { BaseURL } from "../Helper/config";
import { toast } from "react-hot-toast";
import store from "../Redux/store";
import { GetFoodBySeller } from "../Redux/feature/Seller/SellerSlice";

export const GetFoodBySellerApi =async (id,limit)=>{
    try{
        let URL = `${BaseURL}/get-food-by-seller/${id}/${limit}`
        let res = await axios.get(URL);
        if (res.status === 200 && res.data["status"] === "Success") {
           store.dispatch(GetFoodBySeller(res.data.data))

            return true;
          } else {
            toast("Something went wrong! CategoryAPI -1")
            // ErrorToast("Something went wrong! getBannersAPI -1");
            return false;
          }

    }
    catch(e){
        toast("Something went wrong! CategoryAPI -2");

    }
}