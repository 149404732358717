import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  useGetWishFoodByBuyerQuery,
  useGetWishFoodBySellerQuery,
} from "../../../Redux/API/food";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../Helper/SessionHelper";
import { Button } from "react-bootstrap";

const WishFoodBuyer = () => {
  const navigate = useNavigate();
  const userData = getUserDetails();

  const { data: WishFoodData } = useGetWishFoodByBuyerQuery(userData?._id);

  console.log(WishFoodData, "wish-buyer");
  const handleDelete = (data) => {
    axios
      .delete(`${BaseURL}/delete-food/${data}`)
      .then((res) => {
        console.log(res);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };
  const handleCart = async (cart) => {
    if (userData) {
      try {
        await fetch(`${BaseURL}/create-wish-cart-items`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ customerID: userData?._id, cartItem: cart }),
        })
          .then((res) => res.json())
          .then((data) => {
            //console.log((data);
            if (data.status === "Success") {
              navigate(`/checkout/${cart._id}`);
              // <Navigate to={"/checkOut"} />;
              Swal.fire({ title: "Check Out successful!" });
            }
          });
      } catch (e) {}
    }
  };
  return (
    <>
      <div className='card h-auto'>
        <div className='card-body p-0'>
          <div className='table-responsive'>
            {WishFoodData === undefined ? (
              ""
            ) : (
              <table
                className='table table-list i-table style-1 mb-4 border-0'
                id='guestTable-all3'
              >
                <thead>
                  <tr>
                    <th className='bg-none sorting_asc'>
                      <div className='form-check style-3'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='checkAll'
                          //   onClick={() => chackboxFun("all")}
                        />
                      </div>
                    </th>
                    <th>Image</th>
                    <th>FoodDataName</th>
                    <th>createdBy</th>
                    <th>Price</th>
                    <th className='bg-none'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {WishFoodData?.data?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className='application_sorting_1'>
                          <div className='form-check style-3'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              //   onClick={() => chackboxFun()}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <img
                              className='me-3 rounded-circle'
                              src={item?.foodImage[0]?.orginalImageUrl}
                              alt='images'
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <h5 className='mb-0'>{item?.foodName}</h5>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <h5 className='mb-0'> {item?.createdDate}</h5>
                          </div>
                          {/* <span>2,97 Km</span> */}
                        </td>

                        <td>
                          <div>
                            {!!item.foodPrice && (
                              <h4 className='text-primary'>
                                {item?.foodPrice}
                              </h4>
                            )}
                            {!item.foodPrice && (
                              <h4 className='text-primary'>not set </h4>
                            )}
                            {/* <h4 className='text-primary'>{item?.foodPrice}</h4> */}
                          </div>
                        </td>
                        <td>
                          <span className='badge badge-xl light badge-success'>
                            {/* <Link to={`/checkout/${item._id}`}> */}
                            <Button onClick={() => handleCart(item)}>
                              confirm order
                            </Button>
                            {/* </Link> */}

                            <Button className='ms-2'>Reject</Button>
                            {/* <Link to={"/edit-food"} state={item}>
                              Edit
                            </Link> */}
                          </span>
                        </td>
                        {/* <td>
                          {item.status === true ? (
                            ""
                          ) : (
                            <div>
                              <Link
                                to={"#"}
                                onClick={() => {
                                  del(item?._id);
                                }}
                                className='btn btn-outline-primary'
                              >
                                Delete
                              </Link>
                            </div>
                          )}
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WishFoodBuyer;
