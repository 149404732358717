/// Menu
import React, {
  Component,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import Collapse from "react-bootstrap/Collapse";
/// Link
import { Link, NavLink } from "react-router-dom";
import { FiPhoneCall } from "react-icons/fi";

import { MenuList } from "./Menu";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { getUserDetails } from "../../../Helper/SessionHelper";
import { useSelector } from "react-redux";
import { MobileNavMenuToggle } from "../nav/NavHader";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = () => {
  var d = new Date();
  const UserType = getUserDetails()?.userType;
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);

  let handleheartBlast = document.querySelector(".heart");
  function heartBlast() {
    return handleheartBlast.classList.toggle("heart-blast");
  }

  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  useEffect(() => {}, []);

  const handleMenuActive = (status) => {
    setState({ active: status });
    //console.log(state.active);
    if (state.active === status) {
      //setActive('');
      setState({ active: "" });
    }
    // NavMenuToggle();
  };
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
    if (window.innerWidth <= 768) {
      MobileNavMenuToggle();
      console.log(window.innerWidth);
    }
  };

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  // console.log(getUserDetails(),UserType);
  let userInfo = useSelector((state) => state.Authentication.seller);

  return (
    <div
      className={`dlabnav  border-right ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >

      <PerfectScrollbar className='dlabnav-scroll'>
        <ul className={`metismenu }`} id='menu'>
          <li className='menu-title'> Main Menu</li>

          {MenuList.map((data, index) => {
            console.log(data);
            if (userInfo && userInfo[0]?.userType === data.type) {
              let menuClass = data.classsChange;
              console.log(userInfo, "from map");
              if (menuClass === "menu-title") {
                return <li className={menuClass}>{data.entitle}</li>;
              } else {
                return (
                  <li
                    className={` ${
                      state.active === data.entitle ? "mm-active" : ""
                    }`}
                    key={index}
                  >
                    {data.content && data.content.length > 0 ? (
                      <Link
                        to={"#"}
                        className='has-arrow'
                        onClick={() => {
                          handleMenuActive(data.entitle);
                        }}


                      >
                        <i className={data.iconStyle}></i>

                        <span className='nav-text'>{data.entitle} </span>
                      </Link>
                    ) : (
                      <NavLink to={data.to}>
                        <i className={data.iconStyle}></i>

                        <span className='nav-text'>{data.entitle}</span>
                      </NavLink>
                    )}
                    <Collapse in={state.active === data.entitle ? true : false}>
                      <ul
                        className={`${
                          menuClass === "mm-collapse" ? "mm-show" : ""
                        }`}
                      >
                        {data.content &&
                          data.content.map((data, index) => {
                            return (
                              <>
                                <li
                                  key={index}
                                  className={`${
                                    state.activeSubmenu === data.entitle
                                      ? "mm-active"
                                      : ""
                                  }`}
                                >
                                  {data.content && data.content.length > 0 ? (
                                    <NavLink
                                      to={data.to}
                                      className={
                                        data.hasMenu ? "has-arrow" : ""
                                      }
                                      onClick={() => {
                                        handleSubmenuActive(data.entitle);
                                      }}
                                    >
                                      {data.entitle}
                                    </NavLink>
                                  ) : (
                                    <Link
                                      to={data.to}
                                      onClick={() => {
                                        handleSubmenuActive(data.entitle);
                                      }}
                                    >
                                      {data.entitle}
                                    </Link>
                                  )}
                                  <Collapse
                                    in={
                                      state.activeSubmenu === data.entitle
                                        ? true
                                        : false
                                    }
                                  >
                                    <ul
                                      className={`${
                                        menuClass === "mm-collapse"
                                          ? "mm-show"
                                          : ""
                                      }`}
                                    >
                                      {data.content &&
                                        data.content.map((data, index) => {
                                          return (
                                            <>
                                              <li key={index}>
                                                <Link
                                                  className={`${
                                                    path === data.to
                                                      ? "mm-active"
                                                      : ""
                                                  }`}
                                                  to={data.to}
                                                >
                                                  {data.entitle}
                                                </Link>
                                              </li>
                                            </>
                                          );
                                        })}
                                    </ul>
                                  </Collapse>
                                </li>
                              </>
                            );
                          })}
                      </ul>
                    </Collapse>
                  </li>
                );
              }
            } else if (data.type === "common") {
              console.log(userInfo, "from map");
              let menuClass = data.classsChange;
              if (menuClass === "menu-title") {
                return <li className={menuClass}>{data.entitle}</li>;
              } else {
                return (
                  <li
                    className={` ${
                      state.active === data.entitle ? "mm-active" : ""
                    }`}
                    key={index}
                  >
                    {data.content && data.content.length > 0 ? (
                      <Link
                        to={"#"}
                        className='has-arrow'
                        onClick={() => {
                          handleMenuActive(data.entitle);
                        }}
                      >
                        <i className={data.iconStyle}></i>

                        <span className='nav-text'>{data.entitle}</span>
                      </Link>
                    ) : (
                      <NavLink
                        to={data.to}
                        onClick={() => {
                          handleSubmenuActive(data.entitle);
                        }}
                      >
                        <i className={data.iconStyle}></i>

                        <span className='nav-text'>{data.entitle}</span>
                      </NavLink>
                    )}
                    <Collapse in={state.active === data.entitle ? true : false}>
                      <ul
                        className={`${
                          menuClass === "mm-collapse" ? "mm-show" : ""
                        }`}
                      >
                        {data.content &&
                          data.content.map((data, index) => {
                            return (
                              <>
                                <li
                                  key={index}
                                  className={`${
                                    state.activeSubmenu === data.entitle
                                      ? "mm-active"
                                      : ""
                                  }`}
                                >
                                  {data.content && data.content.length > 0 ? (
                                    <NavLink
                                      to={data.to}
                                      className={
                                        data.hasMenu ? "has-arrow" : ""
                                      }
                                      onClick={() => {
                                        handleSubmenuActive(data.entitle);
                                      }}
                                    >
                                      {data.entitle}
                                    </NavLink>
                                  ) : (
                                    <Link
                                      to={data.to}
                                      onClick={() => {
                                        handleSubmenuActive(data.entitle);
                                      }}
                                    >
                                      {data.entitle}
                                    </Link>
                                  )}
                                  <Collapse
                                    in={
                                      state.activeSubmenu === data.entitle
                                        ? true
                                        : false
                                    }
                                  >
                                    <ul
                                      className={`${
                                        menuClass === "mm-collapse"
                                          ? "mm-show"
                                          : ""
                                      }`}
                                    >
                                      {data.content &&
                                        data.content.map((data, index) => {
                                          return (
                                            <>
                                              <li key={index}>
                                                <Link
                                                  className={`${
                                                    path === data.to
                                                      ? "mm-active"
                                                      : ""
                                                  }`}
                                                  to={data.to}
                                                >
                                                  {data.entitle}
                                                </Link>
                                              </li>
                                            </>
                                          );
                                        })}
                                    </ul>
                                  </Collapse>
                                </li>
                              </>
                            );
                          })}
                      </ul>
                    </Collapse>
                  </li>
                );
              }
            }
          })}
        </ul>

        <div className='copyright mt-0'>
          {/* <p
            onClick={(event) => {

              const textArea = document.createElement("textarea");
              textArea.value = +8809643123456;
              document.body.appendChild(textArea);
              textArea.select();
              document.execCommand("copy");
              document.body.removeChild(textArea);
              alert("copy your clipboard");
            }}
            // style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            <FiPhoneCall /> Helpline: +8809643123456
          </p> */}
          <p>
            {" "}
            <FiPhoneCall /> Helpline:{" "}
            <a style={{ color: "white" }} href='tel:+8809643123456'>
              +8809643123456
            </a>
          </p>
          <p> © {d.getFullYear()} OWNFOOD</p>
          <p className='fs-12'>
            Made with
            <span className='heart' onClick={() => heartBlast()}></span> by
            Smart Framework
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
