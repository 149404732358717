import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getUserDetails } from "../../../Helper/SessionHelper";
import {
    ImageUploader,
    onUploadProgress,
} from "../../../ImageUploader/ImageUploader";

const EditRecipeCategory = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [EditorData, setEditorData] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const handleFileChange = (event) => {
        const fileList = Array.from(event.target.files);
        setSelectedImage(fileList);
    };
    const [selectedMetaImage, setSelectedMetaImage] = useState(null);
    const handleMetaFileChange = (event) => {
        const metaFile = Array.from(event.target.files);
        setSelectedMetaImage(metaFile);
    };

    const getseller = JSON.parse(localStorage.getItem("UserDetails"));
    const [recipeCategory, setRecipeCategory] = useState([]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setRecipeCategory((prev) => {
            const data = {
                ...prev,
                seo: { ...prev.seo, seoData },
                [name]: value
            };
            return data;
        });

    };

    const [seoData, setSeoData] = useState({});
    const onChangeSeoData = (name, value) => {
        const seo = {
            ...recipeCategory.seo,
            [name]: value
        };

        setRecipeCategory((prev) => {
            const data = {
                ...prev,
                seo: seo
            };
            console.log(data, name, value);
            return data;
        });

        setSeoData(seo);
    };

    useEffect(() => {
        const getRecipeCategory = (async) => {
            fetch(`${BaseURL}/get-single-recipe-category/${params.id}`)
                .then((res) => res.json())
                .then((data) => {
                    setRecipeCategory(data.data[0])
                });
        };

        getRecipeCategory();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append("folder", "Admin/Category"); // folder name
        selectedImage?.forEach((file) => {
            formData.append("cover[]", file);
        });

        if (selectedMetaImage) {
            setIsFileUploading(true);
            await ImageUploader(getUserDetails(), "SEO/MetaImage/", selectedMetaImage).then(
                (data) => {
                    console.log(data, "meta-image")
                    if (data.status === "Success") {
                        recipeCategory.seo.metaImage = data.images;
                    } else {
                        setIsFileUploading(false);
                    }
                }
            );
        }

        fetch(`${BaseURL}/imguploads`, {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then(async function (data) {

                if (data?.images[0].orginalImageUrl) {
                    console.log(data?.images[0].orginalImageUrl)
                    recipeCategory["categoryImage"] = data?.images[0].orginalImageUrl;
                }

                if (selectedImage != null && selectedImage.length === 0) {
                    recipeCategory["categoryImage"] = "";
                }

                await axios
                    .post(`${BaseURL}/update-recipe-category/${params.id}`, recipeCategory)
                    .then((res) => {
                        toast.success("Category Created", {
                            position: "bottom-center",
                            autoClose: 5000
                        });

                        navigate("/my-recipe-category");
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
    };

    return (
        <div className='container  '>
            <ToastContainer />
            <div className='col-12'>
                <div className='card'>
                    <div className='card-header'>
                        <h4 className='card-title'>Enter Details of Recipe Category</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={handleSubmit}>
                                <div className='form-group mb-3'>
                                    <label>Category Title*</label>
                                    <input
                                        type='text'
                                        className='form-control input-default required'
                                        placeholder='Category Name'
                                        label='Name:'
                                        name='categoryName'
                                        value={recipeCategory.categoryName || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className='form-group mb-3'>
                                    <label>Category Description</label>
                                    {/* <h2>Using CKEditor 5 build in React</h2> */}
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"],
                                        }}
                                        data={recipeCategory.categoryDescription}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setRecipeCategory((prev) => { return { ...prev, categoryDescription: data }});
                                        }}
                                        style={{ height: 20 }}
                                    />


                                </div>



                                <div className='row d-flex'>
                                    <div className="col-xl-2">
                                        <div className="contact-title">
                                            <label>Category Image</label>
                                        </div>
                                    </div>


                                    <div className="col-xl-7 mb-3">

                                        <form id='form-file-upload'>
                                            <input
                                                type='file'
                                                id='input-file-upload'
                                                // multiple
                                                onChange={handleFileChange}
                                            />
                                            <label id='label-file-upload' htmlFor='input-file-upload'>
                                                <div>
                                                    <p>Drag and drop your file here or</p>
                                                    <span className='upload-button'>Upload a file</span>
                                                </div>
                                            </label>
                                        </form>


                                    </div>
                                    <div className="col-xl-3 mb-3">
                                        {selectedImage && (
                                            <div>
                                                {selectedImage?.map((item, index) => {
                                                    return (
                                                        <>
                                                            <img
                                                                alt='not found'
                                                                className="img-fluid"
                                                                src={URL.createObjectURL(item)}
                                                            />
                                                            <br />
                                                            <Button
                                                                style={{ marginLeft: 25 }}
                                                                onClick={() => setSelectedImage([])}
                                                            >
                                                                Remove
                                                            </Button>
                                                        </>

                                                    );
                                                })}
                                            </div>
                                        )}

                                        {!selectedImage && recipeCategory.categoryImage && recipeCategory.categoryImage[0] && (
                                            <>
                                                <img
                                                    alt='not found'
                                                    className="img-fluid"
                                                    src={recipeCategory.categoryImage[0]}
                                                />
                                                <br />
                                                <Button
                                                    style={{ marginLeft: 25 }}
                                                    onClick={() => setSelectedImage([])}
                                                >
                                                    Remove
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row d-flex">

                                    <div className="col-xl-2">
                                        <div className="contact-title">
                                            <h4 className="mb-xl-0 mb-2">SEO </h4>
                                            <span>সার্চ ইঞ্জিন অপ্টিমাইজেশান (Search Engine Optimization)</span>
                                        </div>
                                    </div>
                                    <div className="col-xl-10">
                                        <div className="setting-input mb-3">
                                            <label className="form-label">Meta Title</label>
                                            <input type="text" className="form-control" placeholder="Meta Title" name='metaTitle'
                                                value={recipeCategory.seo && recipeCategory.seo.metaTitle || ""}
                                                onChange={(e) => { onChangeSeoData(e.target.name, e.target.value) }}
                                            />
                                        </div>
                                        <div className="setting-input mb-3">
                                            <label className="form-label">Meta Description</label>
                                            <input type="text" className="form-control" placeholder="Meta Description" name='metaDescription'
                                                value={recipeCategory.seo && recipeCategory.seo.metaDescription || ""}
                                                onChange={(e) => { onChangeSeoData(e.target.name, e.target.value) }}
                                            />
                                        </div>
                                        <div className="setting-input mb-3">
                                            <label className="form-label">Meta Keywords</label>
                                            <input type="text" className="form-control" placeholder="Meta Keywords" name='metaKeywords'
                                                value={recipeCategory.seo && recipeCategory.seo.metaKeywords || ""}
                                                onChange={(e) => { onChangeSeoData(e.target.name, e.target.value) }}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="form-group mb-3 col-md-6">
                                                <label className="form-label">Meta Image</label>


                                                <input
                                                    type='file'
                                                    className='form-control input-default '
                                                    placeholder='Meta Image'
                                                    label='metaImage'
                                                    name='metaImage'
                                                    // value={data?.price}
                                                    onChange={handleMetaFileChange}
                                                />

                                            </div>
                                            <div className="form-group mb-3 col-md-6">
                                                <div>
                                                    {selectedMetaImage?.map((item, index) => {
                                                        return (
                                                            <>
                                                                <img className="img-fluid"
                                                                    alt='Meta Image'
                                                                    width={""}
                                                                    src={URL.createObjectURL(item)}
                                                                />
                                                                <br />
                                                                <Button
                                                                    style={{ marginLeft: 25 }}
                                                                    onClick={() => setSelectedMetaImage([])}
                                                                >
                                                                    Remove
                                                                </Button>
                                                            </>

                                                        );
                                                    })}

                                                    {!selectedMetaImage && recipeCategory && recipeCategory.seo && recipeCategory.seo.metaImage && recipeCategory.seo.metaImage[0] && Object.keys(recipeCategory.seo.metaImage[0]).length != 0 && (
                                                        <>
                                                            <img
                                                                alt='not found'
                                                                className="img-fluid"
                                                                src={recipeCategory.seo.metaImage[0].orginalImageUrl}
                                                            />
                                                            <br />
                                                            <Button
                                                                style={{ marginLeft: 25 }}
                                                                onClick={() => setSelectedMetaImage([])}
                                                            >
                                                                Remove
                                                            </Button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>




                                    </div>

                                </div>


                                <Button type='submit'> Save</Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default EditRecipeCategory;
