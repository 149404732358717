// import { toast } from "react-hot-toast";
import { ToastContainer, toast } from 'react-toastify';
import { BaseURL } from "../Helper/config";
import axios from "axios";
import { getAllUsers, getUserDetails } from "../Redux/feature/Users/UsersSlice";
import store from "../Redux/store";
// const { default: axios } = require("axios");
// const { default: store } = require("../Redux/store");
// const { getAllUsers } = require("../Redux/feature/Users/UsersSlice");
import Swal from 'sweetalert2'

import { setEmail, setMobile } from "../Helper/SessionHelper";
import swal from "sweetalert";

// const MySwal = withReactContent(Swal)
export const GetAllUser = async () => {
  try {
    let URL = `${BaseURL}/get-users`;
    let res = await axios.get(URL);
    if (res.status === 200 && res.data["status"] === "Success") {
      store.dispatch(getAllUsers(res.data.data));

      return true;
    } else {
      toast("Something went wrong! getBannersAPI -1");
      // ErrorToast("Something went wrong! getBannersAPI -1");
      return false;
    }
  } catch (e) {
    toast("Something went wrong! getBannersAPI -2");
    return false;
  }
};
export const userDetailsAPI = async (id) => {
  try {
    console.log(id);
    let URL = `${BaseURL}/get-user-details/${id}`;
    let res = await axios.get(URL);
    if (res.status === 200 && res.data["status"] === "Success") {
      console.log(res.status, res.data["status"], res.data.data);
      store.dispatch(getUserDetails(res.data.data));

      return true;
    } else {
      toast("Something went wrong! getBannersAPI -1");
      // ErrorToast("Something went wrong! getBannersAPI -1");
      return false;
    }
  } catch (e) {
    toast(e.message);
  }
};

//! =================== Step One Recovery Verify Email ===================

// Step One Recovery Verify Email
export const RecoveryVerifyEmail = async (email) => {
  try {
    let URL = BaseURL + "/forget-password-verify-email/" + email;
    let res = await axios.post(URL);
    if (res.status === 200) {
      console.log("forget");
      if (res.data["status"] === "Fail") {
        Swal.fire({ title: "⚠️",
        text: res.data.data,
        timer: 1500,
        icon: "error"})
        return false;
      } else {
        setEmail(email);
        toast(
          "A 4 Digit verification code has been sent to your email address. "
        );
        return true;
      }
    } else {
      toast("Something wrong RecoveryVerifyEmailAPI-1");
      return false;
    }
  } catch (e) {
    toast("Something wrong RecoveryVerifyEmailAPI-2");
    return false;
  }
};

// Step One Recovery Verify Mobile number
export const RecoveryVerifyMobile = async (mobile) => {
  try {
    let URL = BaseURL + "/forget-password-verify-mobile/" + mobile;
    let res = await axios.post(URL);
    if (res.status === 200) {
      console.log(res);
      if (res.data["status"] === "Fail") {


        Swal.fire({ title: "⚠️",
        text: res.data.data,
        timer: 1500,
        showConfirmButton: false,

        icon: "error"})

        return false;
      } else {
        setMobile(mobile);
        Swal.fire({ title: "A 4 Digit verification code has been sent to your mobile number ✔️",
        // text: res.data.data,
        showConfirmButton: false,
        timer: 1500,
        icon: "success"})

        return true;
      }
    } else {
      toast("Something wrong RecoveryVerifymobileAPI-1");
      return false;
    }
  } catch (e) {
    toast("Something wrong RecoveryVerifymobileAPI-2");
    return false;
  }
};
