import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Table, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { BaseURL } from "../../../Helper/config";
import { getUserDetails } from "../../../Helper/SessionHelper";
import PageTitle from "../../layouts/PageTitle";

import labels from "../../../translationData/recipe.json";

function dateFormat(value) {
  let dateData = new Date(value);
  // Format the date as dd/mm/yyyy
  const formattedDate = dateData.toLocaleDateString('bn-BD'); // 'bn-BD' for the Bengali language in Bangladesh
  return formattedDate;
}

const Recipe_List = () => {
  const [recipeData, setRecipeData] = useState();

  const chackboxFun = (type) => {
    setTimeout(() => {
      const checkbox = document.querySelectorAll(".bs_exam_topper input");
      const motherCheckBox = document.querySelector(
        ".bs_exam_topper_all input"
      );
      for (let i = 0; i < checkbox.length; i++) {
        const element = checkbox[i];
        if (type === "all") {
          if (motherCheckBox.checked) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        } else {
          if (!element.checked) {
            motherCheckBox.checked = false;
            break;
          } else {
            motherCheckBox.checked = true;
          }
        }
      }
    }, 100);
  };
  console.log(getUserDetails()._id);
  useEffect(() => {
    //chackboxFun()

    axios
      .get(`${BaseURL}/get-recipe-by-single-seller/${getUserDetails()._id}/10`)
      .then((res) => {
        console.log(res.data.data);
        setRecipeData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDelete = (data) => {
    axios
      .delete(`${BaseURL}/delete-recipe/${data}`)
      .then((res) => {
        console.log(res);
        const newData = recipeData.filter((item) => item._id !== data);
        setRecipeData(newData);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  return (
    <>
      <PageTitle activeMenu='আমার রেসিপি' motherMenu='রেসিপি' />
      <Card>
        <Card.Header>
          <Card.Title>আমার রেসিপি</Card.Title>
        </Card.Header>
        <Card.Body>
          <Table responsive>
            <thead>
              <tr>
                {/* <th className="width50 ">
                  <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                    ID
                  </div>
                </th> */}
                <th>
                  <strong>RecipeID</strong>
                </th>
                <th>
                  <strong>{labels.foodImg.en}</strong>
                </th>
                <th>
                  <strong>{labels.recipeName.en}</strong>
                </th>
                {/* <th>
                  <strong>{labels.preparationTime.en}</strong>
                </th>
                <th>
                  <strong>{labels.cookingTime.en}</strong>
                </th>
                <th>
                  <strong>{labels.serves.en}</strong>
                </th> */}
                <th>
                  <strong>{labels.status.en}</strong>
                </th>
                <th>
                  <strong>{labels.catRecipeDate.en}</strong>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {recipeData?.map((item, index) => {
                return (
                  <tr key={index}>
                    {/* <td className="application_sorting_1">
                      <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                        {item.id}
                      </div>
                    </td> */}
                    <td>{item.recipeID}</td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <img
                          src={item?.recipeReadyFoodImage[0]?.orginalImageUrl}
                          className='rounded-lg me-2'
                          width='100'
                          alt=''
                        />{" "}
                      </div>
                    </td>
                    <td>{item.recipeName}</td>
                    {/* <td>{item.preparationTime}</td>
                    <td>{item.cookingTime}</td>
                    <td>{item.serves}</td> */}
                    <td>
                      {item.status === true ? (
                        <div className='d-flex align-items-center'>
                          <i className='fa fa-circle text-success me-1'></i>{" "}
                          Active
                        </div>
                      ) : (
                        <div className='d-flex align-items-center'>
                          <i className='fa fa-circle text-danger me-1'></i>{" "}
                          Inactive
                        </div>
                      )}
                    </td>
                    <td>{dateFormat(item.createdDate)}</td>
                    <td>
                      <div className='d-flex'>
                        <Link
                          to={`/edit-recipe/${item._id}`}
                          state={item}
                          className='btn btn-primary shadow btn-xs sharp me-1'
                        >
                          <i className='fas fa-pencil-alt'></i>
                        </Link>

                        <div>
                          <Link
                            to={"#"}
                            onClick={() => {
                              del(item._id);
                            }}
                            className='btn btn-danger shadow btn-xs sharp'
                          >
                            <i className='fa fa-trash'></i>
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default Recipe_List;
