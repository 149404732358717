import React, { useEffect, useState } from "react";

import { Button } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BaseURL } from "../../../Helper/config";
import { getUserDetails } from "../../../Helper/SessionHelper";
import Swal from "sweetalert2";
// import ImageUploader from "../../../ImageUploader/ImageUploader";
import { SuccessToast } from "../../../Helper/FormHelper";
import { ImageUploader } from "../../../ImageUploader/ImageUploader";
import labels from "../../../translationData/howDo.json";

const HowDoWeDo = () => {
  const [selectedImageBefore, setSelectedImageBefore] = useState([]);
  const [selectedImageAfter, setSelectedImageAfter] = useState([]);
  const [EditorData, setEditorData] = useState();
  const Seller = JSON.parse(localStorage.getItem("UserDetails"));

  const navigate = useNavigate();
  const allowedExtensions = ["jpg", "jpeg", "png"];

  const isFileExtensionAllowed = (fileName) => {
    const ext = fileName.split(".").pop();
    return allowedExtensions.includes(ext.toLowerCase());
  };

  const handleFileChangeBefore = (event) => {
    const fileList = Array.from(event.target.files);
    console.log(fileList);
    const filteredFiles = fileList.filter(
      (file) => {
        if (!isFileExtensionAllowed(file.name)) {
          Swal.fire({
            icon: "question",
            title: "Only jpg, jpeg and png file are allowed!",
          });
        }
        return isFileExtensionAllowed(file.name);
      }
      // isFileExtensionAllowed(file.name)
    );
    setSelectedImageBefore(filteredFiles);
    console.log(setSelectedImageBefore);
  };

  const handleFileChangeAfter = (event) => {
    const fileList = Array.from(event.target.files);
    const filteredFiles = fileList.filter((file) => {
      if (!isFileExtensionAllowed(file.name)) {
        Swal.fire({
          icon: "question",
          title: "Only jpg, jpeg and png file are allowed!",
        });
      }
      return isFileExtensionAllowed(file.name);
    });
    setSelectedImageAfter(filteredFiles);
  };

  const [selectedMetaImage, setSelectedMetaImage] = useState([]);
  const handleMetaFileChange = (event) => {
    const metaFile = Array.from(event.target.files);
    setSelectedMetaImage(metaFile);
  };

  const handleHowDOWeDo = async (e) => {
    console.log(selectedImageBefore, selectedImageAfter);
    e.preventDefault();
    const form = e.target;
    const title = form.title.value;
    const description = EditorData;
    const videoURL = form.video.value;
    const metaTitle = e.target.metaTitle.value;
    const metaDescription = e.target.metaDescription.value;
    const metaKeywords = e.target.metaKeywords.value;

    const seoData = {
      metaTitle: title,
      metaDescription: description,
      metaKeywords: title,
    };

    const HowDoWeDOData = {
      title: title,
      description: description,
      videoURL: videoURL,
      sellerID: getUserDetails()._id,
      userID: getUserDetails()._id,
      seo: seoData,
    };

    if (selectedMetaImage && selectedMetaImage.length > 0) {
      await ImageUploader(Seller, "HowDoWeDo/seo", selectedMetaImage).then(
        (imgData) => {
          if (imgData.status === "Success") {
            seoData["metaImage"] = imgData.images;
          }
        }
      );
    }
    if (selectedImageBefore && selectedImageBefore.length > 0) {
      await ImageUploader(Seller, "HowDoWeDo", selectedImageBefore).then(
        async (data) => {
          if (data.status === "Success") {
            console.log(data);
            HowDoWeDOData["beforeImage"] = data.images;
            await ImageUploader(Seller, "HowDoWeDo", selectedImageAfter).then(
              async (data1) => {
                if (data1.status === "Success") {
                  HowDoWeDOData["afterImage"] = data1.images;
                  seoData["metaImage"] = data1.images;
                  await axios
                    .post(`${BaseURL}/create-how-do-we-do`, HowDoWeDOData)
                    .then((res) => {
                      if (res.data.status == "Success") {
                        form.reset();
                        Swal.fire({
                          icon: "success",
                          title: "How do we do created successfully!!!",
                          showConfirmButton: false,
                          timer: 1500,
                        });

                        navigate("/my-how-do-we-do");

                        SuccessToast(
                          "Success! You have successfully created How Do We Do"
                        );
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }
            );
          }
        }
      );
    }

    console.log(HowDoWeDOData);
  };

  return (
    <div className="container  ">
      <div className="col-12">
        <div className="card how-do-card">
          <div className="card-header">
            <h4 className="card-title">{labels.formTitle.en}</h4>
          </div>

          <div className="card-body">
            <div className="basic-form">
              <form onSubmit={handleHowDOWeDo}>
                <div className="setting-input mb-3">
                  <label className="form-label">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter How do we do title"
                    name="title"
                    required
                  />
                </div>

                <div className="row d-flex">
                  <label>{labels.details.en}</label>
                  <div className="form-group mb-3">
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        removePlugins: [
                          "EasyImage",
                          "ImageUpload",
                          "MediaEmbed",
                        ],
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setEditorData(data);
                      }}
                      style={{ height: 20 }}
                    />
                  </div>
                </div>

                <div className="setting-input mb-3">
                  <label className="form-label">Video URL</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Video URL"
                    name="video"
                  />
                </div>

                <div className="row d-flex before">
                  <div className="col-6">
                    <label>
                      {labels.beforeImg.en}
                      {"*"}
                    </label>
                    {(!selectedImageBefore ||
                      selectedImageBefore.length === 0) && (
                      <>
                        {" "}
                        {console.log(selectedImageBefore)}
                        <input
                          name="beforeImage"
                          type="file"
                          id="input-file-upload1"
                          onChange={handleFileChangeBefore}
                        />
                        <label
                          id="label-file-upload1"
                          htmlFor="input-file-upload1"
                        >
                          <div>
                            <p>Drag and drop your image here</p>
                            <span className="upload-button">Upload Image</span>
                          </div>
                        </label>
                      </>
                    )}
                    {selectedImageBefore && selectedImageBefore.length > 0 && (
                      <>
                        <div>
                          {console.log(selectedImageBefore)}
                          {selectedImageBefore.map((item, index) => {
                            console.log(item);
                            return (
                              <img
                                width={"150px"}
                                height={"150px"}
                                src={URL.createObjectURL(item)}
                              />
                            );
                          })}

                          <span
                            style={{
                              position: "relative",
                              top: "-35px",
                              left: "-26px",
                            }}
                          >
                            <Link
                              to={"#"}
                              onClick={() => setSelectedImageBefore(null)}
                              className="btn btn-danger shadow btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </span>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="col-6">
                    <label>
                      {labels.afterImg.en}
                      {"*"}
                    </label>

                    {(!selectedImageAfter ||
                      selectedImageAfter.length === 0) && (
                      <>
                        <input
                          name="afterImage"
                          type="file"
                          id="input-file-upload"
                          onChange={handleFileChangeAfter}
                        />
                        <label
                          id="label-file-upload"
                          htmlFor="input-file-upload"
                        >
                          <div>
                            <p>Drag and drop your image here</p>
                            <span className="upload-button">Upload Image</span>
                          </div>
                        </label>
                      </>
                    )}
                    {selectedImageAfter && selectedImageAfter.length > 0 && (
                      <>
                        <div>
                          {selectedImageAfter.map((item, index) => {
                            console.log(item);
                            return (
                              <img
                                width={"150px"}
                                height={"150px"}
                                src={URL.createObjectURL(item)}
                              />
                            );
                          })}

                          <span
                            style={{
                              position: "relative",
                              top: "-35px",
                              left: "-26px",
                            }}
                          >
                            <Link
                              to={"#"}
                              onClick={() => setSelectedImageAfter(null)}
                              className="btn btn-danger shadow btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div
                  style={{ position: "absolute", left: "-9999px" }}
                  className="row d-flex mt-3"
                >
                  <div className="col-xl-2">
                    <div className="contact-title">
                      <h4 className="mb-xl-0 mb-2">SEO </h4>
                      <span>
                        সার্চ ইঞ্জিন অপ্টিমাইজেশান (Search Engine Optimization)
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-10">
                    <div className="setting-input mb-3">
                      <label className="form-label">Meta Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Title"
                        name="metaTitle"
                      />
                    </div>
                    <div className="setting-input mb-3">
                      <label className="form-label">Meta Description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Description"
                        name="metaDescription"
                      />
                    </div>
                    <div className="setting-input mb-3">
                      <label className="form-label">Meta Keywords</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Keywords"
                        name="metaKeywords"
                      />
                    </div>
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label className="form-label">Meta Image</label>

                        <input
                          type="file"
                          className="form-control input-default "
                          placeholder="Meta Image"
                          label="metaImage"
                          name="metaImage"
                          // value={data?.price}
                          onChange={handleMetaFileChange}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        {selectedMetaImage && (
                          <div>
                            {selectedMetaImage?.map((item, index) => {
                              return (
                                <>
                                  <img
                                    className="img-fluid"
                                    alt="Meta Image"
                                    width={""}
                                    src={URL.createObjectURL(item)}
                                  />
                                  <br />
                                  <Button
                                    style={{ marginLeft: 25 }}
                                    onClick={() => setSelectedMetaImage(null)}
                                  >
                                    Remove
                                  </Button>
                                </>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <Button style={{ marginTop: 40 }} type="submit">
                  {" "}
                  Save
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowDoWeDo;
