import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Accordion, Button, FormCheck } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { AiFillMinusCircle } from "react-icons/ai";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BaseURL } from "../../../Helper/config";

import Swal from "sweetalert2";
import { getUserDetails } from "../../../Helper/SessionHelper";
import {
  ImageUploader,
  onUploadProgress,
} from "../../../ImageUploader/ImageUploader";
import labels from "../../../translationData/recipe.json";

const Edit_Recipe = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [disable, setdisable] = useState(true);
  const [selectedMetaImage, setSelectedMetaImage] = useState(null);
  const [recipeFormData, setRecipeFormData] = useState({});
  const [recipeData, setrecipeData] = useState({
    stepName: "",
    stepImage: {},
  });
  const [recipeVideoData, setRecipeVideoData] = useState("");
  const recipehandleChange = (e) => {
    const newContact = { ...recipeData };
    newContact[e.target.name] = e.target.value;

    newContact.categoryName !== "" ? setdisable(false) : setdisable(true);
    setrecipeData(newContact);
  };
  const handleMetaFileChange = (event) => {
    const metaFile = Array.from(event.target.files);
    setSelectedMetaImage(metaFile);
  };
  let recipeSteps = {
    stepName: recipeData.stepName,
    stepImage: recipeData.stepImage,
  };
  const [stepList, setStepList] = useState([]);
  const addData = () => {
    const newStep = {
      stepName: recipeData.stepName,
      videoUrl: recipeData.videoUrl,
      stepImage: recipeData.stepImage,
    };

    // Add the new step to the list
    setStepList((prevList) => [...prevList, newStep]);
  };

  const removeData = (toBeRemoved) => {
    const re = infos.filter((item, index) => index !== toBeRemoved);
    setInfos(re);
  };

  const [ingredients, setIngredients] = useState([]);
  const [ingredientsName, setIngredientsName] = useState("");
  const [seoData, setSeoData] = useState({});
  const [ingredientsQty, setIngredientsQty] = useState("");
  const [ingredientsQtyUnit, setIngredientsQtyUnit] = useState("");
  const addToIngredientsList = () => {
    setIngredients([
      ...ingredients,
      {
        ingredientsName: ingredientsName,
        Qty: ingredientsQty,
        qtyUnit: ingredientsQtyUnit,
        isInputHidden: true,
      },
    ]);
    setIngredientsName("");
    setIngredientsQty("");
  };
  const removeIngredientFromList = (toBeRemoved) => {
    const newListOfIngredients = ingredients.filter(
      (item, index) => index !== toBeRemoved
    );
    setIngredients(newListOfIngredients);
  };

  const onSetMetaTitle = (name, value) => {
    const seo = {
      ...recipeFormData.seo,
      [name]: value,
    };

    setRecipeFormData((prev) => {
      const data = {
        ...prev,
        seo: seo,
      };
      return data;
    });

    setSeoData(seo);
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);
    setSelectedImage(fileList);
  };
  const [resCat, setResCat] = useState([]);

  useEffect(() => {
    const getResCategory = (async) => {
      fetch(`${BaseURL}/get-recipe-categories`)
        .then((res) => res.json())
        .then((data) => {
          setResCat(data.data);
        });
    };

    const getRecipe = (async) => {
      fetch(`${BaseURL}/get-single-recipe/${params.id}`)
        .then((res) => res.json())
        .then((data) => {
          console.log("debug",data)
          setRecipeFormData(data.data[0]);

          if (data.data[0].recipeReadyFoodImage) {
            setReadyImageData(data.data[0].recipeReadyFoodImage);
          }

          if (data.data[0].ingredients) {
            setIngredients(data.data[0].ingredients);
          }

          if (data.data[0].recipeSteps) {
            setInfos(data.data[0].recipeSteps);
          }

          if (data.data[0].seo) {
            setSeoData(data.data[0].seo);
          }
        });
    };

    getResCategory();
    getRecipe();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setRecipeFormData((prev) => {
      const data = {
        ...prev,
        seo: { ...prev.seo, seoData },
        [name]: value,
      };

      return data;
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, value } = event.target;

    setRecipeFormData((prev) => {
      const data = {
        ...prev,
        seo: { ...prev.seo, seoData },
        [name]: JSON.parse(value),
      };

      return data;
    });
  };

  const StepData = [];
  const [stepImage, setStepImage] = useState();
  const [readyImageData, setReadyImageData] = useState(null);
  const [stepNames, setStepName] = useState("");
  const [stepVideos, setStepVideo] = useState("");
  const [infos, setInfos] = useState([]);
  const [shoppingData, setShoppingData] = useState();

  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isReadyImageUploaded, setIsReadyImageUploaded] = useState(false);

  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  const addToList = () => {
    setInfos([
      ...infos,
      { stepName: stepNames, Photo: stepImage, Video: stepVideos, isEditable: false,
        isDragging: false, },
    ]);
    setStepName(""); // Clear step name
    setStepVideo(""); // Clear video URL
    setStepImage(null); // Clear step image
  };
  console.log("after remove", readyImageData);
  const handleRecipeSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("folder", "Recipes/" + Seller?.userFullName);

    setIsFileUploading(true);

    if (selectedImage) {
      const readyImagePromise = await ImageUploader(
        Seller,
        "recipes/ReadyImage",
        selectedImage
      );

      if (readyImagePromise && readyImagePromise.status === "Success") {
        setIsReadyImageUploaded(true);
        setReadyImageData(readyImagePromise.images);
        recipeFormData["recipeReadyFoodImage"] = readyImagePromise.images;
      }
    }

    // if (
    //   readyImageData != null &&
    //   readyImageData.length === 0 &&
    //   !isReadyImageUploaded
    // ) {
    //   recipeFormData["recipeReadyFoodImage"] = [];
    // }

    const uploadPromises = infos.map(async (infoObj,index) => {
      const data = await ImageUploader(Seller, "recipes/steps", infoObj.Photo);

      if (data.status === "Success") {
        StepData.push({
          stepNumber: index,
          stepName: infoObj.stepName,
          Photo:
            infoObj.Photo[0] && infoObj.Photo[0].orginalImageUrl
              ? infoObj.Photo
              : data.images,
          Video: infoObj.Video,
        });
      } else {
        setIsFileUploading(false);
      }
    });

    recipeFormData["sellerID"] = getUserDetails()._id;
    recipeFormData["ingredients"] = ingredients;
    recipeFormData["recipeSteps"] = StepData;
    recipeFormData["seo"] = seoData;

    Promise.all(uploadPromises).then((data) => {
      axios
        .post(`${BaseURL}/update-recipe/${params.id}`, recipeFormData)
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Recipe updated successfully!!!",
              showConfirmButton: false,
              timer: 1500,
            });

            navigate("/my-create-recipe");
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Network Error!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    });
  };

  // DRAG & DROP RECIPE LIST START //
  let infoItemDrag = useRef();
  let infoItemDragOver = useRef();

  function D_Start(e, index) {
    infoItemDrag.current = index;
  }
  function D_Enter(e, index) {
    infoItemDragOver.current = index;

    const cpArr = [...infos];

    let finalArr = [];

    cpArr.forEach((item) => {
      finalArr.push({
        stepName: item.stepName,
        stepNames: item.stepNames,
        stepImage: item.stepImage,
        Photo: item.Photo,
        Video: item.Video,
        isDragging: false,
        isEditable: false,
      });
    });

    finalArr[index].isDragging = true;

    setInfos(finalArr);
  }
  function D_End(e, index) {
    const arr1 = [...infos];

    const info_item_main = arr1[infoItemDrag.current];
    arr1.splice(infoItemDrag.current, 1);
    arr1.splice(infoItemDragOver.current, 0, info_item_main);

    infoItemDrag.current = null;
    infoItemDragOver.current = null;

    let f_arr = [];

    arr1.forEach((item) => {
      f_arr.push({
        stepName: item.stepName,
        stepNames: item.stepNames,
        stepImage: item.stepImage,
        Photo: item.Photo,
        Video: item.Video,
        isDragging: false,
        isEditable: false,
      });
    });

    setInfos(f_arr);

    console.log(infos);
  }
console.log("infos", infos);
  // DRAG & DROP RECIPE LIST END //
console.log("debug",recipeFormData)
  return (
    <>
      {" "}
      <div className="container  ">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Edit Recipe</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleRecipeSubmit}>
                  <div className="row">
                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12 col-xl-6 col-xs-12  mb-3">
                      <label>{labels.recipeName.en} *</label>
                      <input
                        type="text"
                        className="form-control input-default "
                        placeholder="Recipe Name"
                        name="recipeName"
                        value={recipeFormData && recipeFormData.recipeName || ""}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12 col-xl-6 col-xs-12   mb-3">
                      <label>{labels.category.en} *</label>
                      <select
                        name="recipeCategoryID"
                        id="inputState"
                        className="form-control"
                        onChange={handleInputChange}
                      >
                        <option value="">Select Recipe Category</option>
                        {resCat.map((item, index) => {
                          if (recipeFormData && recipeFormData.recipeCategoryID === item._id) {
                            return (
                              <option key={index} value={item._id} selected>
                                {item.categoryName}
                              </option>
                            );
                          } else {
                            return (
                              <option key={index} value={item._id}>
                                {item.categoryName}
                              </option>
                            );
                          }
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-12 mb-3">
                      <label>{labels.description.en}</label>
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          removePlugins: [
                            "EasyImage",
                            "ImageUpload",
                            "MediaEmbed",
                          ],
                        }}
                        data={recipeFormData && recipeFormData.recipeDescription || ""}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setRecipeFormData((prev) => {
                            return { ...prev, recipeDescription: data };
                          });
                        }}
                        style={{ height: 20 }}
                      />
                    </div>
                  </div>

                        {/* temporary fix }

                        {/* temporary fix */}
                   <div className="row mb-5">
                    <label>{labels.foodImg.en} * </label>

                    {selectedImage && (
                      <>
                        <div
                          className="col-6"
                          style={{ position: "relative", top: 30 }}
                        >
                          {selectedImage.map((item, index) => {
                            return (
                              <>
                                <img
                                  style={{ height: "150px" }}
                                  className="img-fluid"
                                  // alt="not found"
                                  src={URL.createObjectURL(item)}
                                />

                                <br />

                                <Button
                                  className="my-3"
                                  onClick={() => {
                                    setSelectedImage(null);
                                    setReadyImageData([]);
                                  }}
                                >
                                  Remove
                                </Button>
                              </>
                            );
                          })}
                        </div>
                        <div className="col-6"></div>
                      </>
                    )}
                    {!selectedImage && readyImageData && (
                      <>
                        <div
                          className="col-6"
                          style={{ position: "relative", top: 30 }}
                        >
                          {readyImageData.map((item, index) => {
                            return (
                              <>
                               <img
                                style={{ height: "150px" }}
                                className="img-fluid"
                                src={
                                    (item.large && item.large.length > 0 && item.large.imageUrl) // Condition 1
                                    ? item.large.imageUrl // If Condition 1 is true
                                    : (item.orginalImageUrl ? item.orginalImageUrl : null) // If Condition 1 is false, check Condition 2 and fallback to null if not defined
                                }
                              />


                                <br />

                                <Button
                                  className="my-3"
                                  onClick={() => setReadyImageData([])}
                                >
                                  Remove
                                </Button>
                              </>
                            );
                          })}
                        </div>
                        <div className="col-6"></div>
                      </>
                    )}

                    {!selectedImage &&
                      (!readyImageData || readyImageData.length === 0) && (
                        <>
                          <div className="col-6">
                            <input
                              name="readyFoodImage"
                              type="file"
                              id="input-file-upload"
                              onChange={handleFileChange}
                            />
                            <label
                              id="label-file-upload"
                              htmlFor="input-file-upload"
                            >
                              <div>
                                <p>Drag and drop your image here or</p>
                                <span className="upload-button">
                                  Upload a image
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="col-6"></div>
                        </>
                      )}
                  </div>

                  <div className="row">
                    <div className="col-lg-4 col-xxl-4 col-md-4 col-sm-12 col-xs-12 col-12 ">
                      <div className="form-group mb-3">
                        <label>{labels.preparationTime.en}</label>
                        <input
                          type="text"
                          className="form-control input-default "
                          placeholder="Preparation Time"
                          name="preparationTime"
                          value={recipeFormData && recipeFormData.preparationTime || ""}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-xxl-4 col-md-4 col-sm-12 col-xs-12 col-12">
                      <div className="form-group mb-3">
                        <label>{labels.cookingTime.en}</label>
                        <input
                          type="text"
                          className="form-control input-default "
                          placeholder="Cooking Time"
                          name="cookingTime"
                          value={recipeFormData && recipeFormData.cookingTime || ""}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-xxl-4 col-md-4 col-sm-12 col-xs-12 col-12  ` ">
                      <div className="form-group mb-3">
                        <label>{labels.serves.en}</label>
                        <input
                          type="text"
                          className="form-control input-default "
                          placeholder="Serves "
                          name="serves"
                          value={recipeFormData && recipeFormData.serves || ""}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label>Ingredients</label>

                    <ul class="list-group">
                      {ingredients?.map((item, index) => (
                        <li
                          class="list-group-item d-flex gap-3 align-items-center"
                          style={{ position: "relative" }}
                        >
                          <div>
                            <span class="badge badge-primary badge-pill">
                              {index + 1}
                            </span>
                          </div>
                          <div>
                            <input
                              hidden={
                                item.hasOwnProperty("isInputHidden")
                                  ? item.isInputHidden
                                  : true
                              }
                              type="text"
                              className="form-control input-default "
                              placeholder={labels.ingredient.name.en}
                              name={`ingredients[${index}].ingredientsName`}
                              value={item.ingredientsName || ""}
                              onChange={(e) => {
                                setIngredients((prev) => {
                                  prev[index] = {
                                    ...prev[index],
                                    ingredientsName: e.target.value,
                                  };
                                  return [...prev];
                                });
                              }}
                            />

                            {(item.isInputHidden === undefined ||
                              item.isInputHidden) &&
                              item.ingredientsName}
                          </div>
                          <div>
                            <input
                              hidden={
                                item.hasOwnProperty("isInputHidden")
                                  ? item.isInputHidden
                                  : true
                              }
                              type="text"
                              className="form-control input-default "
                              placeholder={labels.ingredient.quantity.en}
                              name={`ingredients[${index}].Qty`}
                              value={item.Qty || ""}
                              onChange={(e) => {
                                setIngredients((prev) => {
                                  prev[index] = {
                                    ...prev[index],
                                    Qty: e.target.value,
                                  };
                                  return [...prev];
                                });
                              }}
                            />

                            {(item.isInputHidden === undefined ||
                              item.isInputHidden) &&
                              (item.Qty || "")}
                          </div>

                          <div style={{ position: "absolute", right: 15 }}>
                          {item.hasOwnProperty("isInputHidden") &&
                            !item.isInputHidden ? (
                             <Link
                                  to={`#`}
                                  state={item}
                                  hidden={
                                    item.hasOwnProperty("isInputHidden")
                                      ? item.isInputHidden
                                      : true
                                  }
                                  className="btn btn-success shadow btn-xs sharp me-1 jabed"
                                  onClick={() => {
                                    setIngredients((prev) => {
                                      prev[index] = {
                                        ...prev[index],
                                        isInputHidden: item.hasOwnProperty(
                                          "isInputHidden"
                                        )
                                          ? !item.isInputHidden
                                          : false,
                                      };
                                      return [...prev];
                                    });
                                  }}
                                >
                                  <i className="fas fa-save"></i>
                            </Link>
                            ) : (
                            <Link
                              to={`#`}
                              state={item}
                              className="btn btn-primary shadow btn-xs sharp me-1"
                              onClick={() => {
                                setIngredients((prev) => {
                                  prev[index] = {
                                    ...prev[index],
                                    isInputHidden: item.hasOwnProperty(
                                      "isInputHidden"
                                    )
                                      ? !item.isInputHidden
                                      : false,
                                  };
                                  return [...prev];
                                });
                              }}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                             )}

                            <Link
                              to={"#"}
                              onClick={() => {
                                removeIngredientFromList(index);
                              }}
                              className="btn btn-danger shadow btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </li>
                      ))}
                    </ul>

                    <Col xl="12" style={{ marginTop: 20 }}>
                      <Card>
                        <Accordion
                          className="accordion accordion-no-gutter "
                          defaultActiveKey="0"
                        >
                          <Accordion.Item>
                            <Accordion.Header as="div">
                              <h5>{labels.ingredient.en}</h5>
                            </Accordion.Header>
                            <Accordion.Collapse className="px-5">
                              <div className="row">
                                <div
                                  className="form-group mb-3 col-lg-4 cols-xs-12 col-xxl-4 col-md-4 col-sm-12 col-12"
                                  style={{ padding: 10 }}
                                >
                                  <label>{labels.ingredient.name.en}</label>
                                  <input
                                    type="text"
                                    className="form-control input-default "
                                    placeholder={labels.ingredient.name.en}
                                    name="ingredientsName"
                                    value={ingredientsName}
                                    onChange={(e) =>
                                      setIngredientsName(e.target.value)
                                    }
                                  />
                                </div>

                                <div
                                  className="form-group mb-3 col-lg-3 cols-xs-12 col-xxl-3 col-md-3 col-sm-12 col-12"
                                  style={{ padding: 10 }}
                                >
                                  <label>{labels.ingredient.quantity.en}</label>
                                  <input
                                    type="text"
                                    className="form-control input-default "
                                    placeholder={labels.ingredient.quantity.en}
                                    name="qty"
                                    value={ingredientsQty}
                                    onChange={(e) =>
                                      setIngredientsQty(e.target.value)
                                    }
                                  />
                                </div>

                                {/* <div
                                  className="form-group mb-3 col-lg-3 cols-xs-12 col-xxl-3 col-md-3 col-sm-12 col-12"
                                  style={{ padding: 10 }}
                                >
                                  <label>{labels.ingredient.unit.en}</label>
                                  <input
                                    type="text"
                                    className="form-control input-default "
                                    placeholder={labels.ingredient.unit.en}
                                    name="qtyUnit"
                                    onChange={(e) =>
                                      setIngredientsQtyUnit(e.target.value)
                                    }
                                  />
                                </div> */}
                                <div className="col-lg-2 cols-xs-12 col-xxl-2 col-md-2 col-sm-12 col-12">
                                  <div style={{ marginTop: 33 }}>
                                    <Button onClick={addToIngredientsList}>
                                      <i className="fa fa-plus"></i>{" "}
                                      {/*labels.ingredient.en*/}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Collapse>
                          </Accordion.Item>
                        </Accordion>
                      </Card>
                    </Col>
                  </div>

                  <div>
                    {/* <label>{labels.recipeStep.en}</label> */}
                    <label>Recipe Steps</label>
                    <ul class="list-group">
                      {infos?.map((item, index) => (
                        <React.Fragment>
                        <li
                          class="list-group-item d-flex flex-row gap-3 align-items-center"
                          style={{
                            position: "relative",
                            paddingRight: 80,
                            paddingTop: 25,
                            paddingBottom: 25,
                          }}
                          draggable
                          droppable
                          onDragStart={(e) => D_Start(e, index)}
                          onDragEnter={(e) => D_Enter(e, index)}
                          onDragEnd={(e) => D_End(e, index)}
                        >
                          <span class="badge badge-primary badge-pill">
                            {index + 1}
                            {console.log("item", item)}
                          </span>


                          <span className="mx-2" style={{ maxWidth: 750 }}>
                          <textarea
                                style={{ display: "block", height: 60 }}
                                hidden={
                                  item.hasOwnProperty("isInputHidden")
                                    ? item.isInputHidden
                                    : true
                                }
                                className="textarea_editor form-control bg-transparent"
                                rows="10"
                                cols="100"
                                placeholder={labels.recipeStep.stepName.en}
                                name={`infos[${index}].stepName`}
                                value={item.stepName || ""}
                                onChange={(e) => {
                                  setInfos((prev) => {
                                    prev[index] = {
                                      ...prev[index],
                                      stepName: e.target.value,
                                    };
                                    return [...prev];
                                  });
                                }}
                              ></textarea>
                            </span>


                          {/* <span className="mx-2 d-none" style={{ maxWidth: 750 }}>
                            <input
                              style={{ width: "150px" }}
                              hidden={
                                item.hasOwnProperty("isInputHidden")
                                  ? item.isInputHidden
                                  : true
                              }
                              type="text"
                              className="form-control input-default "
                              placeholder={labels.recipeStep.stepVideo.en}
                              name={`infos[${index}].Video`}
                              value={item.Video || ""}
                              onChange={(e) => {
                                setInfos((prev) => {
                                  prev[index] = {
                                    ...prev[index],
                                    Video: e.target.value,
                                  };
                                  return [...prev];
                                });
                              }}
                            />
                          </span> */}


                            <div>
                            <img
                            width={"80px"}
                            src={
                                item.Photo && item.Photo.length > 0
                                    ? item.Photo[0].orginalImageUrl
                                    : item.Photo // or provide a fallback URL or handle the case when item.Photo is not defined
                                    ? URL.createObjectURL(item.Photo)
                                    : null // or any fallback value as per your requirement
                            }
                          />

                          <input
                              hidden={
                                  item.hasOwnProperty("isInputHidden")
                                      ? item.isInputHidden
                                      : true
                              }
                              disabled={
                                  item.hasOwnProperty("isInputHidden")
                                      ? item.isInputHidden
                                      : true
                              }
                              type="file"
                              className="upload-button"
                              id={`label-file-upload-${index}`}
                              name={`infos[${index}].Photo`}
                              value={item.Photo ? item.Photo.fileName : ""}
                              onChange={(e) => {
                                  setInfos((prev) => {
                                      prev[index] = {
                                          ...prev[index],
                                          Photo: e.target.files[0],
                                      };
                                      return [...prev];
                                  });
                              }}
                          />

                        <label
                            id={`label-file-upload-${index}`}
                            htmlFor={`input-file-upload-${index}`}
                            hidden={
                                item.hasOwnProperty("isInputHidden")
                                ? item.isInputHidden
                                : true
                            }
                            disabled={
                                item.hasOwnProperty("isInputHidden")
                                ? item.isInputHidden
                                : true
                            }
                        >

                        </label>


                            </div>

                           {(item.isInputHidden === undefined ||
                              item.isInputHidden) &&
                              item.stepName}
                          <span style={{ position: "absolute", right: 15 }}>
                            {item.hasOwnProperty("isInputHidden") &&
                            !item.isInputHidden ? (
                              <Link
                                to={`#`}
                                state={item}
                                className="btn btn-success shadow btn-xs sharp me-1"
                                onClick={() => {
                                  setInfos((prev) => {
                                    prev[index] = {
                                      ...prev[index],
                                      isInputHidden: item.hasOwnProperty(
                                        "isInputHidden"
                                      )
                                        ? !item.isInputHidden
                                        : false,
                                    };
                                    return [...prev];
                                  });
                                }}
                              >
                                <i className="fas fa-save"></i>
                              </Link>
                            ) : (
                              <>
                              {/* <Link
                                className="btn btn-primary shadow btn-xs sharp me-1"
                                to={item.Video !== '' && item.Video !== '#' ? item.Video : '#'}
                                target="_blank"
                              >

                              <i className="fa-solid fa-video"></i>
                              </Link> */}

                              <Link
                                to={`#`}
                                state={item}
                                className="btn btn-primary shadow btn-xs sharp me-1"
                                onClick={() => {
                                  setInfos((prev) => {
                                    prev[index] = {
                                      ...prev[index],
                                      isInputHidden: item.hasOwnProperty(
                                        "isInputHidden"
                                      )
                                        ? !item.isInputHidden
                                        : false,
                                    };
                                    return [...prev];
                                  });
                                }}
                              >

                                <i className="fas fa-pencil-alt"></i>
                              </Link>
                              </>

                            )}
                            <Link
                              to={"#"}
                              onClick={() => {
                                removeData(index);
                              }}
                              className="btn btn-danger shadow btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </span>
                        </li>
                        {item.isDragging ? (
                            <div className="drag-indicator"></div>
                          ) : null}
                        </React.Fragment>
                      ))}
                    </ul>

                    <Col xl="12" style={{ marginTop: 20 }}>
                      <Card>
                        <Accordion
                          className="accordion accordion-no-gutter "
                          defaultActiveKey="0"
                        >
                          <Accordion.Item>
                            <Accordion.Header as="div">
                              <h5>{labels.recipeStep.en}</h5>
                            </Accordion.Header>
                            <Accordion.Collapse className="px-5">
                              <div className="row">
                                <div
                                  className="form-group mb-3 col-12"
                                  style={{ padding: 10 }}
                                >
                                  <label>{labels.recipeStep.stepName.en}</label>
                                  <textarea
                                    style={{ height: 60 }}
                                    className="textarea_editor form-control bg-transparent"
                                    rows="20"
                                    cols="150"
                                    placeholder={labels.recipeStep.stepName.en}
                                    name="stepName"
                                    value={stepNames}
                                    onChange={(e) =>
                                      setStepName(e.target.value)
                                    }
                                  ></textarea>
                                </div>

                                <div
                                  className="col-12 form-group mb-3"
                                  style={{ padding: 10 }}
                                >
                                  <label>
                                    Step {labels.recipeStep.stepImg.en}
                                  </label>

                                  {(!stepImage || stepImage.length === 0) && (
                                    <div className="col-6">
                                      <input
                                        type="file"
                                        className="form-control input-default "
                                        placeholder={
                                          labels.recipeStep.stepImg.en
                                        }
                                        label="Name:"
                                        name="stepImage"
                                        value={
                                          stepImage ? stepImage.filename : ""
                                        }
                                        onChange={(e) => {
                                          setStepImage(
                                            e.target.files.length > 0
                                              ? e.target.files[0]
                                              : null
                                          );
                                        }}
                                      />
                                    </div>
                                  )}

                                  {stepImage && (
                                    <>
                                      <div
                                        className="col-6"
                                        style={{
                                          position: "relative",
                                          top: 30,
                                        }}
                                      >
                                        <img
                                          style={{ height: "100px" }}
                                          className="img-fluid"
                                          // alt="not found"
                                          src={URL.createObjectURL(stepImage)}
                                        />
                                        <br />
                                        <Button
                                          className="my-3"
                                          onClick={() => setStepImage(null)}
                                        >
                                          Remove
                                        </Button>
                                      </div>
                                    </>
                                  )}
                                </div>

                                {/* <div
                                  className="col-12 form-group mb-3"
                                  style={{ padding: 10 }}
                                >
                                  <label>
                                    {labels.recipeStep.stepVideo.en}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control input-default "
                                    placeholder={labels.recipeStep.stepVideo.en}
                                    name="stepVideo"
                                    value={stepVideos ? stepVideos : ""}
                                    onChange={(e) =>
                                      setStepVideo(e.target.value)
                                    }
                                  />
                                </div> */}

                                {/* <div
                                  className="form-group mb-3 col-3"
                                  style={{ padding: 10 }}
                                >
                                  <label>{labels.recipeStep.stepImg.en}</label>
                                  <input
                                    type="file"
                                    className="form-control input-default "
                                    placeholder={labels.recipeStep.stepImg.en}
                                    name="stepImage"
                                    onChange={(e) => {
                                      setStepImage(e.target.files[0]);
                                    }}
                                  />
                                </div> */}
                                <div style={{ padding: 10 }}>
                                  <Button onClick={addToList}>
                                    <span className="fa fa-plus"></span> Add
                                    Step
                                  </Button>
                                </div>
                              </div>
                            </Accordion.Collapse>
                          </Accordion.Item>
                        </Accordion>
                      </Card>
                    </Col>
                  </div>

                  <div className="row">
                    <div className="form-group col-lg-12 mb-3">
                      <label>Recipe Video URL</label>
                      <input
                        type="text"
                        className="form-control input-default "
                        placeholder="Enter your recipe video link"
                        name="recipeVideo"
                        value={recipeFormData && recipeFormData.recipeVideo || ""}
                        onChange={handleInputChange}
                        // value={recipeVideoData}
                        // onChange={(e) => setRecipeVideoData(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-lg-12 mb-3">
                      <label>{labels.shoppingList.en}</label>
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          removePlugins: [
                            "EasyImage",
                            "ImageUpload",
                            "MediaEmbed",
                          ],
                        }}
                        data={recipeFormData && recipeFormData.shoppingList || ""}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setRecipeFormData((prev) => {
                            return { ...prev, shoppingList: data };
                          });
                        }}
                        style={{ height: 20 }}
                      />
                    </div>

                    <div className="form-group col-lg-12 mb-3">
                      <label>{labels.tips.en}</label>
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          removePlugins: [
                            "EasyImage",
                            "ImageUpload",
                            "MediaEmbed",
                          ],
                        }}
                        data={recipeFormData && recipeFormData.recipeTips || ""}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setRecipeFormData((prev) => {
                            return { ...prev, recipeTips: data };
                          });
                        }}
                        style={{ height: 20 }}
                      />
                    </div>
                  </div>

                  <div className="row d-none">
                    <div className="form-group col-12 mb-3">
                      <label>{labels.protienDetails.en}</label>
                      <textarea
                        className="form-control input-default "
                        placeholder={labels.protienDetails.en}
                        name="proteinDetails"
                        style={{ height: 100 }}
                        value={recipeFormData && recipeFormData.proteinDetails || ""}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-lg-6 mb-3">
                      <label>
                        {labels.tags.en} (separate using comma, e.g. "#Recipe,
                        #Tags"){" "}
                      </label>
                      <input
                        type="text"
                        className="form-control input-default "
                        placeholder="Tags"
                        name="recipeTags"
                        value={recipeFormData && recipeFormData.recipeTags || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="row d-flex d-none">
                    <Accordion
                      className="accordion accordion-rounded-stylish accordion-bordered"
                      defaultActiveKey="0"
                    >
                      <Accordion.Item key="seo" eventKey="seo">
                        <Accordion.Header className="accordion-header accordion-header-primary">
                          <h5>
                            Advance - সার্চ ইঞ্জিন অপ্টিমাইজেশান (Search Engine
                            Optimization)
                          </h5>

                          <span className="accordion-header-indicator "></span>
                          {/* <span className="accordion-header-icon"></span>
                      <span className="accordion-header-text">{d.title}</span>
                      <span className="accordion-header-indicator "></span> */}
                        </Accordion.Header>
                        <Accordion.Collapse
                          eventKey="seo"
                          className="accordion__body"
                        >
                          <div className="accordion-body">
                            <div className="col-xl-12">
                              <div className="setting-input mb-3">
                                <label className="form-label">
                                  {labels.metaTitle.en}
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-default "
                                  placeholder={labels.metaTitle.en}
                                  name="metaTitle"
                                  value={
                                    (recipeFormData && recipeFormData.seo &&
                                      recipeFormData.seo.metaTitle) ||
                                    ""
                                  }
                                  onChange={(e) => {
                                    onSetMetaTitle(
                                      e.target.name,
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                              <div className="setting-input mb-3">
                                <label className="form-label">
                                  {labels.metaDescription.en}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={labels.metaDescription.en}
                                  name="metaDescription"
                                />
                              </div>
                              <div className="setting-input mb-3">
                                <label className="form-label">
                                  {labels.metaKeywords.en}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={labels.metaKeywords.en}
                                  name="metaKeywords"
                                />
                              </div>
                              <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                  <label className="form-label">
                                    {labels.metaImage.en}
                                  </label>

                                  <input
                                    type="file"
                                    className="form-control input-default "
                                    placeholder={labels.metaImage.en}
                                    label="metaImage"
                                    name="metaImage"
                                    // value={data?.price}
                                    onChange={handleMetaFileChange}
                                  />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                  {/* <>
                                {!selectedMetaImage &&
                                recipeFormData.seo.metaImage ? (
                                  <img src='' />
                                ) : null}
                              </> */}
                                  {selectedMetaImage && (
                                    <div>
                                      {selectedMetaImage?.map((item, index) => {
                                        return (
                                          <>
                                            <img
                                              className="img-fluid"
                                              alt="Meta Image"
                                              width={""}
                                              src={URL.createObjectURL(item)}
                                            />
                                            <br />
                                            <Button
                                              style={{ marginLeft: 25 }}
                                              onClick={() =>
                                                setSelectedMetaImage(null)
                                              }
                                            >
                                              Remove
                                            </Button>
                                          </>
                                        );
                                      })}
                                    </div>
                                  )}
                                  {!selectedMetaImage &&
                                    recipeFormData &&
                                    recipeFormData.seo &&
                                    recipeFormData.seo.metaImage &&
                                    recipeFormData.seo.metaImage[0] &&
                                    Object.keys(recipeFormData.seo.metaImage[0])
                                      .length != 0 && (
                                      <>
                                        <img
                                          // alt="not found"
                                          className="img-fluid"
                                          src={
                                            recipeFormData.seo.metaImage[0]
                                              .orginalImageUrl
                                          }
                                        />
                                        <br />
                                        <Button
                                          style={{ marginLeft: 25 }}
                                          onClick={() =>
                                            setSelectedMetaImage([])
                                          }
                                        >
                                          Remove
                                        </Button>
                                      </>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Collapse>
                      </Accordion.Item>
                    </Accordion>
                  </div>

                  <div className="row">
                    <div className="col-lg-4 col-12">
                      <FormCheck
                        type="switch"
                        id="custom-switch"
                        label={labels.ownRecipe.en}
                        name="isMyOwnRecipe"
                        value={recipeFormData && !recipeFormData.isMyOwnRecipe}
                        checked={recipeFormData && recipeFormData.isMyOwnRecipe || false}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    {/* <div className="col-lg-4 col-12">
                      <FormCheck
                        type="switch"
                        id="custom-switch"
                        label={labels.featured.en}
                        name="isFeatured"
                        value={!recipeFormData.isFeatured}
                        checked={recipeFormData.isFeatured || false}
                        onChange={handleCheckboxChange}
                      />
                    </div> */}
                    <div className="col-lg-4 col-12">
                      <FormCheck
                        type="switch"
                        id="custom-switch"
                        label={labels.status.en}
                        name="status"
                        value={recipeFormData && !recipeFormData.status}
                        checked={recipeFormData && recipeFormData.status || false}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  </div>

                  <Button
                    type="submit"
                    style={{ width: "100%", marginTop: 10 }}
                  >
                    {" "}
                    Save
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit_Recipe;
