import { toast } from "react-hot-toast";
import { BaseURL } from "../Helper/config";
import axios from "axios";
import { AllCategory } from "../Redux/feature/Category/CategorySlice";
import store from "../Redux/store";

export const GetAllCategory = async()=>{
    try{
        let URL = `${BaseURL}/get-category`
        let res = await axios.get(URL);
        if (res.status === 200 && res.data["status"] === "Success") {
           store.dispatch(AllCategory(res.data.data))

            return true;
          } else {
            toast("Something went wrong! CategoryAPI -1")
            // ErrorToast("Something went wrong! getBannersAPI -1");
            return false;
          }

    }
    catch(e){
        toast("Something went wrong! CategoryAPI -2");

    }
}