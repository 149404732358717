import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

import { BaseURL } from "../../../Helper/config";
const CreateCategory = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    categoryName: "",
    categorySlug: "",
  });

  const [disable, setdisable] = useState(true);
  const [currentFruit, setCurrentFruit] = useState(true);

  console.log(typeof currentFruit);
  const handleChange = (e) => {
    const newContact = { ...data };
    newContact[e.target.name] = e.target.value;

    newContact.categoryName !== "" && newContact.categorySlug !== ""
      ? setdisable(false)
      : setdisable(true);
    setData(newContact);
  };

  const changeFruit = (newFruit) => {
    setCurrentFruit(newFruit);
  };

  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
console.log(Seller);
const [selectedImage, setSelectedImage] = useState([]);
const handleFileChange = (event) => {
  const fileList = Array.from(event.target.files);
  setSelectedImage(fileList);
};

const handleSubmit = (e) => {
  e.preventDefault();
  console.log(selectedImage);
  const form = e.target;
  const name = form.categoryName.value;

  const formData = new FormData();

  formData.append("folder", "Category/" + Seller?.userFullName); // folder name
  selectedImage?.forEach((file) => {
    formData.append("cover[]", file);
  });

  fetch(`${BaseURL}/imguploads`, {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then(async function (data) {
      console.log(data, "ll");
      let subdata = {
        categoryName: name,

        status: false,
        categoryImage: data?.images[0].orginalImageUrl,
        createdBy: Seller._id,
      };
      await axios
        .post(`${BaseURL}/create-category`, subdata)
        .then((res) => {
          console.log(res);
          // if (res.data.status === "Success") {

          toast.success("Category Created", {
          
            autoClose: 5000,
            // hideProgressBar: false,
            // closeOnClick: true,
            // pauseOnHover: true,
            // draggable: true,
            // progress: undefined,
            // theme: "light",
          });
          //}
          // if (res.data.data.keyPattern?.categorySlug === 1) {
          //   toast.error("Slug Should be unique", {
          //     position: "bottom-right",
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     theme: "light",
          //   });
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    });
};


  return (
    <>
      <ToastContainer />
      <div className='container  '>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>Enter Detials of New Category</h4>
            </div>
            <div className='card-body'>
              <div className='basic-form'>
                <form onSubmit={handleSubmit}>
                  <div className='form-group mb-3'>
                    <label>Category Name</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Category Name'
                      label='Name:'
                      name='categoryName'

                      // onChange={handleChange}
                    />
                  </div>

                  <div className='row d-flex'>
                    <div className="col-lg-6">
                    <div
                      className=' justify-content-center mt-3 mb-5  category-upload'
                      style={{ width: "100%" }}
                    >
                      {selectedImage && (
                        <div>
                          {selectedImage?.map((item, index) => {
                            return (
                              <img
                                alt='not found'
                                width={"80px"}
                                src={URL.createObjectURL(item)}
                              />
                            );
                          })}
                          <br />
                          <Button
                            style={{ marginLeft: 25 }}
                            onClick={() => setSelectedImage(null)}
                          >
                            Remove
                          </Button>
                        </div>
                      )}

                      <br />
                      <br />

                      <form id='form-file-upload'>
                        <input
                          type='file'
                          id='input-file-upload'
                          // multiple
                          onChange={handleFileChange}
                        />
                        <label
                          id='label-file-upload'
                          htmlFor='input-file-upload'
                        >
                          <div>
                            <p>Drag and drop your file here or</p>
                            <span className='upload-button'>Upload a file</span>
                          </div>
                        </label>
                      </form>
                    </div>
                    </div>
                  </div>
                  <Button type='submit' >

                    Save
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCategory;
