import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { BaseURL } from "../../../Helper/config";

import { useSelector } from "react-redux";
import { GetAllUser } from "../../../API/UsersAPI";
import { GetAllCategory } from "../../../API/CategoryAPI";
import { GetFoodBySellerApi } from "../../../API/GetFoodBySellerAPI";
import { useGetBankListQuery } from "../../../Redux/API/ApiSlice";

const CreateCoupon = () => {
  const animatedComponents = makeAnimated();
  const [isForReview, setisForReview] = useState(false);
  const [isCouponForAllProduct, setisCouponForAllProduct] = useState(false);
  const [coupon, setCoupon] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const [foodData, setFoodData] = useState([]);
  const [selectedfood, setSelectedfood] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [layoutOne, setLayoutOne] = useState({
    CouponName: "",
    layoutCouponAmount: "",
    StartDate: "",
    EndDate: "",
  });
  const [usersData, setUsersData] = useState([]);

  const created = () => {
    Swal.fire({
      icon: "success",
      title: "Coupon has Created",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const [disable, setdisable] = useState(true);

  const handleChange = (e) => {
    const newContact = { ...layoutOne };
    newContact[e.target.name] = e.target.value;

    newContact.layoutName !== "" &&
    newContact.layoutOrderby !== "" &&
    newContact.layoutDisplayLimit !== "" &&
    newContact.layoutCouponAmount !== "" &&
    newContact.layoutTwoName !== ""
      ? setdisable(false)
      : setdisable(true);
    setLayoutOne(newContact);
  };

  // console.log(layoutOne);

  const users = useSelector((state) => state.Users.allUsers);
  useEffect(() => {
    GetAllUser();
    const temp = [];
    users.forEach((element) => {
      const data = { value: element._id, label: element.email };
      console.log(data);
      temp.push(data);
      setUsersData(temp);
      console.log(usersData);
    });
  }, [users]);
  const Categories = useSelector((state) => state.Category.AllCategoriesList);
  // console.log(Categories, "cate");
  useEffect(() => {
    GetAllCategory();
    const temp = [];
    Categories.forEach((element) => {
      const data = { value: element._id, label: element.categoryName };
      console.log(data);
      temp.push(data);
      setCategoryData(temp);
      console.log(Categories, "cate");
    });
  }, [Categories]);

  let userInfo = useSelector((state) => state.Authentication.seller);
  const foodBySeller = useSelector((state) => state.Seller.getFoodBySellerList);
  // console.log(foodBySeller[0]?.foodData, "food");
  useEffect(() => {
    GetFoodBySellerApi("64da06ecb3cb98549cd2fada", 6);
    const temp = [];
    if (foodBySeller[0]?.foodData?.length > 0) {
      foodBySeller[0]?.foodData?.forEach((element) => {
        const data = { value: element._id, label: element.foodName };
        console.log(data, "asdfa");
        temp.push(data);

        setFoodData(temp);
        console.log(foodBySeller, "food");
      });
    }
  }, [foodBySeller]);
  // console.log(usersData, "from coupon", selectedUser);
  // useEffect(() => {
  //   axios
  //     .get(`${BaseURL}/get-coupon`)
  //     .then((res) => {
  //       setCoupon(res.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [coupon]);

  const handleSubmit = () => {
    let finalPostdata = {
      couponForUserID: selectedUser,
      couponForProductID: selectedfood,
      sellerID:"64da06ecb3cb98549cd2fada",
      categoriesID: selectedCategory,
      isForReview: isForReview,
      isCouponForAllProduct: isCouponForAllProduct,
      couponCode: layoutOne.CouponName,
      couponStartDate: layoutOne.StartDate,
      couponEndDate: layoutOne.EndDate,
      couponAmount: layoutOne.layoutCouponAmount,
      couponIsPercentage: true,
      couponMinPurchase: 3,
    };
    axios
      .post(`${BaseURL}/create-coupon`, finalPostdata)
      .then((res) => {
        console.log("sucess", res.data);
        created();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (data) => {
    console.log(data);
    axios
      .delete(`${BaseURL}/delete-coupon/${data}`)
      .then((res) => {
        console.log(res);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  return (
    <>
      <div>
        <div className='form-group mb-3 row'>
          <label className='col-lg-4 col-form-label' htmlFor='val-username'>
            Coupon Code
            <span className='text-danger'>*</span>
          </label>
          <div className='col-lg-6'>
            <input
              type='text'
              className='form-control'
              id='val-username'
              placeholder='Enter a Coupon Code '
              label='CouponName:'
              name='CouponName'
              value={layoutOne.CouponName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className='form-group mb-3 row'>
          <label className='col-lg-4 col-form-label' htmlFor='val-username'>
            Coupon Amount
            <span className='text-danger'>*</span>
          </label>
          <div className='col-lg-6'>
            <input
              type='text'
              className='form-control'
              id='val-username'
              placeholder='Enter a Coupon Ammount '
              label='layoutCouponAmount:'
              name='layoutCouponAmount'
              value={layoutOne.layoutCouponAmount}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className='form-group mb-3 row'>
          <label className='col-lg-4 col-form-label' htmlFor='val-username'>
            Coupon Start Date
            <span className='text-danger'>*</span>
          </label>
          <div className='col-lg-6'>
            <input
              type='date'
              className='form-control'
              id='val-username'
              placeholder='Enter a Coupon Code '
              label='StartDate:'
              name='StartDate'
              value={layoutOne.StartDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className='form-group mb-3 row'>
          <label className='col-lg-4 col-form-label' htmlFor='val-username'>
            Coupon End Date
            <span className='text-danger'>*</span>
          </label>
          <div className='col-lg-6'>
            <input
              type='date'
              className='form-control'
              id='val-username'
              placeholder='Enter a Coupon Code '
              label='EndDate:'
              name='EndDate'
              value={layoutOne.EndDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className='row'>
          <div className='form-group mb-3 col-lg-4'>
            <label>Select User</label>
            <Select
              // value={selectedOptions}
              closeMenuOnSelect={false}
              components={animatedComponents}
              // defaultValue={selectedOptions}
              onChange={(item) => setSelectedUser(item)}
              classNamePrefix='select'
              // defaultValue={[Options[4], Options[5]]}
              isMulti
              options={usersData}
            />
          </div>
          <div className='form-group mb-3 col-lg-4'>
            <label>Select Category</label>
            <Select
              // value={selectedOptions}
              closeMenuOnSelect={false}
              components={animatedComponents}
              // defaultValue={selectedOptions}
              onChange={(item) => setSelectedCategory(item)}
              classNamePrefix='select'
              // defaultValue={[Options[4], Options[5]]}
              isMulti
              options={categoryData}
            />
          </div>
          <div className='form-group mb-3 col-lg-4'>
            <label>Select Food</label>
            <Select
              // value={selectedOptions}
              closeMenuOnSelect={false}
              components={animatedComponents}
              // defaultValue={selectedOptions}
              onChange={(item) => setSelectedfood(item)}
              classNamePrefix='select'
              // defaultValue={[Options[4], Options[5]]}
              isMulti
              options={foodData}
            />
          </div>
        </div>
        <div class='form-check'>
          <input
            class='form-check-input'
            type='checkbox'
            value=''
            id='flexCheckDefault'
            defaultChecked={isCouponForAllProduct}
            onChange={() => setisCouponForAllProduct(!isCouponForAllProduct)}
          />
          <label class='form-check-label' for='flexCheckDefault'>
            Is For All Product
          </label>
        </div>
        <div class='form-check'>
          <input
            class='form-check-input'
            type='checkbox'
            value=''
            id='flexCheckDefault'
            defaultChecked={isForReview}
            onChange={() => setisForReview(!isForReview)}
          />
          <label class='form-check-label' for='flexCheckDefault'>
            Is for Review
          </label>
        </div>
        {/* setFoodData */}
        <Button
          disabled={disable}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save Coupon
        </Button>
      </div>
      <div>
        <>
          <div className='card h-auto' style={{ marginTop: 10 }}>
            <div className='card-body p-0'>
              <div className='table-responsive'>
                {coupon === undefined ? (
                  ""
                ) : (
                  <table
                    className='table table-list i-table style-1 mb-4 border-0'
                    id='guestTable-all3'
                  >
                    <thead>
                      <tr>
                        <th>Coupon Code</th>
                        <th>Start Date</th>
                        <th>End Date</th>

                        <th>Action</th>
                        <th className='bg-none'></th>
                        <th className='bg-none'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {coupon.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div className='media-bx d-flex py-3  align-items-center'>
                                <div>
                                  <h5 className='mb-0'>{item.couponCode}</h5>
                                  {/* <p className="mb-0">1x </p>  */}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='media-bx d-flex py-3  align-items-center'>
                                <div>
                                  <h5 className='mb-0'>
                                    {item.couponStartDate}
                                  </h5>
                                  {/* <p className="mb-0">1x </p>  */}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='media-bx d-flex py-3  align-items-center'>
                                <div>
                                  <h5 className='mb-0'>{item.couponEndDate}</h5>
                                  {/* <p className="mb-0">1x </p>  */}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                <Link
                                  to='/EditCoupon'
                                  state={item._id}
                                  className='btn btn-outline-primary'
                                >
                                  Edit
                                </Link>
                              </div>
                            </td>
                            <td>
                              <div>
                                <Link
                                  to={"#"}
                                  onClick={() => {
                                    // handleDelete(item._id);
                                    del(item._id);
                                  }}
                                  className='btn btn-outline-primary'
                                >
                                  Delete
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default CreateCoupon;
